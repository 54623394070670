export type Resource = {
	id?: string,
	name?: string,
	file?: string,
	link?: string,
	createdAt?: Date
}

export function prepResource(resource: Resource): Resource {
	delete resource.createdAt;
	delete resource.file;

	return resource;
}

export function parseResource(json: any): Resource {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	return json;
}