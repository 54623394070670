import { ContextsProviderProps } from './contextsProvider.types'

import { BrandProvider, ApiProvider } from 'contexts/providers'
import { ConstantProvider } from 'contexts/constant/Constant'
import { JobsCounterProvider } from 'contexts/jobs-counter/JobsCounter'
import { JobIdProvider } from 'contexts/job-id/JobId'

const ContextsProvider = ({ children } : ContextsProviderProps) => {
  return (
    <ApiProvider>
      <JobsCounterProvider>
        <JobIdProvider>
          <BrandProvider>
            <ConstantProvider>
                {children}
            </ConstantProvider>
          </BrandProvider>
        </JobIdProvider>
      </JobsCounterProvider>
    </ApiProvider>
  )
}

export default ContextsProvider
