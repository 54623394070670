import { DnrType } from "./dnr-type";
import { Organization } from "./organization";
import { User } from "./user";

export type DnrOrganization = {
	dnrType?: DnrType,
	createdAt?: Date,
	createdBy?: User,
	organization?: Organization
}

export function parseDnrOrganization(json: any): DnrOrganization {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	return json;
}
