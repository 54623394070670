import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { format } from 'date-fns'
import { utcToZonedTime, format as formatTz } from 'date-fns-tz'
import { Job, rateToString } from '@praos-health/jobs-client';
import { useBrand } from 'contexts';
import './JobCard.scss';
import JobCardDetailItem from './JobCardDetailItem';
import nurseImage from '../../../../assets/images/nurse.png';
import ModalPopup from '../modal';
import JobHeader from '../jobForms/jobHeader/JobHeader';
import ApplicantForm from '../jobForms/applicantForm/ApplicantForm';
import Separator from '../jobForms/separator/Separator';
import JobFooter from '../jobForms/jobFooter/JobFooter';
import CompleteInfo from '../jobForms/completeInfo/CompleteInfo';
import { useUserData } from 'contexts/user/User';
import FormTab from '../jobForms/formTab/FormTab';
import ReferForm from '../jobForms/referForm/ReferForm';
import { useFavoritesData } from 'contexts/favorites/Favorites';
import { isMobile } from 'utils';
import { useJobId } from 'contexts/job-id/JobId';
import HotJobImage from '../../../../assets/images/Hot-Job.png'
import { truncateStringToMaxCharacters } from '@praos-health/core/utilities/string';

interface JobCardProps {
    job: Job
}

const JobCard: React.FC<JobCardProps> = ({ job }) => {
    const MAX_CHARS_SHOW_MORE = 400;
    
    const { brand } = useBrand();
    const { user } = useUserData();
    const { jobId, setJobId } = useJobId();
    const { addFavorite, removeFavorite, isFavorite } = useFavoritesData();
    const [openApplyModal, setOpenApplyModal] = useState(false);
    const [openReferModal, setOpenReferModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [selectedApplyTab, setSelectedApplyTab] = useState(0);
    const [selectedReferTab, setSelectedReferTab] = useState(0);
    const [customErrorMessage, setCustomErrorMessage] = useState('');
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
      if ((job?.thirdPartyId && job?.thirdPartyId === jobId?.id) || (!job?.thirdPartyId && Number(jobId?.id) === job?.intId)) {
        handleApply()
      }
    }, [jobId, job])

    const formatPhoneNumber = (phoneNumberString?: string) => {
        if (!phoneNumberString) return '';

        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumberString;
    }

    const handleApply = () => setOpenApplyModal(true);
    const handleRefer = () => setOpenReferModal(true);
    const handleDetails = () => setOpenDetailsModal(true);
    const handleCloseApplyModal = () => {
        setOpenApplyModal(false);
        setSelectedApplyTab(0);
        setJobId({ id: undefined })
    }
    const handleCloseReferModal = () => {
        setOpenReferModal(false);
        setSelectedReferTab(0);
    }
    const handleNextApplyTab = (message?: string) => {
        setCustomErrorMessage(message || '')
        setSelectedApplyTab(currentTab => currentTab + 1);
    }
    const handleNextReferTab = (message?: string) => {
        setCustomErrorMessage(message || '')
        setSelectedReferTab(currentTab => currentTab + 1);
    }
    const handlePreviousReferTab = () => setSelectedReferTab(currentTab => currentTab - 1);
    const handleCloseDetailsModal = () => {
      setShowMore(false);
      setOpenDetailsModal(false);
    }

    

    const handleJobDescriptionHTML = () => {
    let text = job?.description?.replace(/\n/gi, '<br>')
    if(text){
    text = text.replace(
        /(https?:\/\/)([^ ]+)/g,
        '<a target="_blank" href="$&">$2</a>'
      );
      if (showMore) {
        return text;
      }
      return text?.length && text?.length > MAX_CHARS_SHOW_MORE ? truncateStringToMaxCharacters(text, MAX_CHARS_SHOW_MORE) : text
    }
    return;

    }

  const handleJobId = (job: Job) => {
    setJobId(Object.assign({}, { id: job.thirdPartyId ? job.thirdPartyId : job.intId?.toString() }))
  }
  
    return (
        <>
            <Box className={`${!isMobile() ? `jobContainer` : ``}`} sx={{height: `${isMobile() ? `290px` : `auto`}`}}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{position: 'relative', top: '20px', zIndex: '20'}}>
                    <Box className='type' sx={{ backgroundColor: brand.color, height: '30px'}}>
                        {job.type}
                    </Box>
                <Avatar
                  sx={{
                    color: brand.color,
                    mx: 1,
                    border: '2px solid',
                    bgcolor: '#ffffff',
                    cursor: 'pointer',
                    maxHeight: 30,
                    maxWidth: 30,
                    padding: 2.2,
                    position: 'relative',
                    right: '10px'
                  }}>
                  {
                    isFavorite(job._id) ?
                      <FavoriteIcon onClick={() => removeFavorite(job._id)} /> :
                      <FavoriteBorderIcon onClick={() => addFavorite(job._id)} />
                  }
                </Avatar>
              </Box>          
              <Card
                  className='jobCard'
                  sx={{ borderLeft: `3px solid ${brand.color}80` }}>
                    {job?.isExclusive ? 
                    <img src={HotJobImage} height='100px' width= '100px' alt='Hot Job' style={{ position: 'absolute', right: '55px', top:'65px', opacity: '1', zIndex: -1, objectFit: 'contain', transform: 'rotate(-10deg)'}}></img>
                    : <Box sx={{ backgroundImage: `linear-gradient(90deg, #FFFFFF 65%, rgba(255, 255, 255, 0.5) 100%), url(${job.imageUrl ? job.imageUrl : nurseImage})` }} className='jobImage'></Box>
                    } 
                  <CardContent sx={{marginLeft: '15px'}} >
                      <Box className='header'>
                          <Typography noWrap={true} sx={{ fontSize: 22, fontWeight: 600, fontFamily: 'Lato-Bold' }}>
                              {job.location?.city?.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}, {job.location?.state}
                          </Typography>
                      </Box>
                      <Box className="subHeader" sx={{position: 'relative', bottom: '6px'}}>
                          <Typography sx={{fontFamily: 'Lato', color: '#808080', 'whiteSpace': 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{job.title}</Typography>
                      </Box>
                      <Box className="details">
                          <Grid container columnSpacing={2}>
                              <JobCardDetailItem jobCard={true} xsColumns={12} Icon={WorkspacePremiumOutlinedIcon} text={job.licenseType} />
                              <JobCardDetailItem jobCard={true} xsColumns={12}
                                  Icon={PaymentsOutlinedIcon}
                                  text={rateToString(job?.rate)} />

                              <JobCardDetailItem jobCard={true} xsColumns={12}
                                  Icon={VerifiedOutlinedIcon}
                                  text={
                                    (!job.specialties || (job.specialties && job.specialties.length === 0)) ? 'No specialty' :
                                    job.specialties.join(", ")
                                  } />
                              <Grid item xs={12}></Grid>
                              <JobCardDetailItem jobCard={true} xsColumns={6}
                                  Icon={AccessTimeOutlinedIcon}
                                  text={`${job.duration ? job.duration + ' ' : ''}${job.duration && job.duration <= 1 && job.durationUnit ? job.durationUnit?.toString().slice(0, -1) : job.durationUnit?.toString()}`} />
                              <Grid item xs={6}>
                                  <div className='view-details' style={{ color: brand.color }} onClick={handleDetails}>
                                      View Details
                                  </div>
                              </Grid>
                              <Grid item xs={6}></Grid>
                          </Grid>
                      </Box>
                  </CardContent>
              </Card>
              <Box className="actions" display={'flex'} marginRight={isMobile() ? '0px' : '10px'} justifyContent={isMobile() ? 'center' : 'flex-end'} gap={isMobile() ? '20px' : 'inherit'}>
                  <Button
                      className='actionBtn refer'
                      variant="outlined"
                      startIcon={<MonetizationOnOutlinedIcon />}
                      onClick={handleRefer}
                      sx={{padding: isMobile() ?  '' : '5px 40px'}}>
                      {user ? 'FAST REFER' : 'REFER'}
                  </Button>
                  <Button
                      className='actionBtn'
                      sx={{ bgcolor: brand.color, border: `2px solid ${brand.color}`, padding: isMobile() ?  '' : '5px 40px'}}
                      variant="contained"
                      startIcon={<EditOutlinedIcon />}
                      onClick={() => handleJobId(job)}>
                      {user ? 'FAST APPLY' : 'I\'M INTERESTED'}
                  </Button>
              </Box>
            </Box>
            { /* Apply Modal */}
            <ModalPopup
                openModal={openApplyModal}
                handleCloseModal={handleCloseApplyModal}>
                {
                    selectedApplyTab !== 1 &&
                    (
                        <>
                            <JobHeader job={job} handleClose={handleCloseApplyModal}></JobHeader>
                            <Separator></Separator>
                            <ApplicantForm job={job} handleNextTab={handleNextApplyTab}></ApplicantForm>
                          {brand?.isBriefcaseEnabled ? 
                                <>
                                    <Separator></Separator>
                                    <JobFooter modalType={'apply'}></JobFooter>
                                </> 
                            : 
                            !!user &&
                            <>
                                    <Separator></Separator>
                                    <JobFooter modalType={'apply'}></JobFooter>
                            </> 
                          }
                            
                        </>
                    )
                }
                {selectedApplyTab === 1 && <CompleteInfo customErrorMessage={customErrorMessage} handleClose={handleCloseApplyModal}></CompleteInfo>}
            </ModalPopup>

            {/* Refer Modal */}
            <ModalPopup
                openModal={openReferModal}
                handleCloseModal={handleCloseReferModal}
            >
                {
                    (selectedReferTab === 0 || selectedReferTab === 1) &&
                    (
                        <>
                            <JobHeader job={job} handleClose={handleCloseReferModal}></JobHeader>
                            <Separator></Separator>
                            <FormTab items={['Your info', 'Your referral\'s info']} selectedTab={selectedReferTab}></FormTab>
                            <ReferForm job={job} selectedTab={selectedReferTab} handleNextTab={handleNextReferTab} handlePreviousTab={handlePreviousReferTab}></ReferForm>
                            {brand?.isBriefcaseEnabled && 
                                <>
                                    <Separator></Separator>
                                    <JobFooter modalType={'refer'}></JobFooter>
                                </>
                            }
                        </>
                    )
                }
                {selectedReferTab === 2 &&
                    <CompleteInfo
                        handleClose={handleCloseReferModal}
                        title={'Thank you for your referral!'}
                        message={'A recruiter will be in touch.'}
                        contentTitle={'Email Sent'}></CompleteInfo>
                }
            </ModalPopup>

            {/* Details Modal */}
            <ModalPopup
                openModal={openDetailsModal}
                handleCloseModal={handleCloseDetailsModal}>
                <JobHeader job={job} handleClose={handleCloseDetailsModal}></JobHeader>
                <Separator></Separator>
                <FormTab items={['Job details']} selectedTab={0} useIcon={true}></FormTab>
                <Grid container className='details-modal-container'>
                    <Grid item xs={9}>
                        <Grid container>
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12} Icon={WorkspacePremiumOutlinedIcon} text={job.licenseType} />
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12} Icon={NotListedLocationOutlinedIcon} text={`${job.location?.city}, ${job.location?.state}`} />
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12}
                                Icon={VerifiedOutlinedIcon}
                                text={
                                    (!job.specialties || (job.specialties && job.specialties.length === 0)) ? 'Specialty not specified' :
                                        job.specialties.join(", ")
                                } />
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12}
                                Icon={PaymentsOutlinedIcon}
                                text={rateToString(job?.rate)} />
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12}
                                Icon={CalendarMonthOutlinedIcon}
                                text={
                                    `Starts ${job.startDate ?
                                        ((job.type === 'Per diem') ?
                                            (format(job.startDate, 'EEE, MM/dd/yyyy h:mm a')) :
                                            (formatTz(utcToZonedTime(job.startDate, 'UTC'), 'EEE, MM/dd/yyyy', { timeZone: 'UTC' }))) :
                                        'ASAP'}`
                                } />
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12} 
                                Icon={InfoOutlinedIcon} 
                                text={`${job.createdByUser?.firstName && job.createdByUser?.lastName && (job.createdByUser?.firstName + ' ' + job.createdByUser?.lastName)}${job.createdByUser?.phoneNumber && (' ' + formatPhoneNumber(job.createdByUser?.phoneNumber))}`} />
                            <JobCardDetailItem jobCard={false} fontSize={'20px'} xsColumns={12}
                                Icon={AccessTimeOutlinedIcon}
                                text={`${job.duration ? job.duration + ' ' : ''}${job.duration && job.duration <= 1 && job.durationUnit ? job.durationUnit?.toString().slice(0, -1) : job.durationUnit?.toString()}`} />
                        </Grid>
                        {
                            job?.description &&
                            <>
                                <Box className='details-modal-line'></Box>
                                <Grid className='description detail' item xs={6}>
                                    <span className='icon'>
                                        <DescriptionOutlinedIcon />
                                    </span>
                                    <span className='text'>
                                        Job Description
                                    </span>
                                </Grid>
                                <Grid container className='description-paragraph'>
                                  <Typography className='description-text' sx={{fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html: handleJobDescriptionHTML() || 'NA'}}>
                                  </Typography >
                                  { !showMore && job.description?.length > MAX_CHARS_SHOW_MORE && <Typography className='description-text show-more-text' sx={{ marginTop: ''}} onClick={() => setShowMore(true)}>Show more...</Typography>}
                                </Grid>
                            </>
                        }
                        <Grid container justifyContent="center" alignItems="flex-start" className='action-btns' rowGap={1}>
                            <Grid item xs={12} md={6}>
                                <Button
                                    className='actionBtn refer'
                                    variant="outlined"
                                    onClick={() => {
                                        handleCloseDetailsModal();
                                        handleRefer();
                                    }}
                                    sx={{padding: isMobile() ?  '' : '5px 40px'}}>
                                    {user ? 'FAST REFER' : 'REFER'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    className='actionBtn'
                                    sx={{ bgcolor: brand.color, padding: isMobile() ?  '' : '5px 40px'}}
                                    variant="contained"
                                    onClick={() => {
                                        handleCloseDetailsModal();
                                        handleJobId(job);
                                    }}>
                                    {user ? 'FAST APPLY' : 'I\'M INTERESTED'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {brand?.isBriefcaseEnabled &&
                    <>
                        <Separator></Separator>
                        <JobFooter></JobFooter>
                    </> 
                }
            </ModalPopup>
        </>
    );
};

export default JobCard;