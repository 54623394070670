import { Box, Slider, Typography, InputLabel, MenuItem, FormControl, SelectChangeEvent, Select } from '@mui/material';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import './RateFilter.scss';
import { RateFrequency } from '@praos-health/jobs-client';

let firstExecution = true;

interface RateFilterProps {
  onRateFilterTypeChange: (newRateFilterType: RateFrequency) => void,
  rateType: string
}

const RateFilter: React.FC<RateFilterProps> = ({ onRateFilterTypeChange, rateType }) => {
  const { filtersData, setFiltersData, updateLocalData } = useFiltersData();
  const [value, setValue] = useState(0);
  const [rateFilterType, setRateFilterType] = useState(rateType);

const handleChange = (event: SelectChangeEvent) => {
  setRateFilterType(event.target.value as RateFrequency);
  setValue(0);
  filtersData.rate=0;
  setFiltersData(Object.assign({}, filtersData))
  updateLocalData()
  onRateFilterTypeChange && onRateFilterTypeChange(event.target.value as RateFrequency);
};

  useEffect(() => {
    if (firstExecution) {
        setValue(filtersData.rate || 0)
        firstExecution = false
    }
  }, [filtersData.rate, rateFilterType])

  const marks = {
    [RateFrequency.Hourly]: [
      { value: 0, label: '$0/hr' },
      { value: 50, label: '$50/hr' },
      { value: 100, label: '+$100/hr'}
    ],
    [RateFrequency.Weekly]: [
      { value: 0, label: '$0/wk' },
      { value: 2500, label: '$2500/wk' },
      { value: 5000, label: '+$5000/wk' }
    ]
  }

  const maxFilterRate = {
    [RateFrequency.Hourly]: 100,
    [RateFrequency.Weekly]: 5000
  }

  const incrementFrequency = {
    [RateFrequency.Hourly]: 10,
    [RateFrequency.Weekly]: 100
  }
      
  const changeValue = (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    filtersData.rate = newValue as number
    filtersData.rateFrequency = rateFilterType as (RateFrequency.Daily | RateFrequency.Hourly | RateFrequency.Weekly | RateFrequency.Monthly | RateFrequency.Yearly);
    setValue(newValue as number)
    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  };

  function convertToMoneyLabel(value: number): string {
    return `${value} $`
  }

  return (
    <Box data-test="jobRate-filter">
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Rate Terms</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              data-test="rate-filter-select"
              value={rateFilterType}
              label="Rate Terms"
              onChange={handleChange}
            >
              <MenuItem data-test='Hourly' value={RateFrequency.Hourly}>{RateFrequency.Hourly.charAt(0)+ RateFrequency.Hourly.slice(1).toLowerCase()}</MenuItem>
              <MenuItem data-test='Weekly' value={RateFrequency.Weekly}>{RateFrequency.Weekly.charAt(0)+ RateFrequency.Weekly.slice(1).toLowerCase()}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Slider
            className='filter-rate-slider'
            valueLabelFormat={convertToMoneyLabel}
            max={maxFilterRate[rateFilterType as (RateFrequency.Hourly | RateFrequency.Weekly)]}
            marks={marks[rateFilterType as (RateFrequency.Hourly | RateFrequency.Weekly)]}
            onChange={changeValue}
            step={incrementFrequency[rateFilterType as (RateFrequency.Hourly | RateFrequency.Weekly)]}
            value={filtersData.rate ? value : 0}>
        </Slider>
        { !!filtersData.rate && 
            <Box display="flex" alignContent='column' justifyContent='flex-end' className='selected-rate-viewer-wrapper'>
                <Typography fontWeight='bold' color='primary'>Minimum rate&nbsp;</Typography><br></br>
                <Typography>{`$${value}`}</Typography>
            </Box>
        }
    </Box>
   );
};

export default RateFilter;