import { Box, Divider, Typography } from '@mui/material';
import { LicenseType } from '@praos-health/briefcase-client';
import { useConstant } from 'contexts/constant/Constant';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { useEffect, useState } from 'react';
import './LicenseFilter.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useBrand } from 'contexts';

let firstExecution = true;

const LicenseFilter: React.FC<{}> = () => {
  const { constants } = useConstant();
  const { brand } = useBrand();
  const [values, setValues] = useState<LicenseType[]>([]);
  const [ profession, setProfession ] = useState<string | undefined>(undefined);
  const [ license, setLicense ] = useState<LicenseType | undefined>(undefined);
  const { filtersData, setFiltersData, updateLocalData } = useFiltersData();


  useEffect(() => {
    setValues(constants.licenseTypes || [])
  }, [constants.licenseTypes])

  useEffect(() => {
    if (firstExecution) {
      firstExecution = false
      setProfession(filtersData.license && filtersData.license[0] ? filtersData.license[0].profession : undefined)
      setLicense(filtersData.license ? filtersData.license[0] : undefined)
    }

    if (filtersData.license === undefined) {
      setProfession(undefined)
      setLicense(undefined)
    }
  }, [filtersData.license])
    
  const getProfessionSelectorComponent = (profession: string | undefined) => {
    return <Box className='profession-selector flex flex-space-between' key={profession} onClick={() => setProfession(profession)}>
        <Typography>{profession}</Typography>
        <ChevronRightIcon />
      </Box>
  }
    
  const updateSelectedLicense = (selectedLicense: LicenseType | undefined) => {
    if (!selectedLicense) {
        setLicense(undefined)
        setProfession(undefined)
        filtersData.license = undefined
        setFiltersData(Object.assign({}, filtersData))
    } else {
        filtersData.license = selectedLicense ? [selectedLicense] : [];
        setFiltersData(Object.assign({}, filtersData))
        setLicense(Object.assign({}, selectedLicense));
    }

    updateLocalData();
  }

  const getLicenseSelectorComponent = (selectedLicense: LicenseType | undefined) => {
    return <Box className='license-selector flex flex-space-between' sx={{border: '1px solid ' + (selectedLicense && selectedLicense._id === license?._id) ? brand.color : 'inherit'}}
            key={selectedLicense?._id || ''} onClick={() => updateSelectedLicense(selectedLicense)}>
        <Typography>{selectedLicense?.abbr} - {selectedLicense?.name}</Typography>
        {selectedLicense && selectedLicense._id === license?._id ? <CheckCircleOutlineIcon sx={{fontSize:25}} color='primary' /> : <></> }
      </Box>
  }

  return (
    <Box className='profession-license-filter-wrapper'>
        {
            profession && !license &&
                <Box className='selected-profession-wrapper'>
                    <Box className='selected-profession flex flex-space-between' onClick={() => setProfession(undefined)}>
                        <Box>
                            <Box className='flex'>
                                <CheckCircleOutlineIcon color='primary'/>
                                <Typography className='p-l-5-px' sx={{fontWeight: 550}}>{profession}</Typography>
                            </Box>
                            <Typography className='p-l-30-px'>Select a license...</Typography>
                        </Box>
                        <ChevronLeftIcon className="flex align-center"/>
                    </Box>
                    <Divider />
                </Box>

        }
        {
            profession && license && 
            <Box className='selected-license-wrapper'>
                <Box className='selected-license flex flex-space-between' onClick={() => updateSelectedLicense(undefined)}>
                    <Box>
                        <Box className='flex'>
                            <CheckCircleOutlineIcon color='primary'/>
                            <Typography className='p-l-5-px' sx={{fontWeight: 550}}>{profession}</Typography>
                        </Box>
                        <Typography className='p-l-30-px'>{license.abbr} - {license.name}</Typography>
                    </Box>
                    <ChevronLeftIcon className="flex align-center"/>
                </Box>
                <Divider />
            </Box>
        }
        { !profession 
            ? values.map(value => value?.profession).filter((v, i, a) => a.indexOf(v) === i).map(getProfessionSelectorComponent)
            : values.filter(value => value.profession === profession).map(getLicenseSelectorComponent)
        }
    </Box>
  );
};

export default LicenseFilter;