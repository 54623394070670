import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type VehicleInsurance = {
	name?: string,
	company?: string,
	policyNumber?: string,
	expirationDate?: Date,
	fileUrl?: string
	uploadedAt?: Date,
	downloadUrl?: string
}

export function prepVehicleInsurance(insurance: VehicleInsurance): VehicleInsurance {
	delete insurance.fileUrl;
	delete insurance.downloadUrl;
	delete insurance.uploadedAt;

	if (insurance.expirationDate) {
		insurance.expirationDate = midnight(new Date(insurance.expirationDate), true);
	}

	return insurance;
}

export function parseVehicleInsurance(json: any): VehicleInsurance {
	if (json.expirationDate) {
		json.expirationDate = addMinutes(new Date(json.expirationDate).getTimezoneOffset(), new Date(json.expirationDate));
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}