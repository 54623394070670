import { clone } from "@praos-health/core/utilities/object";
import { CertifyingBody } from "./models";
import { ajax } from "./ajax"

export class CertifyingBodyService {
	private _certifyingBodyCache: CertifyingBody[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, profession?: string): Promise<CertifyingBody[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/certifyingbodies`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: CertifyingBody[];

		if (!this._certifyingBodyCache) {
			this._certifyingBodyCache = await ajax(`${this.apiUrl}/briefcase/certifyingbodies`, 'GET', auth);
		}

		result = this._certifyingBodyCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}
		
		return clone(result);
	}
}