import { midnight } from "@praos-health/core/utilities/date";
import { Address } from "./address";
import { Department } from "./department";
import { UserType } from "./user-type";

export type User = {
	_id?: string,
	userType?: UserType,
	firstName?: string,
	lastName?: string,
	email?: string,
	phoneNumber?: string,
	dateOfBirth?: Date | boolean,
	gender?: 'Male' | 'Female' | 'Other',
	languages?: string[],
	profilePicUrl?: string,
	profilePicThumbUrl?: string,
	deactivatedAt?: Date,
	address?: Address,
	department?: Department,
	signupIpAddress?: string,
	signupChannel?: string
}

export function prepUser(user: User): User {
	if (user.profilePicUrl != null) {
		delete user.profilePicUrl;
	}

	delete user.profilePicThumbUrl;

	if (user.dateOfBirth instanceof Date) {
		user.dateOfBirth = midnight(new Date(user.dateOfBirth), true);
	}
	
	if (user.phoneNumber) {
		user.phoneNumber = user.phoneNumber.replace(/\D/g,'');
	}

	return user;
}

export function parseUser(json: any): User {
	if (typeof json.dateOfBirth === 'number') {
		json.dateOfBirth = new Date(json.dateOfBirth);
	}

	if (typeof json.deactivatedAt === 'number') {
		json.deactivatedAt = new Date(json.deactivatedAt);
	}

	return json;
}