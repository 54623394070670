import { HighlightOff, Search } from '@mui/icons-material';
import { Box, InputAdornment, List, TextField, CardActionArea} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useConstant } from 'contexts/constant/Constant';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { useEffect, useState } from 'react';
import './OrganizationFilter.scss';
import { OrganizationJobCountResponse} from '@praos-health/jobs-client/job-service'

let firstExecution = true;

const OrganizationFilter: React.FC<{}> = () => {
  const { constants } = useConstant();

  const [selectedOrganization, setSelectedOrganization] = useState<string>();
  const [value, setValue] = useState<OrganizationJobCountResponse[]>([]);
  const [organizationSearchText, setOrganizationSearchText] = useState<string>('');
  const { filtersData, setFiltersData, updateLocalData } = useFiltersData();

  const handleToggle = (value: OrganizationJobCountResponse) => () => {

    const selectedOrganizationCoBrand = value?.coBrandedUrl2 ?? value?.coBrandedUrl;
    if(selectedOrganizationCoBrand){
      filtersData.organization = selectedOrganizationCoBrand;
    }

    setFiltersData(Object.assign({}, filtersData))
    updateLocalData();


    setSelectedOrganization(selectedOrganizationCoBrand);
  };


  useEffect(() => {
      setValue(constants.organizations || [])  // use with api data
  }, [constants.organizations])

  useEffect(() => {
    if (firstExecution) {
        firstExecution = false
        setSelectedOrganization(filtersData.organization)
    }

    if (filtersData.organization === undefined) {
      setSelectedOrganization('');
    }

  }, [filtersData?.organization])

  function clearSearchValue(): void {
    setOrganizationSearchText('')
    filtersData.search = undefined
    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  }

	function getEndAdornment(): React.ReactNode {
    if (organizationSearchText && organizationSearchText.length > 0) {
        return <InputAdornment position="end"><HighlightOff color='primary' className='clear-search-value-icon' onClick={clearSearchValue}/> </InputAdornment>;
    }
    
    return null;
  }

  return (
      <Box data-test="organization-filter">
        <Box className='flex w-100-p'>
          <TextField id='outlined-basic' className='organization-text-search m-20-px w-100-p' placeholder='Search organization' variant='outlined' 
            onChange={event => setOrganizationSearchText(event.target.value)}
            value={organizationSearchText}
            autoComplete='off'
            InputProps={{
              startAdornment: <InputAdornment position='start'><Search /> </InputAdornment>,
							endAdornment: getEndAdornment()
            }} />
        </Box>

        <List data-test="organization-list" sx={{ width: '100%', minWidth: '100%', height: 300, bgcolor: 'background.paper', overflow: 'scroll', overflowX: 'hidden'}}>
          { value
              .filter(value => value.name?.toLocaleLowerCase().includes(organizationSearchText.toLowerCase()))
              .map((value) => {
              return (
                <Card
                    key={value.id} onClick={handleToggle(value)} className={`${(value.coBrandedUrl2 === selectedOrganization || value.coBrandedUrl === selectedOrganization)&& 'isSelected'}`} sx={{ maxWidth: 345, m: 1}}>
                  <CardActionArea sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    paddingX: 1,
                  }}>
                    {value?.logoURL ? 
                      <CardMedia
                        component='img'
                        width='40'
                        image={value.logoURL}
                        alt='company logo'
                        sx={{ width: 40}}
                      />
                    :
                      <Avatar sx={{ bgcolor: '#613794' }}>{`${value.name?.split(' ')[0].substring(0,1)}${value.name?.split(' ')[1].substring(0,1)}`}</Avatar>
                    }
                    <CardContent sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      py: 1.5,
                      px: 1
                    }}>
                      <Typography variant='subtitle2' component='div'>
                        {value.name}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {value.jobCount} {value?.jobCount && value?.jobCount > 1 ? `Jobs` : `Job`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
          })}
        </List>

      </Box>
  );
};

export default OrganizationFilter;