import React from 'react';
import './Separator.scss';


const Separator: React.FC = () => {

    return (
        <div className='separator'></div>
    );
};

export default Separator;