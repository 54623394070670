import { Department } from "./department";
import { NotificationPreferences } from "./notification-preferences";
import { OrganizationLocation, parseOrganizationLocation, prepOrganizationLocation } from "./organization-location";
import { OrientationDocument, parseOrientationDocument, prepOrientationDocument } from "./orientation-document";
import { parseResource, prepResource, Resource } from "./resource";
import { User } from "./user";

export type Organization = {
	_id?: string,
	intId?: number,
	name?: string,
	address1?: string,
	address2?: string,
	city?: string,
	state?: string,
	zip?: string,
	createdAt?: Date,
	completedAt?: Date
	paymentType?: string,
	accountManager?: string,
	accountNotes?: string,
	taxId?: string,
	MSAsignDate?: Date,
	coBrandedUrl?: string
	billingContact?: {
		name?: string,
		email?: string,
		phoneNumber?: string
	},
	facility?: {
		type?: string,
		businessName?: string,
		description?: string,
		url?: string,
		imageURL?: string,
		imageThumbURL?: string,
		logoURL?: string,
		logoThumbURL?: string,
		bannerURL?: string,
		bannerThumbURL?: string,
		color?: string,
		googlePlayUrl?: string
		appleStoreUrl?: string
	},
	jobs?: {
		orientationDocuments?: {
			id?: any,
			name?: string,
			description?: string,
			path?: string,
			department?: Department,
			createdAt?: Date,
			createdBy?: User		
		}
	},
	approvedBy?: User,
	approvedAt?: Date,
	approvalNotes?: string,
	rejectedBy?: User,
	rejectedAt?: Date,
	rejectionNotes?: string,
	suspendedBy?: User,
	suspendedAt?: Date,
	suspensionNotes?: string,
	resources?: Resource[],
	locations?: OrganizationLocation[],
	notificationPreferences?: NotificationPreferences,
	generalContactInfo?: {
		email?: string,
		phoneNumber?: string
	},
	services?: {
		name?: string,
		isEnabled?: boolean
	}[],
	webUrls?: {
		jobBoardUrl?: string,
		organizationLoginUrl?: string,
		professionalLoginUrl?: string,
		professionalSignupUrl?: string
	}
}
export enum OrganizationField {
	OrientationDocuments = 'orientationdocuments',
	Locations = 'locations'
}

export type OrganizationItem = OrientationDocument | OrganizationLocation;

export function prepOrganization(organization: Organization): Organization {
	if (organization.facility) {
		delete organization.facility.imageURL;
		delete organization.facility.imageThumbURL;
		delete organization.facility.logoURL;
		delete organization.facility.logoThumbURL;
		delete organization.facility.bannerURL;
		delete organization.facility.bannerThumbURL;
	}

	if (organization.resources) {
		for (let i = 0; i < organization.resources.length; i++) {
			organization.resources[i] = prepResource(organization.resources[i]);
		}
	}

	return organization;
}

export function parseOrganization(json: any): Organization {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	if (json.approvedAt) {
		json.approvedAt = new Date(json.approvedAt);
	}

	if (json.completedAt) {
		json.completedAt = new Date(json.completedAt);
	}

	if (json.MSAsignDate) {
		json.MSAsignDate = new Date(json.MSAsignDate);
	}

	if (json.rejectedAt) {
		json.rejectedAt = new Date(json.rejectedAt);
	}

	if (json.suspendedAt) {
		json.suspendedAt = new Date(json.suspendedAt);
	}

	if (json.locations) {
		for (let i = 0; i < json.locations.length; i++) {
			json.locations[i] = parseOrganizationLocation(json.locations[i]);
		}
	}

	if (json.resources) {
		for (let i = 0; i < json.resources.length; i++) {
			json.resources[i] = parseResource(json.resources[i]);
		}
	}

	if (json.jobs?.orientationDocuments) {
		for (let i = 0; i < json.jobs.orientationDocuments.length; i++) {
			json.jobs.orientationDocuments[i] = parseOrientationDocument(json.jobs.orientationDocuments[i]);
		}
	}

	return json;
}

export function prepItem(field: OrganizationField, item: OrganizationItem): OrganizationItem {
	switch (field) {
		case OrganizationField.OrientationDocuments:
			item = prepOrientationDocument(item as OrientationDocument);
			break;
		case OrganizationField.Locations:
			item = prepOrganizationLocation(item as OrganizationLocation);
			break;
	
		default:
			break;
	}

	return item;
}

export function getOrganizationStatus(organization: Organization): string {
	if (organization.rejectedAt) {
		return 'REJECTED';
	}

	if (!organization.suspendedAt) {
		return 'SUSPENDED';
	}

	if (organization.approvedAt) {
		return 'APPROVED';
	}

	return 'PENDING';
}