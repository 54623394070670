import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, styled, useRadioGroup } from '@mui/material';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { useEffect, useState } from 'react';
import './JobTypeFilter.scss';

let firstExecution = true;

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
  ))
  
  (({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
  },
}));

const JobTypeFilter: React.FC<{}> = () => {
  const { filtersData, setFiltersData, updateLocalData } = useFiltersData();
  const [value, setValue] = useState('');
  
  
  useEffect(() => {
    if (firstExecution) {
      setValue(filtersData.jobType || '')
      firstExecution = false
    }

    if (filtersData.jobType === undefined) {
        setValue('');
    }

  }, [filtersData.jobType])
  
  function onRateJobTypeChange(event: React.SyntheticEvent, checked: boolean): void {
    let selectedJobType = (event.target as HTMLInputElement).value;
    setValue(selectedJobType)
    filtersData.jobType = selectedJobType
    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  }

  function JobTypeControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
      
    let checked = false;
      
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
      
    return <StyledFormControlLabel onChange={onRateJobTypeChange} checked={checked} {...props} />;
  }
      

  return (
    <RadioGroup className='m-l-20-px' name="filter-job-type-radio-group" value={value}>
      <JobTypeControlLabel value="Per diem" label="Shift" control={<Radio />} />
      <JobTypeControlLabel value="Travel" label="Travel" control={<Radio />} />
      <JobTypeControlLabel value="Permanent" label="Permanent" control={<Radio />} />
      <JobTypeControlLabel value="Contract" label="Contract" control={<Radio />} />
    </RadioGroup>
  );
};

export default JobTypeFilter;