export type DailyStatistics = {
	date: Date,
	count: number
}

export enum DailyStatisticType {
	Posted = 'posted',
	Confirmed = 'confirmed',
	Applied = 'applied',
	NotEnrouted = 'notenrouted',
	NotStarted = 'notstarted',
	NotCompleted = 'notcompleted',
	NotApproved = 'notapproved'
}

export function parseDailyStatistic(json: any): DailyStatistics {
	const result: DailyStatistics = json;
	
	if (json.date) {
		result.date = new Date(json.date);
	}

	return result;
}