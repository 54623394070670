import Board from 'apps/board';
import './App.scss';
import { ContextsProvider } from 'components'
import { BrowserRouter } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FilterDataContextProvider } from 'contexts/filter/Filter';
import { useEffect, useState } from 'react';
import { loadMapApi } from 'utils';
import variables from './styles/Variables.module.scss'
import { FavoritesDataContextProvider } from 'contexts/favorites/Favorites';

let firstTimeExecution = true;

function App() {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    
    function useMediaQuery(query: string): boolean {
    const getMatches = (query: string): boolean => {
      if (typeof window !== 'undefined') {
        return window.matchMedia(query).matches
      }
      return false
    }
    
    const [matches, setMatches] = useState<boolean>(getMatches(query))
    
    useEffect(() => {
      if (firstTimeExecution) {
        firstTimeExecution = false;
      } else {
        window.location.reload()
      }
    }, [matches])
  
    function handleChange() {
      setMatches(getMatches(query))
    }
    
    useEffect(() => {
      const matchMedia = window.matchMedia(query)
      handleChange()
      matchMedia.addEventListener('change', handleChange)
      return () => {
        matchMedia.removeEventListener('change', handleChange)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])
    
    return matches
  }
    
  useMediaQuery(`(max-width: ${variables['mobileWidth']})`)

    useEffect(() => {
        const googleMapScript = loadMapApi();
        googleMapScript.addEventListener('load', function () {
            setScriptLoaded(true);
        });
      }, []);
    
    
    return (
      <BrowserRouter>
        <ContextsProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FilterDataContextProvider>
              <FavoritesDataContextProvider>
                {scriptLoaded && <Board />}
              </FavoritesDataContextProvider>
            </FilterDataContextProvider>
          </LocalizationProvider>
        </ContextsProvider>
      </BrowserRouter>
    );
}

export default App;