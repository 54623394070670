import { Address } from "./address";

export type OrganizationLocation = Address & {
	id?: string,
	locationType?: string,
	createdAt?: Date
}

export function prepOrganizationLocation(location: OrganizationLocation): OrganizationLocation {
	if (location.createdAt) {
		delete location.createdAt;
	}

	return location;
}

export function parseOrganizationLocation(json: any): OrganizationLocation {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	return json;
}