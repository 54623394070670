import { parseSettings, Settings } from "./models";
import { ajax } from "./ajax"

export type GetSettingsOptions = {
	select?: {
		versions?: 1,
		resources?: 1,
		session?: 1,
		referrals?: 1,
		defaultFacility?: 1,
		server?: 1
	}
}

export type SetSettingsOptions = {
	nursys?: {
		isEnabled?: boolean
	}
}

export class SystemService {
	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async getSettings(auth?: string, options?: GetSettingsOptions): Promise<Settings> {
		let result = await ajax(`${this.apiUrl}/system/settings`, 'GET', auth, null, options);

		if (result) {
			result = parseSettings(result);
		}

		return result;
	}

	async setSettings(auth: string, options?: SetSettingsOptions): Promise<void> {
		await ajax(`${this.apiUrl}/system/settings`, 'PUT', auth, options);
	}
}