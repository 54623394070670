import { clone } from "@praos-health/core/utilities/object";
import { AdditionalCertification } from "./models";
import { ajax } from "./ajax"

export class AdditionalCertificationService {
	private _additionalCertificationCache: AdditionalCertification[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, profession?: string): Promise<AdditionalCertification[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/additionalcertifications`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: AdditionalCertification[];

		if (!this._additionalCertificationCache) {
			this._additionalCertificationCache = await ajax(`${this.apiUrl}/briefcase/additionalcertifications`, 'GET', auth);
		}

		result = this._additionalCertificationCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}

		return clone(result);
	}
}