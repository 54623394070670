import variables from './../../../styles/Variables.module.scss'

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: parseInt(variables['mobileWidth'].slice(0, -2)),
    lg: 1200,
    xl: 1536
  }
}

export default breakpoints
