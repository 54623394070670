import { DnrOrganization, parseDnrOrganization } from "./dnr-organization";

export type JobSettings = {
	workCities?: WorkCity[] | null,
	workStates?: string[] | null,
	workDistance?: number,
	newJobEmail?: JobEmailNotification,
	newJobSms?: boolean,
	availiableDate?: Date,
	dnrOrganizations?: DnrOrganization[],
	requestedTimeOff?: RequestedTimeOff[],
	preferredShift?: "AM" | "PM" | "Mids",
	preferredSchedule?: PreferredSchedule,
	confirmedJobs?: number,
	cancelledJobs?: number,
	competentReviews?: number,
	professionalReviews?: number,
	skilledReviews?: number,
	badgedReviews?: number
}

export type WorkCity = {
	city?: string,
	state?: string,
	coordinates?: number[]
}

export enum JobEmailNotification {
	No = 0,
	Daily = 1,
	Realtime = 2,
	Weekly = 3
}

export type RequestedTimeOff = {
	fromDate?: Date,
	toDate?: Date
}

export enum PreferredSchedule {
	Sunday = 1,
	Monday = 2,
	Tuesday = 4,
	Wednesday = 8,
	Thursday = 16,
	Friday = 32,
	Saturday = 64
}

export function parseJobSettings(json: any): JobSettings {
	if (json.availiableDate) {
		json.availiableDate = new Date(json.availiableDate);
	}

	if (json.requestedTimeoff) {
		for (const item of json.requestedTimeoff) {
			if (item.fromDate) {
				item.fromDate = new Date(item.fromDate);
			}

			if (item.toDate) {
				item.toDate = new Date(item.toDate);
			}
		}
	}

	if (json.dnrOrganizations) {
		for (let i = 0; i < json.dnrOrganizations.length; i++) {
			json.dnrOrganizations[i] = parseDnrOrganization(json.dnrOrganizations[i]);
		}
	}

	return json;
}