import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type HealthDocument = {
	id?: string,
	name?: string,
	reason?: string,
	date?: Date,
	fileUrl?: string,
	downloadUrl?: string,
	uploadedAt?: Date
}

export function prepHealthDocument(doc: HealthDocument): HealthDocument {
	delete doc.fileUrl;
	delete doc.downloadUrl;
	delete doc.uploadedAt;

	if (doc.date) {
		doc.date = midnight(new Date(doc.date), true);
	}

	return doc;
}

export function parseHealthDocument(json: any): HealthDocument {
	if (json.date) {
		json.date = addMinutes(new Date(json.date).getTimezoneOffset(), new Date(json.date));
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}