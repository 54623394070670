import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import { useBrand } from 'contexts';
import './FormTab.scss';
import { isMobile } from 'utils';

interface FormTabProps {
    items: string[],
    selectedTab: number,
    useIcon?: boolean
}

const FormTab: React.FC<FormTabProps> = ({ items, selectedTab, useIcon }) => {
    const { brand } = useBrand();

    return (
        <Grid sx={{ mb: 4 }} className="formTabGrid" container>
            {
                items.map((item, i) => {
                    return (
                        <Grid key={i} item xs={(12/items.length)}>
                            <Box className={`tabColumn ${ selectedTab !== i ? 'unselected': '' }`}>
                                <Box className='tabNum' style={{ marginRight: isMobile() ? '15px' : '20px', backgroundColor: selectedTab <= i  ? brand.color : '#70A500' }}>
                                    { useIcon ? <WorkIcon className='icon' /> : (i + 1) }
                                </Box>
                                <Box className='tabText' style={{ color: brand.color }}>
                                    <Typography sx={{fontFamily: 'Lato-Bold', fontSize: '20px'}}>{item}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
};

export default FormTab;