import { Box } from '@mui/material';
import { JobType, ListJobsOptions, ListJobsResult, RateFrequency } from '@praos-health/jobs-client';
import { DEFAULT_BRAND_NAME } from 'configs/brand/brand';
import { FiltersData } from 'configs/filtersData/filtersData.types';
import { useApi, useBrand } from 'contexts';
import { useFiltersData } from 'contexts/filter/Filter';
import { useJobsCounter } from 'contexts/jobs-counter/JobsCounter';
import { UserDataContextProvider } from 'contexts/user/User';
import React, { useEffect, useState } from 'react';
import TabNav from '../tabNav';
import './Content.scss';
import NavBar from '../navbar';

const Content: React.FC<{}> = () => {
    const { brand } = useBrand()
    const { filtersData } = useFiltersData();
    const { jobService } = useApi()
    const { jobsCounter, setJobsCounter } = useJobsCounter()
    const [jobs, setJobs] = useState<ListJobsResult>({ count: 0, list: [] });
    const [mapJobs, setMapJobs] = useState<ListJobsResult>({ count: 0, list: [] });
    const [loadingJobs, setLoadingJobs] = useState<Boolean>(true);
    const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
    const [skip, setSkip] = useState<number>(0);
    const limit: number = (25)
    const sortBy: "createdAt" | "startDate" | "rate" | "distance" | "isExclusive" | undefined = 'isExclusive'
    const [selectedTabValue, setSelectedTabValue] = useState<number>(0)

    const fetchJobs = (isLoadMore: boolean) => {
        setLoadMoreLoading(true);
    
      let fetchJobsPayload: ListJobsOptions = {};

      if (brand.name !== DEFAULT_BRAND_NAME) {
          
          if (brand.pathName === '') {
              return;
          }

          fetchJobsPayload.organizationPathName = brand.pathName;
      }

      if(selectedTabValue === 0){
            fetchJobsPayload.skip = isLoadMore ? skip : 0;
            fetchJobsPayload.limit = limit;
      } else if(selectedTabValue === 1){
            fetchJobsPayload.select = 'latLong'
      }
      fetchJobsPayload.sortBy = sortBy;

      _buildFilterRequest(filtersData, fetchJobsPayload);

      jobService.listExternal(process.env.REACT_APP_X_API_KEY || '', fetchJobsPayload)
        .then(data => {
            if(selectedTabValue === 0){
                if(isLoadMore){
                    setJobs({ count: data.count, list: [...jobs.list, ...data.list]})
                    return;
                }else{
                    setJobs(data)
                }
            }else{
                setMapJobs(data)
            }
          jobsCounter.current = data.count
          setJobsCounter(Object.assign({}, jobsCounter))
        })
        .catch(error => console.error("Jobs could not be retrieved", error))
        .finally(() => {
            setLoadingJobs(false)
            setLoadMoreLoading(false)
        });
    }

    function _buildFilterRequest(filtersData: FiltersData, request: ListJobsOptions): ListJobsOptions {
        if (filtersData.rate) {
            request.rate = filtersData.rate;
        }

        if (filtersData.jobType) {
            request.type = filtersData.jobType as JobType;
        }

        if (filtersData.startDate) {
            if (filtersData.startDate instanceof Date) {
                const startDate = new Date(filtersData.startDate);
                request.startDate = startDate;
                const startDateTo = new Date(filtersData.startDate);
                startDateTo.setMonth(startDateTo.getMonth() + 6)
                request.startDateTo = startDateTo;
            } else if (typeof filtersData.startDate === "boolean" && filtersData.startDate === true) {
                request.startDate = 'ASAP'
            }
        }

        if (filtersData.location?.length) {
            request.cities = filtersData.location
                .map(location => { return { city: location.city, state: location.state } })
        }

        if (filtersData.specialty?.length) {
            request.specialties = JSON.stringify(filtersData.specialty
                .map(specialty => { return { profession: specialty.profession, name: specialty.name } }))
        }

        if (filtersData.license?.length) {
            request.licenseType = JSON.stringify(filtersData.license
                .map(license => { return { abbr: license.abbr, profession: license.profession } }))
        }

        if (filtersData.search && filtersData.search.length >= 2) {
            request.search = filtersData.search;
        }

        if (brand.name === DEFAULT_BRAND_NAME && filtersData?.organization?.length) {
            request.organizationPathName = filtersData.organization;
        }

        if (filtersData.rateFrequency) {
            request.rateFrequency = filtersData.rateFrequency as RateFrequency.Daily
        }
        return request;
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
                fetchJobs(false);
        }, 500);
        return () => clearTimeout(timeOutId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand.pathName, filtersData, selectedTabValue]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
                fetchJobs(true);
        }, 500);
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip]);

    return (
        <Box className='flex content-wrapper flex-basis-75'>
            <Box className='content-body content-filter-wrapper inline-flex'>
                <UserDataContextProvider>
					<NavBar setJobs={(jobs) => setJobs(jobs)} setLoadingJobs={(value) => setLoadingJobs(value)} setSkip={(newSkip) => setSkip(newSkip)} />
                    <TabNav brand={brand} setSelectedTabValue={(value) => setSelectedTabValue(value)} mapJobs={mapJobs} jobs={jobs} setJobs={(jobs) => setJobs(jobs)} loadingJobs={loadingJobs} setLoadingJobs={(value) => setLoadingJobs(value)} setSkip={(newSkip) => setSkip(newSkip)} skip={skip} limit={limit} loadMoreLoading={loadMoreLoading} />
                </UserDataContextProvider>
            </Box>
        </Box>
    );
};

export default Content;
