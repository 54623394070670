import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type LiabilityInsurance = {
	company?: string
	policyNumber?: string,
	expirationDate?: Date,
	fileUrl?: string,
	downloadUrl?: string,
	uploadedAt?: Date
}

export function prepLiabilityInsurance(insurance: LiabilityInsurance): LiabilityInsurance {
	delete insurance.fileUrl;
	delete insurance.downloadUrl;
	delete insurance.uploadedAt;

	if (insurance.expirationDate) {
		insurance.expirationDate = midnight(new Date(insurance.expirationDate), true);
	}

	return insurance;
}

export function parseLiabilityInsurance(json: any): LiabilityInsurance {
	if (json.expirationDate) {
		json.expirationDate = addMinutes(new Date(json.expirationDate).getTimezoneOffset(), new Date(json.expirationDate));
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}