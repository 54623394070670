import React from 'react';
import { Grid, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import './JobCardDetailItem.scss';


interface JobCardDetailItemProps {
    Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string },
    text?: string,
    fontSize?: string,
    xsColumns?: number,
    mdColumns?: number,
    jobCard?: boolean
}

const JobCardDetailItem: React.FC<JobCardDetailItemProps> = ({ Icon, text, xsColumns = 6, mdColumns = 6, fontSize = '7px', jobCard = false }) => {

    return (
        <Grid className='detail' item md={mdColumns} xs={xsColumns}>
            <span className='icon' style={{ fontSize: fontSize }}>
                <Icon />
            </span>
            <Typography dangerouslySetInnerHTML={{__html: text || 'NA'}} className='text' style={{
              fontFamily: 'Lato',
              textOverflow: jobCard ? 'ellipsis' : 'inherit',
              overflow: jobCard ? 'hidden' : 'inherit',
              whiteSpace: jobCard ? 'nowrap' : 'inherit', }}>
            </Typography>
        </Grid>
    );
};

export default JobCardDetailItem;