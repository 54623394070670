import React, { useState, useEffect, RefCallback } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Autocomplete, FormControl, FormHelperText, Grid, MenuItem, Select, InputAdornment, TextField, InputLabel, Box, Chip, Typography, Tooltip } from '@mui/material';
import './ApplicantForm.scss';
import { useApi, useBrand } from 'contexts';
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from '@mui/lab';
import { useConstant } from 'contexts/constant/Constant';
import { Job } from '@praos-health/jobs-client';
import { ListUserResult, OrganizationUser, UserType } from '@praos-health/users-client';
import { Professional, WorkCity } from '@praos-health/professional-client';
import { IMaskInput } from 'react-imask';
import { City, Specialty } from '@praos-health/briefcase-client';
import { State } from 'configs/constantsData/state.types';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { MuiFileInput } from 'mui-file-input';
import { useUserData } from 'contexts/user/User';
import FormTab from '../formTab/FormTab';
import americaFlag from '../../../../../assets/images/usa.png'
import { isMobile } from 'utils';
import { UploadRequest } from '@praos-health/files-client';
import { applyIfDefined } from '@praos-health/core/utilities/object'

interface ApplicantFormProps {
    job?: Job,
    handleNextTab: (message?: string) => void
}
interface CustomTextProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomTextProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="___-___-____"
                definitions={{
                    _: /\d/
                }}
                inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
                onAccept={(value: any) =>
                    onChange({ target: { name: props.name, value } })
                }
            />
        );
    }
);

enum SelectInputTypes {
    License = 'license',
    Profession = 'profession',
    Specialty = 'specialty',
    State = 'states',
    City = 'cities',
    Distance = 'distance',
    Recruiter = 'recruiter',
    ReferredBy = 'referredBy',
    ShiftPreference = 'shiftPreference',
    YearsOfExperience = 'yearsOfExperience'
}

enum InputTypes {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    Phone = 'phone',
}
/* eslint-disable no-useless-escape */
const ApplicantForm: React.FC<ApplicantFormProps> = ({ job, handleNextTab }) => {
    const { brand } = useBrand();
    const { user, updateUserData } = useUserData();
    const { userService, cityService, professionalService, jobService, settingService, fileService } = useApi();
    const { constants } = useConstant();
    const [submittingForm, setSubmittingForm] = useState(false);
    const [recruiters, setRecruiters] = useState<ListUserResult>({ count: 0, list: [] });
    const [phone, setPhone] = useState('');
    const [license, setLicense] = useState('');
    const [profession, setProfession] = useState('');
    const [yearsOfExperience, setYearsOfExperience] = useState<string>('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [referredBy, setReferredBy] = useState('')
    const [specialty, setSpecialty] = useState<Specialty[]>([]);
    const [state, setState] = useState<State[]>([]);
    const [city, setCity] = useState<City[]>([]);
    const [distance, setDistance] = useState('');
    const [shiftPreference, setShiftPreference] = useState<'AM' | 'PM' | 'Mids'>('AM');
    const [recruiter, setRecruiter] = useState('');
    const [cityOptions, setCityOptions] = useState<City[]>([]);
    const [cityName, setCityName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [licenseError, setLicenseError] = useState(false);
    const [referrals, setReferrals] = useState<string[]>([]);
    const [file, setFile] = useState<File | null | undefined>(null);
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
    const [visibleRepeatPassword, setVisibleRepeatPassword] = useState<boolean>(false);
    const [fileError, setFileError] = useState<boolean>(false);

    const { register, handleSubmit, setValue, watch, control, formState: { errors, isValid } } = useForm({ mode: 'all' });

    const onSubmit = async (data: any) => {
        if ((errors && Object.values(errors).length) || licenseError) return;
        setSubmittingForm(true);
        const {
            firstName, lastName, email, phone, profession, password,
            license, specialty, states, cities, distance, recruiter,
            referredBy
        } = data;

        let specialtyPayload: string[] | undefined = (specialty as Specialty[])?.map(s => s.name || '')?.filter(s => !!s && s !== 'None');
        specialtyPayload = specialtyPayload.length > 0 ? specialtyPayload : undefined;

        const professional = {
            firstName: firstName as string,
            lastName: lastName as string,
            email: email as string,
            phoneNumber: (phone as string).replace(new RegExp('-', 'g'), ''),
            profession: profession as string,
            briefcase: applyIfDefined<any>(
                {
                    licenses: [{ licenseType: license as string }]
                },
                {
                    specialties: specialtyPayload?.length ? specialtyPayload : undefined,
                    yearsOfExperience: job ? undefined : yearsOfExperience
                }
            ),
            jobs: {
                workStates: state.length > 0 ? (states as State[])?.map(s => s.code) : [],
                workCities: cities.length > 0 ? (cities as City[])?.map(c => {
                    return { city: c.city, state: c.state, coordinates: c.coordinates } as WorkCity
                }) : [],
                workDistance: Number(distance),
                preferredShift: shiftPreference
            }
        }

        if (brand?.isBriefcaseEnabled) {
            professionalService.addExternal(
                {
                    item: professional,
                    recruiter: recruiter || undefined,
                    organizationId: job?.organization?._id || brand.id || undefined,
                    jobId: job?._id || undefined,
                    referredBy: referredBy,
                    fileName: file?.name ? file.name : undefined,
                    password: password,
                }
            )
                .then(async (user) => {
                    const professionalWithFileRequest: Professional & { fileRequest?: UploadRequest } = user;
                    if (professionalWithFileRequest?.fileRequest && file) {
                        await fileService.performUpload(
                            professionalWithFileRequest.fileRequest,
                            file.name,
                            file
                        ).catch(e => console.error('Unable to upload resume', e))
                    }
                    setSubmittingForm(false);
                    setErrorMessage('');
                    if (job) {
                        updateUserData(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] }))
                    } else {
                        localStorage.setItem("user_data", JSON.stringify(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] })))
                    }
                    handleNextTab();
                })
                .catch((e) => {
                    setSubmittingForm(false);
                    let { name, message } = e;
                    if (name === "AccountAlreadyExistsError" ||
                        name === "DeactivatedAccountError" ||
                        name === "ProfessionAlreadyAssignedError" ||
                        name === "AddAffiliationRequiredError") {
                        setErrorMessage('');
                        if (job) {
                            updateUserData(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] }))
                        } else {
                            localStorage.setItem("user_data", JSON.stringify(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] })))
                        }
                        if (name === "ProfessionAlreadyAssignedError") {
                            message = "We found an existing account for this email associated with a different profession.  Please login to complete your profile or create a new account for a new Profession.";
                        }
                        handleNextTab(message);
                    } else {
                        setErrorMessage(e.message);
                    }
                    console.error(e);
                })
        }
        else {
            let fileResult;
            if (file) {
                fileResult = await fileService.upload(
                    {
                        file,
                        fileName: 'Resume'
                    }
                )
            }
            jobService.thirdpartyApply({
                item: professional,
                recruiter: recruiter || undefined,
                organizationId: job?.organization?._id || brand.id || undefined,
                jobId: job?._id || undefined,
                referredBy: referredBy,
                fileUrl: fileResult?.downloadUrl
            }).then(async () => {
                setSubmittingForm(false);
                setErrorMessage('');
                if (job) {
                    updateUserData(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] }))
                } else {
                    localStorage.setItem("user_data", JSON.stringify(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] })))
                }
                handleNextTab();
            })
                .catch((e) => {
                    setSubmittingForm(false);
                    let { name, message } = e;
                    if (name === "AccountAlreadyExistsError" ||
                        name === "DeactivatedAccountError" ||
                        name === "ProfessionAlreadyAssignedError" ||
                        name === "AddAffiliationRequiredError") {
                        setErrorMessage('');
                        if (job) {
                            updateUserData(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] }))
                        } else {
                            localStorage.setItem("user_data", JSON.stringify(Object.assign(professional, { affiliations: [{ referredBy: referredBy, recruiter: (typeof recruiter === 'string' ? { _id: recruiter } : { _id: '' }) }] })))
                        }
                        if (name === "ProfessionAlreadyAssignedError") {
                            message = "We found an existing account for this email associated with a different profession.  Please login to complete your profile or create a new account for a new Profession.";
                        }
                        handleNextTab(message);
                    } else {
                        setErrorMessage(e.message);
                    }
                    console.error(e);
                })
        }
    }

    const setInitialData = () => {
        setPhone('');
        setLicense('');
        setProfession('');
        setYearsOfExperience('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setReferredBy('')
        setSpecialty([]);
        setState([]);
        setCity([]);
        setDistance('');
        setShiftPreference('AM');
        setRecruiter('');
        setCityOptions([]);
        setCityName('');
        setFile(null);
    }

    const handleInputChange = (event: any, type: InputTypes,) => {
        switch (type) {
            case InputTypes.FirstName:
                setFirstName(event.target.value)
                setValue(InputTypes.FirstName, event.target.value, { shouldValidate: true });
                break
            case InputTypes.LastName:
                setLastName(event.target.value)
                setValue(InputTypes.LastName, event.target.value, { shouldValidate: true });
                break
            case InputTypes.Email:
                setEmail(event.target.value)
                setValue(InputTypes.Email, event.target.value, { shouldValidate: true });
                break
            case InputTypes.Phone:
                setPhone(event.target.value)
                setValue(InputTypes.Phone, event.target.value, { shouldValidate: true });
                break
        }
    };

    const handleSelectChange = (event: any, type: SelectInputTypes, value?: any) => {
        switch (type) {
            case SelectInputTypes.License:
                setLicense(event.target.value);
                setValue(SelectInputTypes.License, event.target.value, { shouldValidate: true });
                break;
            case SelectInputTypes.Profession:
                setProfession(event.target.value);
                setValue(SelectInputTypes.Profession, event.target.value, { shouldValidate: true });
                setLicense('');
                if (event.target.value === 'Allied Health') {
                    setSpecialty([{ _id: '', name: 'None', profession: '' }]);
                    setValue(SelectInputTypes.Specialty, [{ _id: '', name: 'None', profession: '' }], { shouldValidate: true })
                } else {
                    setSpecialty([]);
                    setValue(SelectInputTypes.Specialty, [], { shouldValidate: false })
                }
                setErrorMessage('');
                setLicenseError(false);
                break;
            case SelectInputTypes.Specialty:
                if (value.length <= 10) {
                    if (specialty?.findIndex(s => s.name === 'None') >= 0 && (value as Specialty[])?.findIndex(s => s.name !== 'None') >= 0) {
                        value = (value as Specialty[])?.filter(s => s.name !== 'None')
                    }
                    if (specialty?.findIndex(s => s.name === 'None') < 0 && (value as Specialty[])?.findIndex(s => s.name === 'None') >= 0) {
                        value = [{ _id: '', name: 'None', profession: '' }] as Specialty[]
                    }
                    if (specialty?.findIndex(s => s.name === 'None') >= 0 && ((value as Specialty[])?.filter(s => s.name === 'None'))?.length > 1) {
                        value = [{ _id: '', name: 'None', profession: '' }] as Specialty[]
                    }
                    setSpecialty(value);
                    setValue(SelectInputTypes.Specialty, value, { shouldValidate: true });
                }
                break;
            case SelectInputTypes.State:
                setState(value);
                setValue(SelectInputTypes.State, value, { shouldValidate: true });
                break;
            case SelectInputTypes.City:
                setCity(value);
                setValue(SelectInputTypes.City, value, { shouldValidate: true });
                break;
            case SelectInputTypes.Distance:
                setDistance(event.target.value);
                setValue(SelectInputTypes.Distance, event.target.value, { shouldValidate: true });
                break;
            case SelectInputTypes.Recruiter:
                setRecruiter(event.target.value);
                setValue(SelectInputTypes.Recruiter, event.target.value, { shouldValidate: true });
                break;
            case SelectInputTypes.ReferredBy:
                setReferredBy(event.target.value);
                setValue(SelectInputTypes.ReferredBy, event.target.value, { shouldValidate: true });
                break;
            case SelectInputTypes.ShiftPreference:
                setShiftPreference(event.target.value);
                setValue(SelectInputTypes.ShiftPreference, event.target.value, { shouldValidate: true });
                break;
            case SelectInputTypes.YearsOfExperience:
                setYearsOfExperience(event.target.value);
                setValue(SelectInputTypes.YearsOfExperience, event.target.value, { shouldValidate: true });
                break;
        }
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
        setValue('phone', event.target.value, { shouldValidate: true })
        handleInputChange(event, InputTypes.Phone)
    }

    const onCityInputChange = (event: React.SyntheticEvent, cityName: string) => {
        setCityName(cityName);
    }

    const convertReadableCityName = (city: City) => {
        let label = city.city ? city.city : '';
        label += city.state ? `, ${city.state}` : ''
        return label;
    }

    const formatPhoneNumber = (phoneNumber: string) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    const handleFileChange = (newFile: File | null) => {
        if (newFile && !isValidExtension(newFile)) {
            setFileError(true)
            setFile(null)
        } else {
            setFile(newFile)
            setFileError(false)
        }
    }

    const isValidExtension = (file: File) => {
        const fileName = file?.name || '';
        if (!fileName) return false;
        const fileExt = fileName.split('.').pop() || '';

        const acceptableExtensions = ['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png', '.page', '.pages', '.txt', '.rtf', '.gif'];
        return acceptableExtensions.includes('.' + fileExt.toLowerCase());
    };


    useEffect(() => {
        let storedUser = job ? user : localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data') ?? '')
        if (storedUser && Object.keys(storedUser).length > 0) {
            setValue('firstName', storedUser.firstName)
            setFirstName(storedUser?.firstName ?? '')
            setValue('lastName', storedUser.lastName)
            setLastName(storedUser?.lastName ?? '')
            setValue('email', storedUser.email)
            setEmail(storedUser?.email ?? '')
            setValue('phone', formatPhoneNumber(storedUser.phoneNumber || ''))
            setPhone(formatPhoneNumber(storedUser.phoneNumber || ''))

            setProfession(storedUser.profession || '')
            setValue('profession', storedUser.profession || '')

            const license = storedUser.briefcase?.licenses && storedUser.briefcase?.licenses[0].licenseType ? storedUser.briefcase?.licenses[0].licenseType : ''
            setLicense(license)
            setValue('license', license)

            setDistance(storedUser.jobs?.workDistance?.toString() || '');
            setValue('distance', storedUser.jobs?.workDistance?.toString() || '')

            let specialty = constants.specialties?.filter(s => storedUser.briefcase?.specialties?.includes(s.name || '')) || []
            if (specialty.length === 0) specialty = [{ _id: '', name: 'None', profession: '' }];
            setSpecialty(specialty)
            setValue('specialty', specialty)

            setState(constants.states?.filter(s => storedUser.jobs?.workStates?.includes(s.code)))
            setValue('states', constants.states?.filter(s => storedUser.jobs?.workStates?.includes(s.code)))

            const cities = (storedUser.jobs?.workCities || []) as City[]
            setCity(cities);
            setValue('cities', cities)

            const yearsOfExperience = storedUser.briefcase?.yearsOfExperience ?? ''
            setYearsOfExperience(yearsOfExperience);
            setValue('yearsOfExperience', yearsOfExperience)

            setShiftPreference(storedUser.jobs?.preferredShift || "AM");
            setReferredBy((storedUser.affiliations && storedUser.affiliations[0].referredBy) || '');
        } else {
            setInitialData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, constants])

    useEffect(() => {
        if (cityName && cityName.length >= 2) {
            const timeOutId = setTimeout(() => {
                cityService.listExternal(process.env.REACT_APP_X_API_KEY || '', cityName)
                    .then(cities => {
                        let uniqueIds: string[] = [];
                        if (cities && cities.length) {
                            if (city && city.length) {
                                let mergedCities = cities.concat(city).filter(element => {
                                    const isDuplicate = uniqueIds.includes(element._id);
                                    if (!isDuplicate) {
                                        uniqueIds.push(element._id);
                                        return true;
                                    }
                                    return false;
                                });
                                setCityOptions(mergedCities);
                            } else {
                                setCityOptions(cities)
                            }
                        } else {
                            setCityOptions(city)
                        }
                    })
                    .catch(console.error)
            }, 200);

            return () => clearTimeout(timeOutId);
        } else {
            setCityOptions([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityName]);

    useEffect(() => {
        userService.listExternal(process.env.REACT_APP_X_API_KEY || '', {
            organizationId: job?.organization?._id || brand.id || '',
            isDeactivated: false,
            isOrganizationUser: true,
            limit: 100,
            userType: [UserType.HospitalManager, UserType.HospitalSupervisor]
        })
            .then(data => {
                let orgUsers: OrganizationUser[] = [];
                if (job?.department?._id) {
                    orgUsers = data.list.filter(user => user.department?._id?.toString() === job?.department?._id?.toString());
                    if (!orgUsers.length) orgUsers = data.list;
                } else {
                    orgUsers = data.list;
                }
                setRecruiters({ count: data.count, list: orgUsers });
                let storedUser = job ? user : localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data') ?? '')
                if (storedUser?.affiliations?.length && orgUsers.filter(orgUser => storedUser.affiliations && orgUser._id?.toString() === storedUser.affiliations[0]?.recruiter?._id?.toString()).length) {
                    setRecruiter(storedUser.affiliations[0]?.recruiter?._id?.toString() || '')
                    setValue('recruiter', storedUser.affiliations[0]?.recruiter?._id?.toString() || '')
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userService, job?.organization?._id, job?.department?._id, brand?.id, user]);

    useEffect(() => {
        if (license && job && license !== job.licenseType) {
            setErrorMessage('Your license info does not seem to match the requirement of the job you are applying to.')
            setLicenseError(true)
        } else {
            setErrorMessage('')
            setLicenseError(false)
        }
    }, [license, job, job?.licenseType])

    useEffect(() => {
        settingService.getSettings(undefined, {
            select: { referrals: 1 }
        }).then(settings => {
            setReferrals(settings.referrals?.options || []);
        });
    }, [settingService, user]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {job ?
                (<FormTab items={['Your info']} selectedTab={0}></FormTab>) :
                (
                    <Box sx={{ justifyContent: 'center', width: isMobile() ? '90%' : '60%', margin: '50px auto' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
                            Thank you for visiting. Tell us about yourself to have a recruiter contact you. To speed up the process look out for an email to compile your credentials.
                        </Typography>
                    </Box>
                )
            }
            <Grid container className='formGrid'>
                <Grid item xs={11} md={11}>
                    <Grid container justifyContent="space-around">
                        <Grid className='gridControl' item xs={12} md={6}>
                            <Box className='gridItem'>
                                <TextField
                                    {...register('firstName', { required: true, minLength: 3 })}
                                    disabled={!!user}
                                    error={!!errors?.firstName}
                                    helperText={errors?.firstName && 'First name is required'}
                                    className='formElement'
                                    InputProps={{ className: 'formInput' }}
                                    InputLabelProps={{
                                        required: true
                                    }}
                                    id="firstName"
                                    value={firstName}
                                    onChange={(event) => handleInputChange(event, InputTypes.FirstName)}
                                    variant="outlined"
                                    label="First Name" />
                            </Box>
                        </Grid>
                        <Grid className='gridControl' item xs={12} md={6}>
                            <Box className='gridItem'>
                                <TextField
                                    {...register('lastName', { required: true, minLength: 3 })}
                                    disabled={!!user}
                                    error={!!errors?.lastName}
                                    helperText={errors?.lastName && 'Last name is required'}
                                    className='formElement'
                                    InputProps={{ className: 'formInput' }}
                                    InputLabelProps={{
                                        required: true
                                    }}
                                    id="lastName"
                                    value={lastName}
                                    onChange={(event) => handleInputChange(event, InputTypes.LastName)}
                                    variant="outlined"
                                    label="Last Name" />
                            </Box>
                        </Grid>
                        <Grid className='gridControl' item xs={12} md={6}>
                            <Box className='gridItem'>
                                <TextField
                                    {...register('email', { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                    disabled={!!user}
                                    error={!!errors?.email}
                                    helperText={errors?.email && 'A valid email is required'}
                                    className='formElement'
                                    InputProps={{
                                        className: 'formInput',
                                        startAdornment: <InputAdornment position="start"><EmailOutlinedIcon /></InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        required: true
                                    }}
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(event) => handleInputChange(event, InputTypes.Email)}
                                    variant="outlined"
                                    label="Your email" />
                            </Box>
                        </Grid>
                        <Grid className='gridControl' item xs={12} md={6}>
                            <Box className='gridItem'>
                                <TextField
                                    {...register('phone', { required: true, minLength: 12 })}
                                    disabled={!!user}
                                    error={!!errors?.phone}
                                    helperText={errors?.phone && 'Phone number is required'}
                                    className={`formElement phoneInput ${isMobile() ? '' : 'desktop'}`}
                                    id="phone"
                                    variant="outlined"
                                    label="Phone Number"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    InputLabelProps={{
                                        required: true
                                    }}
                                    InputProps={{
                                        className: 'formInput',
                                        inputComponent: TextMaskCustom as any,
                                        startAdornment: isMobile() ? <></> : <InputAdornment position="start"> <img style={{ marginRight: '15px', }} alt={'USA'} src={americaFlag} /> +1 </InputAdornment>
                                    }} />
                            </Box>
                        </Grid>

                        {
                            !user &&
                            (
                                <>
                                    <Grid className='gridControl' item xs={12} md={4}>
                                        <Box className='gridItem'>
                                            <FormControl className='formControl'>
                                                <InputLabel id="profession-label" required={true}>Profession</InputLabel>
                                                <Select
                                                    {...register('profession', { required: true })}
                                                    error={!!errors?.profession}
                                                    className={`formElement ${profession === '' && 'empty'}`}
                                                    inputProps={{ className: 'formInput' }}
                                                    value={profession}
                                                    onChange={($event) => handleSelectChange($event, SelectInputTypes.Profession)}
                                                    label="Profession *"
                                                    labelId='profession-label'
                                                >
                                                    <MenuItem value={'Nurse'}>Nurse</MenuItem>
                                                    <MenuItem value={'Allied Health'}>Allied Health</MenuItem>
                                                </Select>
                                                {errors?.profession && <FormHelperText className='error'>Profession is required</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid className='gridControl' item xs={12} md={4}>
                                        <Box className='gridItem'>
                                            <FormControl className='formControl'>
                                                <InputLabel id="license-label" className="licenseLabel" required={true}>License</InputLabel>
                                                <Select
                                                    {...register('license', { required: true })}
                                                    error={!!errors?.license}
                                                    className={`formElement licenseSelect ${license === '' && 'empty'}`}
                                                    inputProps={{ className: 'formInput' }}
                                                    value={license}
                                                    onChange={($event) => handleSelectChange($event, SelectInputTypes.License)}
                                                    label="License *"
                                                    labelId='license-label'>
                                                    {
                                                        constants.licenseTypes &&
                                                        constants.licenseTypes
                                                            .filter(license => license.profession === profession)
                                                            .map(license => <MenuItem key={license._id} value={license.abbr}>{license.abbr} - {license.name}</MenuItem>)
                                                    }
                                                </Select>
                                                {errors?.license && <FormHelperText className='error'>License is required</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid className='gridControl' item xs={12} md={4}>
                                        <Box className='gridItem'>
                                            <FormControl className='formControl'>
                                                <Controller
                                                    name='specialty'
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            multiple
                                                            disableClearable
                                                            disabledItemsFocusable
                                                            id='specialty'
                                                            className={`formElement ${specialty.length === 0 && 'empty'}`}
                                                            options={
                                                                [{ _id: '', name: 'None', profession: '' }, ...(constants.specialties || []).filter(specialty => specialty.profession === profession)]
                                                            }
                                                            getOptionLabel={(option) => option.name || ''}
                                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                                            value={specialty}
                                                            onChange={($event, specialities) => handleSelectChange($event, SelectInputTypes.Specialty, specialities)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        required: true
                                                                    }}
                                                                    label="Specialty" />
                                                            )}

                                                        />
                                                    )}
                                                />
                                                {specialty?.length >= 10 && (<FormHelperText className="error">Limit Reached (10 Specialty Max)</FormHelperText>)}
                                                {errors?.specialty && <FormHelperText className='error'>Specialty is required</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid className='explainer-container' item xs={11}>
                                        <span className='explainer'>Choosing desired states or cities achieves better job-matching results. (optional)</span>
                                    </Grid>
                                    <Grid item xs={12} md={4} className='gridControl'>
                                        <Box className='gridItem'>
                                            <FormControl className='formControl'>
                                                <Controller
                                                    name='states'
                                                    control={control}
                                                    // rules={{required:true}}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            {...register('states')}
                                                            multiple
                                                            disableClearable
                                                            id='states'
                                                            className={`formControl ${state?.length === 0 && 'empty'}`}
                                                            options={constants.states}
                                                            blurOnSelect
                                                            getOptionLabel={(option) => option.name || ''}
                                                            value={state}
                                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                                            onChange={(event, states) => handleSelectChange(event, SelectInputTypes.State, states)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    // InputLabelProps={{
                                                                    //     required: true
                                                                    // }}
                                                                    variant="outlined"
                                                                    label="States" />
                                                            )}
                                                        />
                                                    )}
                                                />

                                                {errors?.states && <FormHelperText className='error'>State is required</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid className='gridControl' item xs={12} md={4}>
                                        <Box className='gridItem'>
                                            <FormControl className='formControl'>
                                                <Controller
                                                    name='cities'
                                                    control={control}
                                                    // rules={{required:true}}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            {...register('cities')}
                                                            multiple
                                                            disableClearable
                                                            id='cities'
                                                            className={`formControl ${state?.length === 0 && 'empty'}`}
                                                            noOptionsText={cityName.length < 2 ? 'Start writing to search' : 'No options'}
                                                            options={cityOptions}
                                                            getOptionLabel={convertReadableCityName}
                                                            value={city}
                                                            onInputChange={onCityInputChange}
                                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                                            onChange={($event, cities) => handleSelectChange($event, SelectInputTypes.City, cities)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    // InputLabelProps={{
                                                                    //     required: true
                                                                    // }}
                                                                    variant="outlined"
                                                                    label="Cities" />
                                                            )}
                                                            renderTags={(value: City[], getTagProps) => (
                                                                <Box >
                                                                    {value?.map((option: City, index: number) => (
                                                                        <Chip
                                                                            variant="outlined"
                                                                            label={convertReadableCityName(option)}
                                                                            {...getTagProps({ index })}
                                                                        />
                                                                    ))}
                                                                </Box>
                                                            )}
                                                        />
                                                    )}
                                                />
                                                {errors?.cities && <FormHelperText className='error'>City is required</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid className='gridControl' item xs={12} md={4}>
                                        <Box className='gridItem'>
                                            <FormControl className='formControl'>
                                                <InputLabel id="distance-label" className='distanceLabel'>Preferred Distance (miles)</InputLabel>
                                                <Select
                                                    {...register('distance')}
                                                    // error={!!errors?.distance}
                                                    className={`formElement distanceSelect ${distance === '' && 'empty'}`}
                                                    inputProps={{ className: 'formControl' }}
                                                    // value={distance}
                                                    defaultValue={constants?.distance[2]}
                                                    onChange={($event) => handleSelectChange($event, SelectInputTypes.Distance)}
                                                    label="Preferred Distance (miles)"
                                                    labelId='distance-label'>
                                                    {
                                                        constants.distance &&
                                                        constants.distance
                                                            .map(distanceItem => <MenuItem key={distanceItem} value={distanceItem}>{distanceItem}</MenuItem>)
                                                    }
                                                </Select>
                                                {/* {errors?.distance && <FormHelperText className='error'>Distance is required</FormHelperText>} */}
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid container justifyContent="space-between">
                                        <Grid className='gridControl' item xs={12} md={6}>
                                            <Box className='gridItem'>
                                                <FormControl className='formControl'>
                                                    <InputLabel id="referred-by-label" className='referredBy'>Referred By</InputLabel>
                                                    <Select
                                                        {...register('referredBy')}
                                                        // error={!!errors?.referredBy}
                                                        className={`formElement`}
                                                        inputProps={{ className: 'formInput' }}
                                                        value={referredBy}
                                                        onChange={($event) => handleSelectChange($event, SelectInputTypes.ReferredBy)}
                                                        label="Referred By"
                                                        labelId='referred-by-label'>
                                                        {
                                                            referrals &&
                                                            referrals.map(referral => <MenuItem key={referral} value={referral}>{referral}</MenuItem>)
                                                        }
                                                    </Select>
                                                    {/* {errors?.referredBy && <FormHelperText className='error'>Referred By is required</FormHelperText>} */}
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid className={`gridControl`} item xs={12} md={6} justifyContent="space-between">
                                            <Box className='gridItem'>
                                                <FormControl className='formControl'>
                                                    <InputLabel id="shift-preference-label" className='shiftPreference' required={true}>Shift Preference</InputLabel>
                                                    <Select
                                                        {...register('shiftPreference', { required: true })}
                                                        error={!!errors?.shiftPreference}
                                                        className={`formElement`}
                                                        inputProps={{ className: 'formInput' }}
                                                        value={shiftPreference}
                                                        onChange={($event) => handleSelectChange($event, SelectInputTypes.ShiftPreference)}
                                                        label="Shift Preference *"
                                                        labelId='shift-preference-label'>
                                                        <MenuItem value={'AM'}>AM</MenuItem>
                                                        <MenuItem value={'PM'}>PM</MenuItem>
                                                        <MenuItem value={'Mids'}>Mids</MenuItem>
                                                    </Select>
                                                    {errors?.shiftPreference && <FormHelperText className='error'>Shift Preference is required</FormHelperText>}
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {
                                        !job && (
                                            <Grid container justifyContent="space-between">
                                                <Grid className='gridControl' item xs={12} md={6}>
                                                    <Box className='gridItem'>
                                                        <FormControl className='formControl'>

                                                            <MuiFileInput
                                                                {...register('resume')}
                                                                InputProps={{ className: 'formInput' }}
                                                                className='formElement'
                                                                id="resume"
                                                                variant="outlined"
                                                                helperText={fileError && 'Please upload a valid document'}
                                                                hideSizeText
                                                                value={file}
                                                                onChange={handleFileChange}
                                                                inputProps={{ accept: ".doc,.docx,.pdf,.jpg,.jpeg,.png,.page,.pages,.txt,.rtf,.gif" }}
                                                                label={
                                                                    <div>
                                                                        Upload Resume (optional)
                                                                        &nbsp;
                                                                        <Tooltip
                                                                            title="Please upload a copy of your resume in pdf, doc, docx, jpeg, jpg, page, pages, txt, gif, rtf or png format.  The file must be smaller than 10MB."
                                                                            placement="bottom"
                                                                            enterTouchDelay={0}
                                                                        >
                                                                            <HelpIcon />
                                                                        </Tooltip>
                                                                    </div>
                                                                } />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid className={`gridControl`} item xs={12} md={6} justifyContent="space-between">
                                                    <Box className='gridItem'>
                                                        <FormControl className='formControl'>
                                                            <InputLabel id="yearsOfExperience-label" className='experience' required={true}>Years of Experience</InputLabel>
                                                            <Select
                                                                {...register('yearsOfExperience', { required: true })}
                                                                error={!!errors?.yearsOfExperience}
                                                                className={`formElement`}
                                                                inputProps={{ className: 'formInput' }}
                                                                value={yearsOfExperience}
                                                                onChange={($event) => handleSelectChange($event, SelectInputTypes.YearsOfExperience)}
                                                                label="Years of Experience *"
                                                                labelId='yearsOfExperience-label'>
                                                                {
                                                                    constants?.yearsOfExperience &&
                                                                    constants?.yearsOfExperience?.map(exp => <MenuItem key={exp} value={exp}>{exp}</MenuItem>)
                                                                }
                                                            </Select>
                                                            {errors?.yearsOfExperience && <FormHelperText className='error'>Years of Experience is required</FormHelperText>}
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )
                                    }


                                    {brand?.isBriefcaseEnabled &&
                                        <>
                                            <Grid className='explainer-container' item xs={11}>
                                                <span className='explainer'>Select your password for the account creation</span>
                                            </Grid>
                                            <Grid className='gridControl' item xs={12} md={6}>
                                                <Box className='gridItem'>
                                                    <TextField
                                                        {...register('password',
                                                            {
                                                                required: !user,
                                                                minLength: 8,
                                                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
                                                            })
                                                        }
                                                        error={!!errors?.password}
                                                        className='formElement'
                                                        helperText={errors?.password && 'Password is required. Password should contain at least one of each Uppercase, Lowercase, Number and Special Character.'}
                                                        InputProps={{
                                                            className: 'formInput',
                                                            endAdornment: <InputAdornment position="start">
                                                                {
                                                                    visiblePassword
                                                                        ? <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setVisiblePassword(false)} />
                                                                        : <VisibilityOffOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setVisiblePassword(true)} />
                                                                } </InputAdornment>
                                                        }}
                                                        InputLabelProps={{
                                                            required: true
                                                        }}
                                                        id="password"
                                                        variant="outlined"
                                                        autoComplete="new-password"
                                                        type={visiblePassword ? 'text' : 'password'}
                                                        label="Password" />
                                                </Box>
                                            </Grid>
                                            <Grid className='gridControl' item xs={12} md={6}>
                                                <Box className='gridItem'>
                                                    <TextField
                                                        {...register('confirmPassword',
                                                            {
                                                                validate: (val: string) => {
                                                                    if (watch('password') !== val) {
                                                                        return "Your passwords do no match";
                                                                    }
                                                                },
                                                                required: !user,
                                                                minLength: 8,
                                                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
                                                            })
                                                        }
                                                        error={!!errors?.confirmPassword}
                                                        helperText={errors?.confirmPassword && 'Passwords do not match'}
                                                        className='formElement'
                                                        InputProps={{
                                                            className: 'formInput',
                                                            endAdornment: <InputAdornment position="start">{
                                                                visibleRepeatPassword
                                                                    ? <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setVisibleRepeatPassword(false)} />
                                                                    : <VisibilityOffOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setVisibleRepeatPassword(true)} />
                                                            }</InputAdornment>
                                                        }}
                                                        InputLabelProps={{
                                                            required: true
                                                        }}
                                                        id="confirmPassword"
                                                        autoComplete="new-password"
                                                        variant="outlined"
                                                        type={visibleRepeatPassword ? 'text' : 'password'}
                                                        label="Confirm Password" />
                                                </Box>
                                            </Grid>
                                        </>
                                    }
                                </>
                            )
                        }
                        <>
                            <Grid className='explainer-container' item xs={11}>
                                <span className='explainer'>If you have been in touch with a recruiter, select below (optional)</span>
                            </Grid>
                            <Grid className='recruiterGrid' item xs={12}>
                                <Box className='gridItem'>
                                    <FormControl className='formControl'>
                                        <InputLabel id="recruiter-label">Recruiter info</InputLabel>
                                        <Select
                                            {...register('recruiter')}
                                            className='formElement'
                                            inputProps={{ className: 'formInput' }}
                                            value={recruiter}
                                            onChange={($event) => handleSelectChange($event, SelectInputTypes.Recruiter)}
                                            label='Recruiter info'
                                            labelId='recruiter-label'>
                                            {
                                                ([{ _id: '', firstName: 'None', lastName: '', email: null }, ...recruiters?.list])?.map(user => <MenuItem key={user._id} value={user._id}>{user.firstName} {user.lastName} {user.email ? `<${user.email}>` : ''}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </>
                        {
                            errorMessage &&
                            <Grid item xs={12} className='errorGrid'>
                                <div className='errorBox'>
                                    <WarningAmberOutlinedIcon />
                                    <span className='message'>{errorMessage}</span>
                                </div>
                            </Grid>
                        }
                        <Grid
                            className='applyContainer'
                            item xs={12}>
                            <LoadingButton
                                type='submit'
                                disabled={licenseError || !isValid}
                                sx={{ bgColor: brand.color }}
                                loading={submittingForm}
                                className='applyBtn'
                                variant="contained">
                                {job ? 'CONTINUE' : 'SUBMIT'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ApplicantForm;