import { userData } from "configs";
import { InitialValues, ProfessionalUser } from "configs/userData/userData.types";
import { createContext, useContext, useState, useEffect } from "react";
import { UserProviderProps } from "./user.types";

const USER_STORAGE_KEY_NAME = 'user_data';

const initialValues: InitialValues = {
    user: userData,
    setUserData: () => { },
    updateUserData: (professional: ProfessionalUser | null) => { },
    resetUserData: () => { }
}
  
export const UserDataContext = createContext(initialValues)

const UserDataContextProvider = ({ children }: UserProviderProps) => {
  const [userData, setUserData] = useState<ProfessionalUser | null>(initialValues.user)


  useEffect(() => {
    let userDataStored = localStorage.getItem(USER_STORAGE_KEY_NAME)

    if (userDataStored) {
        let parsedUserDataStored = JSON.parse(userDataStored) as ProfessionalUser
        setUserData(parsedUserDataStored);
    }
  }, [])

  const updateUserData = (professional: ProfessionalUser | null) => {
    setUserData(professional);
    localStorage.setItem(USER_STORAGE_KEY_NAME, JSON.stringify(professional))
  }

  const resetUserData = () => {
    updateUserData(null);
  }

  return (
    <UserDataContext.Provider value={{ user: userData, setUserData, updateUserData, resetUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

const useUserData = () => {
  return useContext(UserDataContext)
}
  

export { UserDataContextProvider, useUserData };
