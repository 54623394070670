export type AdditionalDocument = {
	id?: string,
	name?: string,
	fileUrl?: string,
	downloadUrl?: string,
	uploadedAt?: Date
}

export function prepAdditionalDocument(doc: AdditionalDocument): AdditionalDocument {
	delete doc.fileUrl;
	delete doc.downloadUrl;
	delete doc.uploadedAt;

	return doc;
}

export function parseAdditionalDocument(json: any): AdditionalDocument {
	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}