import { Brand } from './brand.types'

export const DEFAULT_BRAND_NAME = 'Praos Health'
export const DEFAULT_FAVICON = 'https://assets.praoshealth.com/cropped-praos_favicon-192x192.png';
export const BRIEFCASE_SERVICE = "BRIEFCASE";
export const JOB_BOARD_SERVICE = "JOB_BOARD";


const brand: Brand = {
  id: '',
  name: DEFAULT_BRAND_NAME,
  pathName: '',
  logo: 'https://assets.praoshealth.com/praos-logo-RGB.png',
  banner: `${process.env.REACT_APP_NURSE_URL}/img/bitmap@3x.jpg`,
  color: '#613794',
  appleStoreUrl: 'https://apps.apple.com/us/app/praos-health/id1229105635',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.praoshealth&hl=en&gl=US',
  favicon: DEFAULT_FAVICON,
  isBriefcaseEnabled: true,
  isJobBoardEnabled: true,
}

export default brand
