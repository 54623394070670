import { addMinutes, midnight } from "@praos-health/core/utilities/date";
import { Address } from "./address";

export type User = {
	_id?: string,
	intId?: number,
	createdAt?: Date,
	firstName?: string,
	lastName?: string,
	email?: string,
	emailVerifiedAt?: Date,
	emailCommunicationEnabled?: boolean,
	phoneNumber?: string,
	dateOfBirth?: Date | boolean,
	profilePicUrl?: string,
	profilePicThumbUrl?: string,
	languages?: string[],
	address?: Address,
	deactivatedAt?: Date,
	deactivatedBy?: User,
	deactivationNotes?: string,
	signupIpAddress?: string,
	signupChannel?: string
}

export function prepUser(user: User): User {
	if (user) {
		if (user.profilePicUrl !== null) {
			delete user.profilePicUrl;
		}
	
		delete user.profilePicThumbUrl;
	
		if (user.dateOfBirth instanceof Date) {
			user.dateOfBirth = midnight(new Date(user.dateOfBirth), true);
		}
	
		if (user.phoneNumber) {
			user.phoneNumber = user.phoneNumber.replace(/\D/g,'');
		}
	}

	return user;
}

export function parseUser(json: any): User {
	if (typeof json.createdAt === 'number') {
		json.createdAt = new Date(json.createdAt);
	}

	if (typeof json.activatedAt === 'number') {
		json.activatedAt = new Date(json.activatedAt);
	}

	if (typeof json.dateOfBirth === 'number') {
		json.dateOfBirth = addMinutes(new Date(json.dateOfBirth).getTimezoneOffset(), new Date(json.dateOfBirth));
	}

	if (typeof json.emailVerifiedAt === 'number') {
		json.emailVerifiedAt = new Date(json.emailVerifiedAt);
	}

	if (typeof json.deactivatedAt === 'number') {
		json.deactivatedAt = new Date(json.deactivatedAt);
	}

	return json;
}