import { Job } from "./job";
import { JobApplication } from "./job-application";
import { JobType } from "./job-type";

export type JobStatus = 'OPEN' | 'CANCELLED' | 'EXPIRED' | 'REQUIREMENT_FULFILLED';

export function jobStatusToString(job: Job, application?: JobApplication, bufferMinutes?: number): string {
	if (!application) {
		return job.status;
	}

	if (application.ignoredAt) {
		return 'FILLED';
	}

	if (application.cancelledAt) {
		return 'CANCELLED';
	}

	if (application.expiredAt) {
		return 'EXPIRED';
	}

	if (application.withdrawnAt) {
		return 'WITHDRAWN';
	}

	if (application.rejectedAt) {
		return 'REJECTED';
	}

	let isException = false;
	
	if (job.endDate) {
		const bufferDate = new Date((new Date()).getTime() + (bufferMinutes || 0) * 60000);
		isException = !application.approvedAt && !application.cancelledAt && job.type === JobType.PerDiem && (job.endDate < bufferDate);
	}

	if (isException) {
		if (application.status === "ENROUTE") {
			return 'NOT STARTED';
		}

		if (application.status === "ACCEPTED") {
			return 'NOT ENROUTED';
		}

		if (application.status === "STARTED") {
			return 'NOT COMPLETED';
		}

		if (application.status === "COMPLETED") {
			return 'NOT APPROVED';
		}
	}

	if (application.approvedAt) {
		return 'APPROVED';
	}

	if (application.completedAt) {
		return 'COMPLETED';
	}

	if (application.timesheet) {
		return "STARTED";
	}

	if (application.enrouteAt) {
		return "EN ROUTE";
	}

	if (application.confirmedAt) {
		return "CONFIRMED";
	}

	if (application.submittedAt) {
		return "SUBMITTED";
	}

	return 'APPLIED';
}
