export type Notification = {
	list: {
    type?: string,
    user?: {
			_id?: string,
			intId?: number,
			firstName?: string,
			lastName?: string
		},
    message: string,
    createdAt?: Date,
    readAt?: Date

	}[],
	count: number
}

export function parseNotification(json: any): Notification {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	if (json.readAt) {
		json.readAt = new Date(json.readAt);
	}

	return json;
}