import { ReactNode, Dispatch, SetStateAction } from 'react'

export interface BrandProviderProps {
  children: ReactNode
}

export const BRIEFCASE_SERVICE = "BRIEFCASE";
export const JOB_BOARD_SERVICE = "JOB_BOARD";

export interface Service {
  name?: string,
  isEnabled?: boolean
}

export interface Brand {
  id: string,
  name: string,
  pathName: string,
  logo: string,
  banner: string,
  color: string,
  coBrandedUrl?: string,
  companyUrl?: string,
  googlePlayUrl?: string
  appleStoreUrl?: string,
  favicon?: string
  isBriefcaseEnabled?: boolean,
  isJobBoardEnabled?: boolean,
  url?: string | null
}

export interface InitialValues {
  brand: Brand,
  setBrand: Dispatch<SetStateAction<Brand>>,
  resetBrand: () => void
}
