import { clone } from "@praos-health/core/utilities/object";
import { EducationLevel } from "./models";
import { ajax } from "./ajax"

export class EducationLevelService {
	private _educationLevelCache: EducationLevel[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}
	
	async list(auth: string, profession?: string): Promise<EducationLevel[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/educationlevels`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: EducationLevel[];

		if (!this._educationLevelCache) {
			this._educationLevelCache = await ajax(`${this.apiUrl}/briefcase/educationlevels`, 'GET', auth);
		}

		result = this._educationLevelCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}
		
		return clone(result);
	}
}