import React, { useState } from 'react';
import './Header.scss';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MenuItem from '@mui/material/MenuItem';
import appleBadge from '../../../../assets/images/apple-badge.png';
import googleBadge from '../../../../assets/images/google-badge.png';
import { useBrand } from 'contexts';
import { useNavigate } from 'react-router-dom';

enum AuthType {
  login = 'login',
  signup = 'signup',
  refer = 'refer'
}

const Header: React.FC<{}> = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { brand } = useBrand();
  const navigate = useNavigate()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleAuthLinks = (type: AuthType) => {
    if (type === AuthType.login) {
      window.location.href = brand.coBrandedUrl ?
        `${process.env.REACT_APP_NURSE_URL}/#/co-branded-login/${brand.coBrandedUrl}` : `${process.env.REACT_APP_NURSE_URL}/#/login`
    } else if (type === AuthType.signup) {
      if (brand.companyUrl) {
        navigate(`/${brand.companyUrl}/apply-now`)
      } else {
        window.location.href = `${process.env.REACT_APP_NURSE_URL}/#/signup`
      }
    } else if (type === AuthType.refer && brand.companyUrl) {
      navigate(`/${brand.companyUrl}/refer`)
    }
    handleCloseNavMenu()
  }

  const handleHome = () => {
    if (brand.companyUrl) {
      navigate(`/${brand.companyUrl}`)
    } else {
      window.location.href = `/`
    }
  }

  return (
    <AppBar position="static" className='appBar'>
      <Container maxWidth="xl">
        <Toolbar disableGutters className='toolbar-wrapper'>
          <Box sx={{ mr: 4, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              name='mobileMenuIcon'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              data-test='mobileMenu'
            >
              {brand?.isBriefcaseEnabled &&
                <MenuItem data-test='loginButtonMob' className='headerButton' onClick={() => handleAuthLinks(AuthType.login)}>
                  <Typography textAlign="center">Log In</Typography>
                </MenuItem>
              }
              <MenuItem onClick={() => handleAuthLinks(AuthType.signup)}>
                <Typography textAlign="center">Apply Now</Typography>
              </MenuItem>
              {
                brand?.companyUrl &&
                  <MenuItem onClick={() => handleAuthLinks(AuthType.refer)}>
                    <Typography textAlign="center">Refer a Friend</Typography>
                  </MenuItem>
              }
              {brand?.isBriefcaseEnabled &&
                <MenuItem data-test='AppstoreButtonMob' className='headerButton' onClick={() => {
                  window.location.href = brand.appleStoreUrl ? brand.appleStoreUrl : '#';
                  handleCloseNavMenu();
                }}>
                  <Typography textAlign="center">Download on the App Store</Typography>
                </MenuItem>
              }
              {brand?.isBriefcaseEnabled &&
                <MenuItem data-test='PlaystoreButtonMob' className='headerButton' onClick={() => {
                  window.location.href = brand.googlePlayUrl ? brand.googlePlayUrl : '#';
                  handleCloseNavMenu();
                }}>
                  <Typography textAlign="center">Get it on Google Play</Typography>
                </MenuItem>
              }
            </Menu>
          </Box>
          <Box className="brand-logo-wrapper" sx={{ display: { md: 'flex' } }}>
            <img className='brandLogo' alt='Logo' src={brand.logo} onClick={() => handleHome()}  />
          </Box>
          {brand?.isBriefcaseEnabled &&
            <Box data-test='MobileButtons' className='appLinks headerButton' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <img
                alt='Download on Play Store'
                src={appleBadge}
                onClick={() => window.location.href = brand.appleStoreUrl ? brand.appleStoreUrl : '#'} />
              <img
                alt='Get it on Google Play'
                src={googleBadge}
                onClick={() => window.location.href = brand.googlePlayUrl ? brand.googlePlayUrl : '#'} />
            </Box>
          }

          <Box
		  	className='login-singup-wrapper'
            sx={{ flexGrow: brand?.isBriefcaseEnabled ? 0 : 1,
              mx: 4, display: { xs: 'none', md: 'flex' }, alignItems: 'center',
              justifyContent: brand?.isBriefcaseEnabled ? 'normal' : 'end' }}>
                {brand?.isBriefcaseEnabled &&
                  <Button 
                    size="small"
                    data-test='loginButtonWeb'
                    onClick={() => handleAuthLinks(AuthType.login)}>
                    <Avatar sx={{ color: brand.color, mx:1, border: '1px solid', bgcolor: '#ffffff' }}>
                      <PersonOutlineIcon />
                    </Avatar>
                    LOGIN
                  </Button>
                }
            {
              brand?.companyUrl &&
              <Button
                data-test="refer-button"
                sx={{ margin: '0 10px' }} 
                size="small"
                onClick={() => handleAuthLinks(AuthType.refer)}>
                <div style={{ lineHeight: '16px' }}>
                  REFER A <br/>FRIEND
                </div>
              </Button>
            }
            <Button
              sx={{ bgcolor: brand.color, mx: 2, height: 40 }}
              variant="contained"
              size="small"
              onClick={() => handleAuthLinks(AuthType.signup)}
            >
              APPLY NOW
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;