export enum RateFrequency {
	Daily = 'DAILY',
	Fixed = 'FIXED',
	Flat = 'FLAT',
	Hourly = 'HOURLY',
	Weekly = 'WEEKLY',
	Monthly = 'MONTHLY',
	Yearly = 'YEARLY'
}

export enum RateType {
	Standard = 'Standard',
	Amount = 'Amount',
	Range = 'Range',
	Differential = 'Differential',
	Bonus = 'Bonus',
	Overtime = 'Overtime',
}

export type JobRate = {
	type?: 'Standard' | 'Differential' | 'Bonus' | 'Overtime' | 'Variable',
	frequency?: 'DAILY' | 'FIXED' | 'FLAT' | 'HOURLY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY'
} | {
	type?: 'Amount',
	frequency?: 'DAILY' | 'FIXED' | 'FLAT' | 'HOURLY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY',
	value?: number
} | {
	type?: 'Range',
	frequency?: 'DAILY' | 'FIXED' | 'FLAT' | 'HOURLY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY',
	from?: number,
	to?: number
}

export function parseJobRate(json: any): JobRate {
	return json;
}

export function rateToString(rate?: JobRate) {
	if (!rate) {
		return 'Variable';
	}

	switch (rate.type) {
		case RateType.Amount:
			return `$${rate.value}${rateFrequencyToString(rate.frequency as RateFrequency)}`;
		case RateType.Range:
			return `$${rate.from} - $${rate.to} ${rateFrequencyToString(rate.frequency as RateFrequency)}`;
		default:
			return rate.type;
	}
}

export function rateFrequencyToString(frequency: RateFrequency): string {
	switch (frequency) {
		case RateFrequency.Hourly:
			return '/hr';
		case RateFrequency.Daily:
			return '/day';
		case RateFrequency.Weekly:
			return '/week';	
		case RateFrequency.Monthly:
			return '/month';
		case RateFrequency.Yearly:
			return '/year';	
		default:
			return '';
	}
}