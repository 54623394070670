import { createContext, useContext, useState } from "react";
import { JobIdProviderProps } from "./job-id.types";
import { InitialValues, JobId } from "configs/jobId/jobId.types";
import jobId from "configs/jobId/jobId";

const initialValues: InitialValues = {
    jobId,
    setJobId: () => { }
}

export const JobIdContext = createContext(initialValues)
const JobIdProvider = ({ children }: JobIdProviderProps) => {
  const [jobId, setJobId] = useState<JobId>(initialValues.jobId)

  return (
    <JobIdContext.Provider value={{ jobId, setJobId }}>
      {children}
    </JobIdContext.Provider>
  );
};

const useJobId = () => {
  return useContext(JobIdContext)
}
  

export { JobIdProvider, useJobId };
