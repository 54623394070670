export type Organization = {
	_id?: string,
	name?: string,
	address1?: string,
	address2?: string,
	city?: string,
	state?: string,
	zip?: string,
	facility?: {
		type?: string,
		employees?: string,
		businessName?: string,
		logoThumbURL?: string,
		description?: string,
		color?: string,
		url?: string,
		imageURL?: string,
		imageThumbURL?: string,
		logoURL?: string,
		bannerURL?: string,
		bannerThumbURL?: string,
		enabledJobBoard?: boolean
	}
}

export function parseOrganization(json: any): Organization {
	return json;
}