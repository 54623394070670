import { clone } from "@praos-health/core/utilities/object";
import { ajax } from "./ajax"
import { FacilityType } from "./models";

export class FacilityTypeService {
	private _facilityTypeCache: FacilityType[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}
	
	async list(auth: string): Promise<FacilityType[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/facility-types`, 'GET', auth, undefined, null);
		}

		let result: FacilityType[];

		if (!this._facilityTypeCache) {
			this._facilityTypeCache = await ajax(`${this.apiUrl}/briefcase/facility-types`, 'GET', auth);
		}

		result = this._facilityTypeCache;

		return clone(result);
	}
}
