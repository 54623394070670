import { ListJobsOptions } from "@praos-health/jobs-client";
import { DEFAULT_BRAND_NAME } from "configs/brand/brand";
import jobsCounter from "configs/jobsCounter/jobsCounter";
import { InitialValues, JobsCounter } from "configs/jobsCounter/jobsCounter.types";
import { useApi, useBrand } from "contexts";
import { createContext, useContext, useEffect, useState } from "react";
import { JobsCounterProviderProps } from "./jobs-counter.types";

const initialValues: InitialValues = {
    jobsCounter,
    setJobsCounter: () => { }
}

export const JobsCounterContext = createContext(initialValues)
const JobsCounterProvider = ({ children }: JobsCounterProviderProps) => {
  const [jobsCounter, setJobsCounter] = useState<JobsCounter>(initialValues.jobsCounter)
  const { jobService } = useApi()
  const { brand } = useBrand()

  useEffect(() => {
    if (jobService) {
        let fetchJobsPayload: ListJobsOptions = {};
        // adding limit as 1 to minimize the payload for count
        fetchJobsPayload.limit = 1;

        if (brand.name !== DEFAULT_BRAND_NAME) {
            fetchJobsPayload.organizationPathName = brand.name;
        }
        
        jobService.listExternal(process.env.REACT_APP_X_API_KEY || '', fetchJobsPayload)
        .then(data => {
            jobsCounter.total = data.count
            setJobsCounter(Object.assign({}, jobsCounter))
        })
        .catch(error => console.error("Jobs could not be retrieved", error));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  return (
    <JobsCounterContext.Provider value={{ jobsCounter, setJobsCounter }}>
      {children}
    </JobsCounterContext.Provider>
  );
};

const useJobsCounter = () => {
  return useContext(JobsCounterContext)
}
  

export { JobsCounterProvider, useJobsCounter };
