import { addMinutes, midnight } from "@praos-health/core/utilities/date";
import { User } from "./user";

export type License = {
	id?: string,
	licenseType?: string,
	licenseBody?: string,
	licenseState?: string,
	issueDate?: Date,
	expirationDate?: Date,
	licenseNumber?: string,
	isCompact?: boolean,
	verifiedBy?: User,
	verifiedAt?: Date,
	verificationText?: Date,
	dateOfBirth?: Date,
	ssn?: string,
	manualOverride?: boolean,
	fileUrl?: string,
	downloadUrl?: string,
	uploadedAt?: Date
}

export type LicenseHistory = {
	licenseType?: string,
	licenseBody?: string,
	fileUrl?: string,
	uploadedAt?: Date,
	createdAt?: Date
}

export function prepLicense(lic: License): License {
	delete lic.verifiedBy;
	delete lic.verifiedAt;
	delete lic.fileUrl;
	delete lic.downloadUrl;
	delete lic.uploadedAt;
	delete lic.licenseState;
	delete lic.verificationText;

	if (lic.issueDate) {
		lic.issueDate = midnight(new Date(lic.issueDate), true);
	}

	if (lic.expirationDate) {
		lic.expirationDate = midnight(new Date(lic.expirationDate), true);
	}

	if (lic.dateOfBirth) {
		lic.dateOfBirth = midnight(new Date(lic.dateOfBirth), true);
	}

	return lic;
}

export function parseLicense(json: any): License {
	if (json.issueDate) {
		json.issueDate = addMinutes(new Date(json.issueDate).getTimezoneOffset(), new Date(json.issueDate));
	}

	if (json.expirationDate) {
		json.expirationDate = addMinutes(new Date(json.expirationDate).getTimezoneOffset(), new Date(json.expirationDate));
	}

	if (json.verifiedAt) {
		json.verifiedAt = new Date(json.verifiedAt);
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}

export function parseLicenseHistory(json: any): LicenseHistory {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}