import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type Competency = {
	id?: string,
	title?: string,
	startDate?: Date,
	endDate?: Date,
	moduleContentUrl?: string,
	fileUrl?: string,
	downloadUrl?: string
}

export function prepCompetency(competency: Competency): Competency {
	delete competency.fileUrl;
	delete competency.downloadUrl;

	if (competency.startDate) {
		competency.startDate = midnight(new Date(competency.startDate), true);
	}

	if (competency.endDate) {
		competency.endDate = midnight(new Date(competency.endDate), true);
	}

	return competency;
}

export function parseCompetency(json: any): Competency {
	if (json.startDate) {
		json.startDate = addMinutes(new Date(json.startDate).getTimezoneOffset(), new Date(json.startDate));
	}

	if (json.endDate) {
		json.endDate = addMinutes(new Date(json.endDate).getTimezoneOffset(), new Date(json.endDate));
	}

	return json;
}