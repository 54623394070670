import { JobRate, parseJobRate } from "./job-rate";
import { JobLocation, parseJobLocation } from "./job-location";
import { JobType } from "./job-type";
import { JobDurationUnit } from "./job-duration-unit";
import { Department, parseDepartment } from "./department";
import { User, parseUser } from "./user";
import { JobStatus } from "./job-status";
import { Organization, parseOrganization } from "./organization";
import { JobMatches, parseJobMatches } from "./job-matches";
import { JobApplication, JobApplicationStatus, parseJobApplication } from "./job-application";
import { JobClient } from "./job-client";
import { addHours, addMinutes } from "@praos-health/core/utilities/date";

export type Job = {
	_id?: any,
	sqlId?: string,
	intId?: number,
	thirdPartyId?: string,
	type?: JobType,
	title?: string,
	description?: string,
	keywords?: string[],
	imageUrl?: string,
	licenseType?: string,
	specialties?: string[],
	location?: JobLocation,
	certification?: string,
	additionalCertifications?: string[],
	minimumEducation?: string,
	minimumExperience?: string,
	startDate?: Date,
	endDate?: Date,
	timezone?: number,
	duration?: number,
	durationUnit?: JobDurationUnit,
	sendOrientation?: boolean,
	totalRequirement?: number,
	requirementFulfilled?: number,
	status?: JobStatus,
	client?: JobClient,
	department?: Department,
	delegatedAt?: Date,
	delegatedToUser?: User,
	createdAt?: Date,
	createdByUser?: User,
	cancelledAt?: Date,
	cancelledByUser?: User,
	updatedAt?: Date,
	isMarketplace?: boolean,
	isIrp?: boolean,
	rate?: JobRate,
	isTelehealth?: boolean,
	matches?: JobMatches,
	organization?: Organization,
	applicationCount?: number,
	appliedCount?: number,
	confirmedCount?: number,
	applications?: JobApplication[],
	isFavorite?: boolean,
	isExclusive?: boolean,
	isEvergreen?: boolean,
	autoRePost?: boolean
}

export type JobV1 = {
	id?: string,
	intId?: number,
	title?: string,
	description?:string,
	type?: string,
	licenseType?: string,
	speciality?: string,
	facilityLocationAddress1?: string,
	facilityLocationAddress2?: string,
	facilityLocationState?: string,
	facilityLocationCity?: string,
	facilityLocationZip?: string,
	workLocationAddress1?: string,
	workLocationAddress2?: string,
	workLocationCity?: string,
	workLocationState?: string,
	workLocationZip?: string,
	locationLatitude?: number,
	locationLongitude?: number,
	location?: string,
	certifications?: string,
	additionalCertifications?: string,
	minimumEducation?: string,
	minimumExperience?: string,
	rate?: string,
	startDate?: string,
	rateType?: string,
	endDate?: string,
	shiftLength?: string,
	keywords?: string,
	orientation?: number,
	totalRequirement?: number,
	requirementFulfilled?: number,
	applicationId?: string,
	jobConfirmedId?: string,
	status?: string,
	createdAt?: string,
	updatedAt?: string,
	jobTimezone?: number,
	isAddressSame?: number,
	hospitalId?: string,
	department?:{
		 id?: string,
		 name?: string
	},
	isMarketplace?: number,
	isPRN?: number,
	isFavorite?: number,
	locationType?: string,
	telehealth?: number,
	durationUnit?: string,
	rateLabel?: string,
	isDateSelected?: number,
	locationId?: string,
	hospital?: {
		 id?: string,
		 facility?: {
				businessName?: string,
				type?: string,
				employees?: string,
				description?: string,
				url?: string,
				imageURL?: string,
				imageThumbURL?: string,
				logoURL?: string,
				logoThumbURL?: string,
				bannerURL?: string,
				bannerThumbURL?: string
		 },
		 address1?: string,
		 address2?: string,
		 city?: string,
		 state?: string,
		 zip?: string,
		 isFavorite?: boolean
	},
	new?: number,
	applicantsCount?: number,
	reviewScore?: number,
	jobCreator?: {
		 id?: string,
		 firstName?: string,
		 lastName?: string,
		 phoneNumber?: string,
		 email?: string
	},
	supervisor?: any,
	contactPerson?: {
		 id?: string,
		 firstName?: string,
		 lastName?: string,
		 phoneNumber?: string,
		 email?: string
	},
	chatEnabled?: number,
	canDelegate?: number,
	canAssign?: number,
	canRate?: number,
	canAprroveTime?: number,
	canEdit?: number,
	showRate?: number,
	isSubmittalSkillChecklist?: boolean,
	isSubmittalResume?: boolean,
	isSubmittalReferences?: boolean,
	exception?: number,
	nurse?: User
}

export type JobApplicationV1 = {
	id?: string,
	job?: {
		id?: string,
		title?: string,
		description?: string,
		intId?: number,
		type?: string,
		licenseType?: string,
		speciality?: string,
		facilityLocationAddress1?: string,
		facilityLocationAddress2?: string,
		facilityLocationState?: string,
		facilityLocationCity?: string,
		facilityLocationZip?: string,
		workLocationAddress1?: string,
		workLocationAddress2?: string,
		workLocationCity?: string,
		workLocationState?: string,
		workLocationZip?: string,
		locationLatitude?: number,
		locationLongitude?: number,
		location?: string,
		certifications?: string,
		additionalCertifications?: string,
		minimumEducation?: string,
		minimumExperience?: string,
		rate?: string,
		startDate?: string,
		rateType?: string,
		endDate?: string,
		shiftLength?: string,
		keywords?: string,
		orientation?: number,
		totalRequirement?: number,
		requirementFulfilled?: number,
		createdAt?: string,
		updatedAt?: string,
		telehealth?: number,
		durationUnit?: string,
		rateLabel?: string,
		isDateSelected?: number,
		isFavorite?: number
	}
	W9Shared?: number,
	isMarketplace?: number,
	isPRN?: number,
	imageUrl?: string,
	driverLicenseShared?: number,
	resumeShared?: number,
	vehicleInsuranceShared?: number,
	liabilityInsuranceShared?: number,
	certificationsShared?: any[],
	additionalCertificationsShared?: any[],
	xRayDocumentShared?: number,
	drugTestDocumentShared?: number,
	vaccinationShared?: number,
	department?: {
		id?: string,
		name?: string
	},
	hospital?: {
		id?: string,
		facility?: {
			businessName?: string,
			type?: string,
			employees?: string,
			description?: string,
			url?: string,
			imageURL?: string,
			imageThumbURL?: string,
			logoURL?: string,
			logoThumbURL?: string,
			bannerURL?: string,
			bannerThumbURL?: string
		},
		address1?: string,
		address2?: string,
		city?: string,
		state?: string,
		zip?: string
	},
	user?: {
		id?: string,
		firstName?: string,
		lastName?: string,
		dateOfBirth?: string,
		userType?: string,
		profilePic?: string,
		profilePicThumb?: string,
	},
	jobCreator?: {
		id?: string,
		firstName?: string,
		lastName?: string,
		phoneNumber?: string,
		email?: string,
	},
	contactPerson?: {
		id?: string,
		firstName?: string,
		lastName?: string,
		phoneNumber?: string,
		email?: string,
	}
}

export function parseJob(json: any): Job {
	const result: Job = json;
	
	if (json.location) {
		result.location = parseJobLocation(json.location);
	}

	if (json.startDate) {
		result.startDate = new Date(json.startDate);
	}

	if (json.endDate) {
		result.endDate = new Date(json.endDate);
	}

	if (json.department) {
		result.department = parseDepartment(json.department);
	}

	if (json.delegatedAt) {
		result.delegatedAt = new Date(json.delegatedAt);
	}

	if (json.delegatedToUser) {
		result.delegatedToUser = parseUser(json.delegatedToUser);
	}

	if (json.createdAt) {
		result.createdAt = new Date(json.createdAt);
	}

	if (json.createdByUser) {
		result.createdByUser = parseUser(json.createdByUser);
	}

	if (json.cancelledAt) {
		result.cancelledAt = new Date(json.cancelledAt);
	}

	if (json.cancelledByUser) {
		result.cancelledByUser = parseUser(json.cancelledByUser);
	}

	if (json.updatedAt) {
		result.updatedAt = new Date(json.updatedAt);
	}

	if (json.rate) {
		result.rate = parseJobRate(json.rate);
	}

	if (json.matches) {
		result.matches = parseJobMatches(json.matches);
	}
	
	if (json.organization) {
		result.organization = parseOrganization(json.organization);
	}

	if (json.applications) {
		for (let i = 0; i < json.applications.length; i++) {
			result.applications[i] = parseJobApplication(json.applications[i]);
		}
	}

	return result;
}

export function sharedDocumentToString(value: string): string {
	if (value.startsWith('certifications.')) { 
		return value.substring(15);
	}

	if (value.startsWith('additionalCertifications.')) { 
		return value.substring(25);
	}

	switch (value) {
		case 'driverLicense':
			return 'Driver License';
		case 'resume':
			return 'Resume';
		case 'vehicleInsurance':
			return 'Vehicle Insurance';
		case 'liabilityInsurance':
			return 'Liability Insurance';
		case 'xRayDocument':
			return 'TB Test';
		case 'drugTestDocument':
			return '';
		case 'vaccination':
			return 'Vaccinations';
	
		default:
			break;
	}

	return value;
}

export function jobToV1(item: Job, endTimeBufferMinutes: number = 0, userId: string = '', userType: string = '', application?: JobApplication): JobV1 {
	const result: JobV1 = {
		id: item.sqlId || item._id?.toString(),
		intId: item.intId,
		title: item.title,
		type: item.type,
		applicantsCount: (item as any).applicationCount || item.applications?.length || 0,
		description: item.description || '',
		licenseType: item.licenseType,
		durationUnit: item.durationUnit,
		status: item.status,
		facilityLocationAddress1: item.organization?.address1,
		facilityLocationAddress2: item.organization?.address2,
		facilityLocationState: item.organization?.state,
		facilityLocationCity: item.organization?.city,
		facilityLocationZip: item.organization?.zip,
		isAddressSame: (item.location?.name === 'Main Location') ? 1 : 0,
		workLocationAddress1: item.location?.address1 || '',
		workLocationAddress2: item.location?.address2 || '',
		workLocationCity: item.location?.city,
		workLocationState: item.location?.state,
		workLocationZip: item.location?.zip,
		locationLatitude: item.location?.coordinates?.[1],
		locationLongitude: item.location?.coordinates?.[0],
		locationType: item.location?.name,
		location: item.location?.state,
		totalRequirement: item.totalRequirement,
		minimumEducation: item.minimumEducation,
		minimumExperience: item.minimumExperience,
		requirementFulfilled: item.requirementFulfilled,
		speciality: item.specialties ? item.specialties.join(',') : 'None',
		certifications: item?.certification || 'None',
		additionalCertifications: item.additionalCertifications ? item.additionalCertifications.join(',') : 'None',
		shiftLength: item.duration != undefined ? item.duration.toString() : item.durationUnit,
		orientation: item.sendOrientation ? 1 : 0,
		isMarketplace: item.isMarketplace ? 1 : 0,
		isPRN: item.isIrp ? 1 : 0,
		telehealth: item.isTelehealth ? 1 : 0,
		isDateSelected: item.startDate ? 1 : 0,
		isFavorite: (item as any).isFavorite ? 1 : 0,
		startDate: item.startDate ? addHours(item.type !== JobType.PerDiem ? 12 : 0, new Date(item.startDate)).toISOString() : null,
		endDate: item.endDate ? addHours(item.type !== JobType.PerDiem ? 12 : 0, new Date(item.endDate)).toISOString() : null,
		jobTimezone: item.timezone,
		createdAt: new Date(item.createdAt).toISOString(),
		updatedAt: new Date(item.updatedAt).toISOString(),
		keywords: item.keywords ? item.keywords.join(', ') : null,
		jobCreator: {
			id: item.createdByUser?._id || null,
			firstName: item.createdByUser?.firstName || null,
			lastName: item.createdByUser?.lastName || null,
			phoneNumber: item.createdByUser?.phoneNumber || null,
			email: item.createdByUser?.email || null
		},
		contactPerson: {
			id: item.delegatedToUser?._id || null,
			firstName: item.delegatedToUser?.firstName || null,
			lastName: item.delegatedToUser?.lastName || null,
			phoneNumber: item.delegatedToUser?.phoneNumber || null,
			email: item.delegatedToUser?.email || null
		},
		hospitalId: item.organization?._id || null,
		hospital: {
			id: item.organization?._id,
			facility: {
				businessName: item.organization?.facility?.businessName || item.organization?.name,
				type: item.organization?.facility?.type,
				employees: item.organization?.facility?.employees,
				description: item.organization?.facility?.description,
				url: item.organization?.facility?.url,
				imageURL: item.imageUrl || item.organization?.facility?.imageURL,
				imageThumbURL: item.organization?.facility?.imageThumbURL,
				logoURL: item.organization?.facility?.logoURL,
				logoThumbURL: item.organization?.facility?.logoThumbURL,
				bannerURL: item.organization?.facility?.bannerURL,
				bannerThumbURL: item.organization?.facility?.bannerThumbURL
			},
			address1: item.organization?.address1,
			address2: item.organization?.address2,
			city: item.organization?.city,
			state: item.organization?.state,
			zip: item.organization?.zip
		},
		department:{
			id: item.department?._id?.toString() || '',
			name: item.department?.name || 'None Specified'
		}
	};

	if ((item as any).isNew !== undefined) {
		result.new = (item as any).isNew ? 1 : 0;
	}

	if (item.rate) {
		result.rateType = item.rate.frequency;
		result.rateLabel = item.rate.type;

		if (item.rate.type === 'Amount') {
			result.rate = `$${item.rate.value}`;
		} else if (item.rate.type === 'Range') {
			result.rate = `$${item.rate.from} - $${item.rate.to}`;
		} else {
			result.rate = item.rate.type;
		}
	} else {
		result.rate = '';
		result.rateType = '';
		result.rateLabel = '';
	}

	result.showRate = result.rate ? 1 : 0;

	if (application) {
		result.applicationId = `${item._id.toString()}-${application.user._id.toString()}`;
		result.jobConfirmedId = `${item._id.toString()}-${application.user._id.toString()}`;
		result.status = (application.status === 'ACCEPTED') ? 'UPCOMING' : application.status;
		result.exception = 0;
		result.nurse = application.user;

		if (result.status === JobApplicationStatus.Submitted) {
			result.status = JobApplicationStatus.Pending;
		}

		if (item.type === JobType.PerDiem && application.confirmedAt && !application.approvedAt && !application.cancelledAt && application.endDate < addMinutes((0-endTimeBufferMinutes))) {
			result.exception = 1;
		}

		if (application.startDate) {
			result.isDateSelected = 1;
			result.startDate = application.startDate ? addHours(item.type !== JobType.PerDiem ? 12 : 0, new Date(application.startDate)).toISOString() : null;
		}

		if (application.endDate) {
			result.endDate = application.endDate ? addHours(item.type !== JobType.PerDiem ? 12 : 0, new Date(application.endDate)).toISOString() : null;
		}

		if (result.nurse.profilePicUrl) {
			(result.nurse as any).profilePic = result.nurse.profilePicUrl;
			(result.nurse as any).profilePicThumb = result.nurse.profilePicThumbUrl;
			delete result.nurse.profilePicUrl;
			delete result.nurse.profilePicThumbUrl;
		}

		if (application.supervisorUser) {
			result.contactPerson.id = application.supervisorUser._id || null;
			result.contactPerson.firstName = application.supervisorUser.firstName || null;
			result.contactPerson.lastName = application.supervisorUser.lastName || null;
			result.contactPerson.phoneNumber = application.supervisorUser.phoneNumber || null;
			result.contactPerson.email = application.supervisorUser.email || null;
		}
	}

	result.nurse = result.nurse || { phoneNumber: '' };
	if (!result.nurse.licenseDetails) {
        result.nurse.licenseDetails = {};
    }

    if (!result.nurse.licenseDetails.licenseType) {
        result.nurse.licenseDetails.licenseType = 'RN';
    }

	jobV1setPrivileges(result, userId, userType, application);

	return result;
}

export function jobV1setPrivileges(job: JobV1, userId: string, userType: string, application: JobApplication) {
	if (job.supervisor && job.supervisor.id && (job.supervisor.id == userId)) {
		job.chatEnabled = 1;
		job.canDelegate = 0;
		job.canAssign = 0;
		job.canAprroveTime = 0;
		job.canRate = 1;
	  } else if (job.contactPerson && job.contactPerson.id && (job.contactPerson.id == userId) && (!job.supervisor || job.supervisor.id == 'null' || job.supervisor.id == null)) {
		job.chatEnabled = 1;
		job.canDelegate = 1;
		job.canAssign = 1;
		job.canAprroveTime = 1;
		job.canRate = 1;
	  } else if (job.jobCreator && job.jobCreator.id && (job.jobCreator.id == userId)
		&& (!job.contactPerson || job.contactPerson.id == 'null' || job.contactPerson.id == null) && (!job.supervisor || job.supervisor.id == 'null' || job.supervisor.id == null)) {
		job.chatEnabled = 1;
		job.canDelegate = 1;
		job.canAssign = 1;
		job.canAprroveTime = 1;
		job.canRate = 1;
	  } else {
		job.chatEnabled = 0;
		job.canDelegate = 0;
		job.canAssign = 0;
		job.canAprroveTime = 0;
		job.canRate = 0;
	  }
	
	  // checks for show rate
	  job.showRate = 0;
	
	  if (userId == job.jobCreator.id || userId== job.contactPerson.id) {
		job.showRate = 1;
	  }
	  if (userType == 'HOSPITAL_ADMIN' || userType == 'NURSE') {
		job.showRate = 1;
	  }
	  if (userType == 'NURSE' && job.exception != 1 && (job.status == 'ENROUTE' || job.status == 'UPCOMING' || job.status == 'STARTED')) {
		job.showRate = 0;
	  }
	  if (!job.showRate && userType != 'NURSE') job.rate = '0';
	
	  // checks for timestamp approval access
	  // if (job.jobCreator && job.jobCreator.id == request.auth.credentials.id) {}
	  if (userType == 'HOSPITAL_ADMIN') {
		// admin will always see
		job.canAprroveTime = 1;
	  } else if (userType == 'HOSPITAL_MANAGER') {
		if (job.contactPerson && job.contactPerson.id && (job.contactPerson.id == userId)) {
		  // different department but job delegated to him
		  job.canAprroveTime = 1;
		} else {
		  // different department but job not delegated to him
		  job.canAprroveTime = 0;
		}
	  } else if (userType == 'HOSPITAL_SUPERVISOR') {
		if (job.supervisor && job.supervisor.id && (job.supervisor.id == userId)) {
		  job.canAprroveTime = 1;
		} else {
		  job.canAprroveTime = 0;
		}
	  } else {
		// if not supervisor then dont show
		job.canAprroveTime = 0;
	  }
	
	  if (application && application.approvedAt) {
		job.canAprroveTime = 0;
	  }
}

export function jobToApplicationV1(item: Job): JobApplicationV1 {
	const certifications: string[] = [];
	const additionalCertifications: string[] = [];

	if (item.applications?.[0]?.sharedDocuments) {
		for (const doc of item.applications[0].sharedDocuments) {
			if (doc.startsWith('certifications.')) {
				certifications.push(sharedDocumentToString(doc));
			}

			if (doc.startsWith('additionalCertifications.')) {
				additionalCertifications.push(sharedDocumentToString(doc));
			}
		}
	}

	const result: JobApplicationV1 = {
		id: `${item.sqlId || item._id?.toString()}-${item.applications?.[0]?.user?._id || ''}`,
		job: {
			id: item.sqlId || item._id?.toString(),
			intId: item.intId,
			title: item.title,
			type: item.type,
			description: item.description || '',
			licenseType: item.licenseType,
			durationUnit: item.durationUnit,
			facilityLocationAddress1: item.organization?.address1,
			facilityLocationAddress2: item.organization?.address2,
			facilityLocationState: item.organization?.state,
			facilityLocationCity: item.organization?.city,
			facilityLocationZip: item.organization?.zip,
			workLocationAddress1: item.location?.address1,
			workLocationAddress2: item.location?.address2 || null,
			workLocationCity: item.location?.city,
			workLocationState: item.location?.state,
			workLocationZip: item.location?.zip,
			locationLatitude: item.location?.coordinates?.[1],
			locationLongitude: item.location?.coordinates?.[0],
			location: item.location?.state,
			totalRequirement: item.totalRequirement,
			minimumEducation: item.minimumEducation,
			minimumExperience: item.minimumExperience,
			requirementFulfilled: item.requirementFulfilled,
			speciality: item.specialties ? item.specialties.join(',') : 'None',
			certifications: item?.certification || 'None',
			additionalCertifications: item.additionalCertifications ? item.additionalCertifications.join(',') : 'None',
			shiftLength: item.duration != undefined ? item.duration.toString() : null,
			orientation: item.sendOrientation ? 1 : 0,
			telehealth: item.isTelehealth ? 1 : 0,
			isDateSelected: item.startDate ? 1 : 0,
			isFavorite: (item as any).isFavorite ? 1 : 0,
			startDate: item.startDate ? addHours(item.timezone == undefined ? 12 : 0, new Date(item.startDate)).toISOString() : null,
			endDate: item.endDate ? addHours(item.timezone == undefined ? 12 : 0, new Date(item.endDate)).toISOString() : null,
			createdAt: new Date(item.createdAt).toISOString(),
			updatedAt: new Date(item.updatedAt).toISOString(),
			keywords: item.keywords ? item.keywords.join(', ') : null
		},
		hospital: {
			id: item.organization?._id,
			facility: {
				businessName: item.organization?.facility?.businessName || item.organization?.name,
				type: item.organization?.facility?.type,
				employees: item.organization?.facility?.employees,
				description: item.organization?.facility?.description,
				url: item.organization?.facility?.url,
				imageURL: item.imageUrl || item.organization?.facility?.imageURL,
				imageThumbURL: item.organization?.facility?.imageThumbURL,
				logoURL: item.organization?.facility?.logoURL,
				logoThumbURL: item.organization?.facility?.logoThumbURL,
				bannerURL: item.organization?.facility?.bannerURL,
				bannerThumbURL: item.organization?.facility?.bannerThumbURL
			},
			address1: item.organization?.address1,
			address2: item.organization?.address2,
			city: item.organization?.city,
			state: item.organization?.state,
			zip: item.organization?.zip
		},
		department: {
			id: item.department?._id || '',
			name: item.department?.name || 'None Specified'
		},
		user: {
			id: item.applications?.[0]?.user?._id,
			firstName: item.applications?.[0]?.user?.firstName,
			lastName: item.applications?.[0]?.user?.lastName
		},
		contactPerson: {
			id: item.delegatedToUser?._id || null,
			firstName: item.delegatedToUser?.firstName || null,
			lastName: item.delegatedToUser?.lastName || null,
			phoneNumber: item.delegatedToUser?.phoneNumber || null,
			email: item.delegatedToUser?.email || null
		},
		jobCreator: {
			id: item.createdByUser?._id || null,
			firstName: item.createdByUser?.firstName || null,
			lastName: item.createdByUser?.lastName || null,
			phoneNumber: item.createdByUser?.phoneNumber || null,
			email: item.createdByUser?.email || null
		},
		isMarketplace: item.isMarketplace ? 1 : 0,
		isPRN: item.isIrp ? 1 : 0,
		W9Shared: (item.applications?.[0]?.sharedDocuments?.indexOf('W9') > -1) ? 1 : 0,
		driverLicenseShared: (item.applications?.[0]?.sharedDocuments?.indexOf('driverLicense') > -1) ? 1 : 0,
		resumeShared: (item.applications?.[0]?.sharedDocuments?.indexOf('resume') > -1) ? 1 : 0,
		vehicleInsuranceShared: (item.applications?.[0]?.sharedDocuments?.indexOf('vehicleInsurance') > -1) ? 1 : 0,
		liabilityInsuranceShared: (item.applications?.[0]?.sharedDocuments?.indexOf('liabilityInsurance') > -1) ? 1 : 0,
		certificationsShared: certifications,
		additionalCertificationsShared: additionalCertifications,
		xRayDocumentShared: (item.applications?.[0]?.sharedDocuments?.indexOf('xRayDocument') > -1) ? 1 : 0,
		drugTestDocumentShared: (item.applications?.[0]?.sharedDocuments?.indexOf('drugTestDocument') > -1) ? 1 : 0,
		vaccinationShared: (item.applications?.[0]?.sharedDocuments?.indexOf('vaccination') > -1) ? 1 : 0
	};

	if (item.rate) {
		result.job.rateType = item.rate.frequency;
		result.job.rateLabel = item.rate.type;

		if (item.rate.type === 'Amount') {
			result.job.rate = `$${item.rate.value}`;
		} else if (item.rate.type === 'Range') {
			result.job.rate = `$${item.rate.from} - $${item.rate.to}`;
		} else {
			result.job.rate = item.rate.type;
		}
	} else {
		result.job.rate = '';
		result.job.rateType = '';
		result.job.rateLabel = '';
	}

	return result;
}

