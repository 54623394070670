import { midnight } from "@praos-health/core/utilities/date";
import { Address } from "./address";
import { UserType } from "./user-type";

export type User = {
	_id?: string,
	userType?: UserType,
	firstName?: string,
	lastName?: string,
	email?: string,
	phoneNumber?: string,
	dateOfBirth?: Date | boolean,
	gender?: 'Male' | 'Female' | 'Other',
	languages?: string[],
	address?: Address,
	signupChannel?: string
}

export function prepUser(user: User): User {
	if (user.dateOfBirth instanceof Date) {
		user.dateOfBirth = midnight(new Date(user.dateOfBirth), true);
	}

	return user;
}

export function parseUser(json: any): User {
	if (typeof json.dateOfBirth === 'number') {
		json.dateOfBirth = new Date(json.dateOfBirth);
	}

	return json;
}