export type JobTimesheet = {
	timestamp?: Date,
	notes?: string,
	clockIn?: boolean,
	adjustedTimestamp: Date
}

export function parseJobTimesheet(json: any): JobTimesheet {
	const result: JobTimesheet = json;

	if (json.timestamp) {
		result.timestamp = new Date(json.timestamp);
	}

	if (json.adjustedTimestamp) {
		result.adjustedTimestamp = new Date(json.adjustedTimestamp);
	}

	return result;
}