export type SkillsChecklist = {
	id?: any,
	identifier?: string
	name?: string,
	data?: any,
	fileUrl?: string,
	updatedAt?: Date
}

export function parseSkillsChecklist(json: any): SkillsChecklist {
	if (json.updatedAt) {
		json.updatedAt = new Date(json.updatedAt);
	}

	return json;
}