import { Box } from '@mui/material';
import React from 'react';
import './Board.scss';
import { Routes, Route } from 'react-router-dom'
import { Header, Content, BrandedBanner } from './components';
import ApplyNow from './components/applyNow/ApplyNow';
import Refer from './components/referPage/Refer';

const Board: React.FC<{}> = () => {
    return (
        <Box className='flex-column w-100-p h-100-p'>
            <Header />
            <Box className='flex flex-column w-100-p h-100-p' sx={{paddingTop: '80px'}}>
                <BrandedBanner />
                <Box className='content-filter-wrapper inline-flex w-100-p h-100-p'>
                    <Routes>
                        <Route path='/:companyName/apply-now' element={ <ApplyNow />} />
                        <Route path='/:companyName/refer' element={ <Refer />} />
                        <Route path='*' element={ <Content />} /> 
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default Board;