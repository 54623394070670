import { isObject, toJson } from "@praos-health/core/utilities/object";
import { ProfessionalError } from "./professional-error";

export async function ajax(url: RequestInfo, method: string, auth?: string, body?: any, qs?: any): Promise<any> {
	if (qs) {
		url += '?' + Object.keys(qs).reduce((i, j) => {
			let v = toJson(qs[j]);

			if (isObject(v)) {
				v = JSON.stringify(v);
			}

			if (v !== undefined) {
				i.push(`${j}=${encodeURIComponent(v)}`);
			}

			return i;
		}, []).join('&');
	}

	const headers: any = {};

	if (body && !(body instanceof FormData)) {
		headers['Content-Type'] = 'application/json';
		body = JSON.stringify(toJson(body));
	}

	if (auth) {
		headers.Authorization = auth;
	}

	const response: Response = await fetch(
		url,
		{
			method,
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers,
			redirect: 'manual',
			referrerPolicy: 'no-referrer',
			body
		}
	);

	return await handleResponse(response);			
}

export async function ajaxKey(url: RequestInfo, method: string, xApiKey: string, body?: any, qs?: any, contentType?: string): Promise<any> {
    if (qs) {
        url += '?' + Object.keys(qs).reduce((i, j) => { i.push(`${j}=${encodeURIComponent(toJson(qs[j]))}`); return i; }, []).join('&');
    }

    if (body && !contentType) {
        body = JSON.stringify(toJson(body));
    }

    const response: Response = await fetch(
        url,
        {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': contentType || 'application/json',
                'X-API-key': xApiKey
            },
            redirect: 'manual',
            referrerPolicy: 'no-referrer',
            body
        }
    );

    return await handleResponse(response);			
}


async function handleResponse(response: Response): Promise<any> {
	let result: any;

	if (response.statusText !== 'No Content') {
		if (response.headers.get('content-type') === 'text/plain') {
			result = await response.text();
		} else {
			result = await response.json();
		}
	}

	if (response.ok) {
		return result;
	}

	const location = response.headers.get('location');
	const type = (location || !result?.type) ? 'ProfessionalError' : result.type;
	const err = new ProfessionalError(type, result?.message || response.statusText, response.status, location);

	if (result.stack) {
		err.stack = result.stack;
	}

	throw err;
}