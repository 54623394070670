import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type ActivityWork = {
	id?: string,
	title?: string,
	startDate?: Date,
	endDate?: Date,
	projectDetailsUrl?: string,
	fileUrl?: string,
	downloadUrl?: string
}

export function prepActivityWork(work: ActivityWork): ActivityWork {
	delete work.fileUrl;
	delete work.downloadUrl;

	if (work.startDate) {
		work.startDate = midnight(new Date(work.startDate), true);
	}

	if (work.endDate) {
		work.endDate = midnight(new Date(work.endDate), true);
	}

	return work;
}

export function parseActivityWork(json: any): ActivityWork {
	if (json.startDate) {
		json.startDate = addMinutes(new Date(json.startDate).getTimezoneOffset(), new Date(json.startDate));
	}

	if (json.endDate) {
		json.endDate = addMinutes(new Date(json.endDate).getTimezoneOffset(), new Date(json.endDate));
	}

	return json;
}