import { clone } from "@praos-health/core/utilities/object";
import { ajax } from "./ajax"

export class ExperienceLevelService {
	private _experienceLevelCache: string[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string): Promise<string[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/educationlevels`, 'GET', auth);
		}

		let result: string[];

		if (!this._experienceLevelCache) {
			this._experienceLevelCache = await ajax(`${this.apiUrl}/briefcase/experiencelevels`, 'GET', auth);
		}

		result = this._experienceLevelCache;

		return clone(result);
	}
}