import { clone } from "@praos-health/core/utilities/object";
import { LicenseBody } from "./models";
import { ajax } from "./ajax"

export class LicenseBodyService {
	private _licenseBodyCache: LicenseBody[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, profession?: string): Promise<LicenseBody[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/licensebodies`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: LicenseBody[];

		if (!this._licenseBodyCache) {
			this._licenseBodyCache = await ajax(`${this.apiUrl}/briefcase/licensebodies`, 'GET', auth);
		}

		result = this._licenseBodyCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}
		
		return clone(result);
	}
}