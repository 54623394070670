import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { ChangeEvent, useEffect } from 'react';
import './StartDateFilter.scss';

let firstExecution = true;

const StartDateFilter: React.FC<{}> = () => {
    
  const { filtersData, setFiltersData, updateLocalData } = useFiltersData();
  const [value, setValue] = React.useState<Date | boolean | null>(new Date());

  useEffect(() => {
    if (firstExecution) {
        setValue(filtersData.startDate || new Date())
        firstExecution = false
    }
  }, [filtersData.startDate])

  const handleDateChange = (newValue: Date | boolean | null) => {
    filtersData.startDate = newValue as Date
    setValue(newValue as Date)
    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  };
    
  const handleASAPChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    filtersData.startDate = checked;
    setValue(checked)
    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  }

  return (
    <Box className='flex flex-justify-center flex-column align-items-start filter-date-picker-wrapper'>
      <DesktopDatePicker
        format='MM/dd/yyyy'
        disabled={value === true}
        value={value instanceof Date ? value : undefined }
        minDate={new Date()}
				onChange={handleDateChange}
				slotProps={{
					textField: {
						variant: 'outlined',
            className: 'start-datepicker-input'
					}
				}}
			/>
      <Box className='start-asap-checkbox'>
        <FormControlLabel
          label='Starting ASAP'
          control={
            <Checkbox 
              checked={value === true}
              onChange={handleASAPChange} />}
        />
      </Box>
    </Box>
);
};

export default StartDateFilter;