import React, { useState, useEffect, RefCallback } from 'react';
import { FormControl, Grid, MenuItem, Select, TextField, InputLabel, Button, Typography, InputAdornment, Box } from '@mui/material';
import './ReferForm.scss';
import { useApi, useBrand } from 'contexts';
import { FieldErrors, FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { LoadingButton } from '@mui/lab';
import { Job } from '@praos-health/jobs-client';
import { ListUserResult, UserType } from '@praos-health/users-client';
import { IMaskInput } from 'react-imask';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useUserData } from 'contexts/user/User';
import americaFlag from '../../../../../assets/images/usa.png'
import { isMobile } from 'utils';
import { Brand } from 'contexts/brand/brand.types';

interface ApplicantFormProps {
    job?: Job,
    selectedTab: number,
    handleNextTab: (message?: string) => void,
    handlePreviousTab?: () => void
}
interface CustomTextProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomTextProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="___-___-____"
                definitions={{
                    _: /\d/
                }}
                inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
                onAccept={(value: any) =>
                    onChange({ target: { name: props.name, value } })
                }
            />
        );
    }
);

enum SelectInputTypes {
    Recruiter = 'recruiter'
}

const RecruiterInput: React.FC<{ 
        register: UseFormRegister<FieldValues>, 
        recruiter: string, 
        recruiters: ListUserResult,
        handleSelectChange: (event: any, type: SelectInputTypes) => void}> = ({ register, recruiter, recruiters, handleSelectChange }) => {
    return (
        <>
        <Grid className='explainer-container' item xs={11}>
            <span className='explainer'>If you have been in touch with a recruiter, select below (optional)</span>
        </Grid>
        <Grid className='recruiterGrid' item xs={12}>
            <Box className='gridItem'>
                <FormControl className='formControl'>
                    <InputLabel id="recruiter-label">Recruiter info</InputLabel>
                    <Select
                        data-test="recruiter-select"
                        {...register('recruiter')}
                        className='formElement'
                        inputProps={{ className: 'formInput' }}
                        value={recruiter}
                        onChange={($event) => handleSelectChange($event, SelectInputTypes.Recruiter)}
                        label='Recruiter info'
                        labelId='recruiter-label'>
                        {
                            ([{ _id: '', firstName: 'None', lastName: '', email: null }, ...recruiters?.list])?.map((user, i) => <MenuItem data-test={`item-${i}`} key={user._id} value={user._id}>{user.firstName} {user.lastName} {user.email ? `<${user.email}>` : ''}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Box>
        </Grid> 
        </>
    )
} 

/* eslint-disable no-useless-escape */
const RefereeInfo: React.FC<{
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors<FieldValues>,
    handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleSelectChange: (event: any, type: SelectInputTypes) => void,
    recruiter: string, 
    recruiters: ListUserResult
    phone: string,
    selectedTab: number
}> = ({ register, handlePhoneChange, handleSelectChange, errors, phone, recruiter, recruiters, selectedTab }) => {
    return (
        <>
                <Grid container className='formGrid'>
                    <Grid item xs={10} md={10}>
                        <Grid container>
                            <Grid className='gridControl' item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('firstName', { required: true, minLength: 3 })}
                                        error={!!errors?.firstName}
                                        helperText={errors?.firstName && 'First name is required'}
                                        className='formElement'
                                        InputProps={{ className: 'formInput' }}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        id="firstName"
                                        variant="outlined"
                                        label="First Name" />
                                </Box>
                            </Grid>
                            <Grid className={`gridControl ${!isMobile() ? `right` : ``}`} item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('lastName', { required: true, minLength: 3 })}
                                        error={!!errors?.lastName}
                                        helperText={errors?.lastName && 'Last name is required'}
                                        className='formElement'
                                        InputProps={{ className: 'formInput' }}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        id="lastName"
                                        variant="outlined"
                                        label="Last Name" />
                                </Box>
                            </Grid>
                            <Grid className='gridControl' item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('email', { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        error={!!errors?.email}
                                        helperText={errors?.email && 'A valid email is required'}
                                        className='formElement'
                                        InputProps={{ 
                                            className: 'formInput',
                                            startAdornment: <InputAdornment position="start"><EmailOutlinedIcon/></InputAdornment> 
                                        }}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        id="email"
                                        type="email"
                                        variant="outlined"
                                        label="Your email" />
                                </Box>
                            </Grid>
                            <Grid className={`gridControl ${!isMobile() ? `right` : ``}`} item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('phone', { required: true, minLength: 12 })}
                                        error={!!errors?.phone}
                                        helperText={errors?.phone && 'Phone number is required'}
                                        className='formElement phoneInput'
                                        id="phone"
                                        variant="outlined"
                                        label="Phone Number"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        InputProps={{
                                            className: 'formInput',
                                            inputComponent: TextMaskCustom as any,
                                            startAdornment: <InputAdornment position="start"> <img style={{ marginRight: '25px' }} alt={'USA'} src={americaFlag} /> +1 </InputAdornment>
                                        }} />
                                </Box>
                            </Grid>
                            
                            {
                                selectedTab < 2 &&
                                <>
                                   <RecruiterInput 
                                    recruiter={recruiter}
                                    recruiters={recruiters}
                                    register={register}
                                    handleSelectChange={handleSelectChange} 
                                        />
                                </>
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </>
    );
};

const ReferralInfo: React.FC<{
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors<FieldValues>,
    referralPhone: string,
    handleReferralPhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handlePreviousTab?: () => void,
    brand: Brand,
    errorMessage: string,
    selectedTab: number
}> = ({ register, errors, selectedTab, referralPhone, errorMessage, brand, handleReferralPhoneChange, handlePreviousTab }) => {
    return (
        <>
                <Grid container className='formGrid'>
                    <Grid item xs={10} md={10}>
                        <Grid container>
                            <Grid className='gridControl' item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('referralFirstName', { required: true, minLength: 3 })}
                                        error={!!errors?.referralFirstName}
                                        helperText={errors?.referralFirstName && 'First name is required'}
                                        className='formElement'
                                        InputProps={{ className: 'formInput' }}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        id="referralFirstName"
                                        variant="outlined"
                                        label={`${selectedTab < 2 ? "Referral's " : ''}First Name`}/>
                                </Box>
                            </Grid>
                            <Grid className={`gridControl ${!isMobile() ? `right` : ``}`} item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('referralLastName', { required: true, minLength: 3 })}
                                        error={!!errors?.referralLastName}
                                        helperText={errors?.referralLastName && 'Last name is required'}
                                        className='formElement'
                                        InputProps={{ className: 'formInput' }}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        id="referralLastName"
                                        variant="outlined"
                                        label={`${selectedTab < 2 ? "Referral's " : ''}Last Name`}/>
                                </Box>
                            </Grid>
                            <Grid className='gridControl' item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('referralEmail', { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        error={!!errors?.referralEmail}
                                        helperText={errors?.referralEmail && 'A valid email is required'}
                                        className='formElement'
                                        InputProps={{ className: 'formInput' }}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        id="referralEmail"
                                        type="email"
                                        variant="outlined"
                                        label={`${selectedTab < 2 ? "Referral's " : ''}Email`}/>
                                </Box>
                            </Grid>
                            <Grid className={`gridControl ${!isMobile() ? `right` : ``}`} item xs={12} md={6}>
                                <Box className='gridItem'>
                                    <TextField
                                        {...register('referralPhone', { required: true, minLength: 12 })}
                                        error={!!errors?.referralPhone}
                                        helperText={errors?.referralPhone && 'Phone number is required'}
                                        className='formElement phoneInput'
                                        id="referralPhone"
                                        variant="outlined"
                                        label={`${selectedTab < 2 ? "Referral's " : ''}Phone Number`}
                                        value={referralPhone}
                                        onChange={handleReferralPhoneChange}
                                        InputLabelProps={{ 
                                            required: true
                                        }}
                                        InputProps={{
                                            className: 'formInput',
                                            inputComponent: TextMaskCustom as any,
                                            startAdornment: <InputAdornment position="start"> <img style={{ marginRight: '15px' }} alt={'USA'} src={americaFlag} /> +1 </InputAdornment>
                                        }} />
                                    </Box>
                            </Grid>
                            {
                                selectedTab < 2 &&
                                <Grid className='gridControl review' item xs={12}>
                                    <Typography sx={{ color: brand.color, textAlign: 'center', cursor: 'pointer' }} onClick={handlePreviousTab}>
                                        Review my contact details
                                    </Typography>
                                </Grid>
                            }
                            {
                                errorMessage && selectedTab < 2 &&
                                <Grid item xs={12} className='errorGrid'>
                                    <div className='errorBox'>
                                        <WarningAmberOutlinedIcon />
                                        <span className='message'>{errorMessage}</span>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
    );
};



/* eslint-disable no-useless-escape */
const ReferForm: React.FC<ApplicantFormProps> = ({ job, selectedTab, handleNextTab, handlePreviousTab }) => {
    const { brand } = useBrand();
    const { user } = useUserData();
    const { userService, professionalService } = useApi();
    const [submittingForm, setSubmittingForm] = useState(false);
    const [recruiters, setRecruiters] = useState<ListUserResult>({ count: 0, list: [] });
    const [phone, setPhone] = useState('');
    const [referralPhone, setReferralPhone] = useState('');
    const [recruiter, setRecruiter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { register, handleSubmit, setValue, formState: { errors, isValid } } = useForm({ mode: 'all' });
    console.log('isValid',isValid);
    console.log('errors', errors);

    const onSubmit = (data: any) => {
        if ((errors && Object.values(errors).length)) return;
        if (selectedTab === 0) {
            handleNextTab();
        } else {
            setSubmittingForm(true);
            const {
                firstName, lastName, email, phone, recruiter,
                referralEmail, referralFirstName, referralLastName, referralPhone
            } = data;
            const payload = {
                info: {
                    firstName, lastName, email, phoneNumber: (phone as string).replace(new RegExp('-', 'g'), '')
                },
                referral: {
                    firstName: referralFirstName, lastName: referralLastName,
                    email: referralEmail, phoneNumber: (referralPhone as string).replace(new RegExp('-', 'g'), '')
                },
                recruiterId: recruiter || undefined,
                jobId: job?._id || '',
                organizationId: job?.organization?._id || brand.id || ''
            }

            professionalService.referCandidate(process.env.REACT_APP_X_API_KEY || '', payload)
                .then(() => {
                    setSubmittingForm(false);
                    setErrorMessage('');
                    handleNextTab();
                })
                .catch((e) => {
                    const { message } = e;
                    setSubmittingForm(false);
                    setErrorMessage(message);
                    console.error(e);
                })
        }
    }

    const handleSelectChange = (event: any, type: SelectInputTypes) => {
        setRecruiter(event.target.value);
        setValue(SelectInputTypes.Recruiter, event.target.value, { shouldValidate: true });
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
        setValue('phone', event.target.value, { shouldValidate: true })
    }
    const handleReferralPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferralPhone(event.target.value);
        setValue('referralPhone', event.target.value, { shouldValidate: true })
    }

    const formatPhoneNumber = (phoneNumber: string) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            setValue('firstName', user.firstName)
            setValue('lastName', user.lastName)
            setValue('email', user.email)
            setValue('phone', formatPhoneNumber(user.phoneNumber || ''))
            setPhone(formatPhoneNumber(user.phoneNumber || ''))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        userService.listExternal(process.env.REACT_APP_X_API_KEY || '', {
            organizationId: job?.organization?._id || brand.id || '',
            isDeactivated: false,
            isOrganizationUser: true,
            limit: 100,
            userType: [UserType.HospitalManager, UserType.HospitalSupervisor]
        })
        .then(data => {
          setRecruiters(data);
          if (data?.count && user?.affiliations?.length && data.list.filter(orgUser => user.affiliations && orgUser._id?.toString() === user.affiliations[0]?.recruiter?._id?.toString()).length) {
            setRecruiter(user.affiliations[0]?.recruiter?._id?.toString() || '')
            setValue('recruiter', user.affiliations[0]?.recruiter?._id?.toString() || '')
          }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userService, job?.organization?._id, brand?.id]);

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log(errors))}>
            {   
                selectedTab === 2 &&
                <Typography sx={{textAlign: 'center', marginBottom: '20px', fontWeight: 'bold', fontSize: '1.5rem', color: '#3c424b'}}>Your Referral's Information</Typography>
            }
            {
                selectedTab === 0 ? 
                    <RefereeInfo 
                        register={register}
                        errors={errors}
                        handlePhoneChange={handlePhoneChange}
                        handleSelectChange={handleSelectChange}
                        recruiter={recruiter} 
                        recruiters={recruiters}
                        phone={phone}
                        selectedTab={selectedTab} /> : 
                selectedTab === 2 ? 
                <ReferralInfo 
                    register={register}
                    errors={errors}
                    referralPhone={referralPhone}
                    handleReferralPhoneChange={handleReferralPhoneChange}
                    handlePreviousTab={handlePreviousTab}
                    brand={brand}
                    errorMessage={errorMessage}
                    selectedTab={selectedTab} /> : 
                    undefined
            }
            {   
                selectedTab === 2 &&
                <Typography sx={{textAlign: 'center', margin: '20px', fontWeight: 'bold', fontSize: '1.5rem', color: '#3c424b'}}>Your Information</Typography>
            }
            {
                selectedTab === 1 ? 
                    <ReferralInfo 
                        register={register}
                        errors={errors}
                        referralPhone={referralPhone}
                        handleReferralPhoneChange={handleReferralPhoneChange}
                        handlePreviousTab={handlePreviousTab}
                        brand={brand}
                        errorMessage={errorMessage}
                        selectedTab={selectedTab} /> : 
                selectedTab === 2 ? 
                <RefereeInfo 
                    register={register}
                    errors={errors}
                    handlePhoneChange={handlePhoneChange}
                    handleSelectChange={handleSelectChange}
                    recruiter={recruiter} 
                    recruiters={recruiters}
                    phone={phone}
                    selectedTab={selectedTab} /> : 
                    undefined
            }

            {
                selectedTab === 2 && 
                    <Grid container className='formGrid' sx={{ marginBottom: '40px' }}>
                        <Grid item xs={10} md={10}>
                            <Grid container>
                            <RecruiterInput 
                                        recruiter={recruiter}
                                        recruiters={recruiters}
                                        register={register}
                                        handleSelectChange={handleSelectChange} />
                                {
                                    errorMessage && selectedTab === 2 &&
                                    <Grid item xs={12} className='errorGrid'>
                                        <div className='errorBox'>
                                            <WarningAmberOutlinedIcon />
                                            <span className='message'>{errorMessage}</span>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
            }

            <Grid
                className='referContainer'
                item xs={12}>
                {
                    selectedTab === 0 ?
                        (
                            <Button
                                type='submit'
                                disabled={!isValid}
                                sx={{ bgColor: brand.color }}
                                className='applyBtn'
                                variant="contained">
                                CONTINUE
                            </Button>
                        ) :
                        (
                            <LoadingButton
                                type='submit'
                                disabled={!isValid}
                                sx={{ bgColor: brand.color }}
                                loading={submittingForm}
                                className='applyBtn'
                                variant="contained">
                                { selectedTab === 2 ? 'SUBMIT' : 'CONTINUE' }
                            </LoadingButton>
                        )
                }
            </Grid>
        </form>
    );
};

export default ReferForm;