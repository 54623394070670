export enum UserType {
	Admin = 'ADMIN',
	AdminApi = 'ADMIN_API',
	SuperAdmin = 'SUPER_ADMIN',
	Nurse = 'NURSE',
	HospitalAdmin = 'HOSPITAL_ADMIN',
	HospitalManager = 'HOSPITAL_MANAGER',
	HospitalSupervisor = 'HOSPITAL_SUPERVISOR',
	OrganizationClient = 'ORGANIZATION_CLIENT',
	HospitalApi = 'HOSPITAL_API'
}

export const ALL_USER_TYPES = [
	UserType.Admin,
	UserType.AdminApi,
	UserType.HospitalAdmin,
	UserType.HospitalApi,
	UserType.HospitalManager,
	UserType.HospitalSupervisor,
	UserType.OrganizationClient,
	UserType.Nurse,
	UserType.SuperAdmin
];

export const ADMIN_USER_TYPES = [
	UserType.SuperAdmin,
	UserType.Admin,
	UserType.AdminApi
];

export const ORGANIZATION_USER_TYPES = [
	UserType.HospitalAdmin,
	UserType.HospitalApi,
	UserType.HospitalManager,
	UserType.HospitalSupervisor
];

export const CLIENT_USER_TYPES = [
	UserType.OrganizationClient
];

export function userTypeToRole(type: UserType): string {
	switch (type) {
		case UserType.Admin:
			return 'Admin';
		case UserType.AdminApi:
			return 'Admin API';
		case UserType.SuperAdmin:
			return 'Super-Admin';
		case UserType.Nurse:
			return 'Nurse';
		case UserType.HospitalAdmin:
			return 'Co-Admin';
		case UserType.HospitalManager:
			return 'Department Manager';
		case UserType.HospitalSupervisor:
			return 'Department User';
		case UserType.OrganizationClient:
			return 'Client';
		case UserType.HospitalApi:
			return 'API User';
		default:
			return 'Unknown';
	}
}

export function isAdminUser(type: UserType): boolean {
	return ADMIN_USER_TYPES.indexOf(type) > -1;
}

export function isAdminApiUser(type: UserType): boolean {
	return type == UserType.AdminApi;
}

export function isProfessionalUser(type: UserType): boolean {
	return type == UserType.Nurse;
}

export function isHospitalUser(type: UserType): boolean {
	return ORGANIZATION_USER_TYPES.indexOf(type) > -1;
}

export function isHospitalAPIUser(type: UserType): boolean {
	return type == UserType.HospitalApi;
}

export function isClientUser(type: UserType): boolean {
	return CLIENT_USER_TYPES.indexOf(type) > -1;
}