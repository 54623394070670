import { clone } from "@praos-health/core/utilities/object";
import { Profession } from "./models";
import { ajax, ajaxKey } from "./ajax"

export class ProfessionService {
	private _professionCache: Profession[];
	private _externalProfessionCache: Profession[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string): Promise<Profession[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/professions`, 'GET', auth);
		}

		let result: Profession[];

		if (!this._professionCache) {
			this._professionCache = await ajax(`${this.apiUrl}/briefcase/professions`, 'GET', auth);
		}

		result = this._professionCache;

		return clone(result);
	}

	async listExternal(xApiKey: string): Promise<Profession[]> {
		if (!this.cacheEnabled) {
			return await ajaxKey(`${this.apiUrl}/briefcase/professions/external`, 'GET', xApiKey);
		}

		let result: Profession[];

		if (!this._externalProfessionCache) {
			this._externalProfessionCache = await ajaxKey(`${this.apiUrl}/briefcase/professions/external`, 'GET', xApiKey);
		}

		result = this._externalProfessionCache;

		return clone(result);
	}
}
