import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState, useRef } from 'react';
import { LocationFilter, JobTypeFilter, RateFilter, StartDateFilter, SpecialtyFilter, LicenseFilter, OrganizationFilter } from '../filters';
import './NavBar.scss';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { HighlightOff } from '@mui/icons-material';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useFiltersData } from 'contexts/filter/Filter';
import { useBrand } from 'contexts';
import { useJobsCounter } from 'contexts/jobs-counter/JobsCounter';
import { isMobile } from 'utils';
import { DEFAULT_BRAND_NAME } from 'configs/brand/brand';
import { ListJobsResult, RateFrequency } from '@praos-health/jobs-client';

interface FilterInformation {
    filterComponent?: JSX.Element,
    icon?: JSX.Element,
    labelName?: string,
    filterFieldName: string,
    isValueSelected: Function,
    resetFunction: Function
}

interface NavBarProps {
  setJobs: (jobs: ListJobsResult) => void;
  setLoadingJobs: (value: boolean) => void;
  setSkip: (skip: number) => void;
}

const NavBar: React.FC<NavBarProps> = ({setJobs, setLoadingJobs, setSkip}) => {
    
    const { brand } = useBrand();
    const { filtersData, setFiltersData, updateLocalData, resetFiltersData, toggleFilters } = useFiltersData();
    const windowHeight = useRef(window.innerHeight);
    const [ expanded, setExpanded ] = useState<string>('');
    const [ filtersOpened, setFiltersOpened ] = useState<boolean>(false);
    const [ filtersConfig, setFiltersConfig ] = useState<FilterInformation[]>([]);
    const { jobsCounter, setJobsCounter } = useJobsCounter();
    const [rateFilterType, setRateFilterType] = useState(filtersData.rateFrequency && filtersData.rate ? filtersData.rateFrequency : RateFrequency.Weekly);

    const handleRateFilterTypeChange = (newRateFilterType: RateFrequency) => {
      setRateFilterType(newRateFilterType as RateFrequency);
    };

    useEffect(() => {
        const resetFilters = (event: Event): void => {
            event.stopPropagation();
            setLoadingJobs(true);
            setJobs({ count: 0, list: [] });
            setSkip(0);
            setFiltersData(Object.assign({}, filtersData));
            updateLocalData()
            setExpanded('')
        }

        let filterConfigList = [{
          filterComponent: <LocationFilter></LocationFilter>,
          icon: <PlaceOutlinedIcon color={!!filtersData.location ? 'primary' : 'inherit'}></PlaceOutlinedIcon>,
          labelName: 'Location',
          filterFieldName: 'location',
          isValueSelected: () => !!filtersData.location,
          resetFunction: (event:  Event) => {
              filtersData.location = undefined;
              resetFilters(event);
          }
      },
      {
          filterComponent: <JobTypeFilter></JobTypeFilter>,
          icon: <WorkOutlineOutlinedIcon color={!!filtersData.jobType ? 'primary' : 'inherit'}></WorkOutlineOutlinedIcon>,
          labelName: 'Job Type',
          filterFieldName: 'jobType',
          isValueSelected: () => !!filtersData.jobType,
          resetFunction: (event:  Event) => {
              filtersData.jobType = undefined;
              resetFilters(event);
          }
      },
      {
          filterComponent: <LicenseFilter></LicenseFilter>,
          icon: <WorkspacePremiumOutlinedIcon color={!!filtersData.license ? 'primary' : 'inherit'}></WorkspacePremiumOutlinedIcon>,
          labelName: 'Profession / License',
          filterFieldName: 'license',
          isValueSelected: () => !!filtersData.license,
          resetFunction: (event:  Event) => {
              filtersData.license = undefined;
              resetFilters(event);
          }
      },
      {
          filterComponent: <SpecialtyFilter></SpecialtyFilter>,
          icon: <VerifiedOutlinedIcon color={!!filtersData.specialty ? 'primary' : 'inherit'}></VerifiedOutlinedIcon>,
          labelName: 'Specialty',
          filterFieldName: 'specialty',
          isValueSelected: () => !!filtersData.specialty,
          resetFunction: (event:  Event) => {
              filtersData.specialty = undefined;
              resetFilters(event);
          }
      },
      {
          filterComponent: <StartDateFilter></StartDateFilter>,
          icon: <CalendarMonthOutlinedIcon color={!!filtersData.startDate ? 'primary' : 'inherit'}></CalendarMonthOutlinedIcon>,
          labelName: 'Start Date',
          filterFieldName: 'startDate',
          isValueSelected: () => !!filtersData.startDate,
          resetFunction: (event:  Event) => {
              filtersData.startDate = undefined;
              resetFilters(event);
          }
      },
      {
          filterComponent: <RateFilter onRateFilterTypeChange={handleRateFilterTypeChange} rateType={rateFilterType}></RateFilter>,
          icon: <PaymentsOutlinedIcon color={!!filtersData.rate ? 'primary' : 'inherit'}></PaymentsOutlinedIcon>,
          labelName: `Rate`,
          filterFieldName: 'rate',
          isValueSelected: () => !!filtersData.rate,
          resetFunction: (event: Event) => {
              filtersData.rate = undefined;
              resetFilters(event);
          }
      }]

      if(brand.name === DEFAULT_BRAND_NAME){
          
        filterConfigList.push({
          filterComponent: <OrganizationFilter></OrganizationFilter>,
          icon: <CorporateFareIcon color={!!filtersData.organization ? 'primary' : 'inherit'}></CorporateFareIcon>,
          labelName: 'Organization',
          filterFieldName: 'organization',
          isValueSelected: () => !!filtersData.organization,
          resetFunction: (event:  Event) => {
              filtersData.organization = undefined;
              resetFilters(event);
          }
      }
        )

    }
    
        setFiltersConfig(filterConfigList)

    }, [filtersData, brand, setFiltersData, updateLocalData, setJobs, setLoadingJobs, setSkip, rateFilterType])

    useEffect(() => {
      jobsCounter.filtersApplied = filtersConfig.filter(filterConfig => filterConfig.isValueSelected())?.length || 0;
      setJobsCounter(jobsCounter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersConfig]);

    function toggleOpenAccordion(filterConfig: FilterInformation): void {
        if (expanded === filterConfig.filterFieldName) {
            setExpanded('')
        } else {
            setExpanded(filterConfig.filterFieldName)
        }
    }

    function clearAllFilters(): void {
        setLoadingJobs(true);
        setJobs({ count: 0, list: [] });
        setSkip(0);
        resetFiltersData()
        setExpanded('')
    }

    useEffect(() => {
      if (isMobile()) {
          toggleFilters ? openFilterBox() : closeFilterBox()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleFilters])

    function openFilterBox(): void {
      const filterBox = document.getElementById('filters-box');
      if (filterBox) {
          filterBox.style.transform = 'translateX(0)';
          setFiltersOpened(true)
          // When the modal is shown, we want a fixed body
          document.body.style.position = 'fixed';
          document.body.style.top = `-${window.scrollY}px`;
      }
    }

    function closeFilterBox(): void {
        const filterBox = document.getElementById('filters-box');
        if (filterBox) {
            filterBox.style.transform = 'translateX(-100%)';
            setFiltersOpened(false)
            // When the modal is hidden, we want to remain at the top of the scroll position
            document.body.style.position = '';
            document.body.style.top = '';
        }
    }
        
    function displayFilterBox(): JSX.Element | undefined {
      let filtersSelected = filtersConfig
        .filter(filterConfig => filterConfig.isValueSelected())
        .map(filterConfig => filterConfig.labelName);

      const showFilterSummary =  filtersSelected.length || filtersOpened;
      return isMobile() ?
        <>
          <Box id='filters-box' className={`w-100-p filters-wrapper`} sx={{position: 'absolute', top: '50px', zIndex: 9, height: `${windowHeight.current-300}px`, backgroundColor: '#F8F8F8', transform: 'translateX(-100%)', overflow: 'scroll'}}>
              { getFiltersComponent() }
          </Box>
          { showFilterSummary && displayFilters() }
        </> :
        <Box id='filters-box' sx={{position: 'inherit', top: '0px', zIndex: 9, height: '100%', backgroundColor: '#F8F8F8'}}>
          { filtersSelected?.length > 0 && displayFilters() }
          { getFiltersComponent() }
        </Box>
    }

    function getFiltersComponent(): JSX.Element[] {
        return filtersConfig.map(filterConfig => 
            <Accordion expanded={expanded === filterConfig.filterFieldName} onChange={() => toggleOpenAccordion(filterConfig)} key={filterConfig.labelName} className={`non-rounded-accordion filter-accordion ${brand.name === DEFAULT_BRAND_NAME && 'organization-filter'}`}>
              <AccordionSummary className='jobRate-filter large-accordion-summary' expandIcon={<ExpandMoreIcon />}>
                <Box className='flex w-100-p p-l-10-px flex-space-between'>
                  <Box className='flex gap-15-px'>
                    {filterConfig.icon}
                    <Typography fontWeight={filterConfig.isValueSelected() ? 600 : 500} color={filterConfig.isValueSelected() ? 'primary' : 'inherit'}>{filterConfig.labelName}</Typography>
                  </Box>
                  <Box className='flex'>
                    { filterConfig.isValueSelected() && <HighlightOff onClick={(event) => filterConfig.resetFunction(event)} color='primary' className='clear-filter-icon' /> }
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails className='accordion-filter-body'>
                {filterConfig.filterComponent}
              </AccordionDetails>
            </Accordion>
          )
    }

    function displayFilters(): JSX.Element {
        let filtersSelected = filtersConfig
            .filter(filterConfig => filterConfig.isValueSelected())
            .map(filterConfig => filterConfig.labelName);

        return <Box className='filter-counter flex flex-space-between selected-filter-wrapper gap-15-px' sx={{ bgcolor: brand.color, paddingLeft: '15px', paddingTop: '10px', paddingBottom: '10px', zIndex: 15}}>
          <Box className='flex flex-column p-l-10-px '>
            <Box className='flex'>
              <Box className='flex hide-on-desktop' sx={{paddingTop: '10px'}}>
                <FilterAltOutlinedIcon sx={{width: 25, height: 25}}></FilterAltOutlinedIcon>
              </Box>
              { filtersOpened && <Typography fontSize='18px' sx={{ paddingTop: isMobile() ? '10px' : 0, paddingLeft: isMobile() ? '10px' : 0}}>Select preferences...</Typography> }
              { !filtersOpened && <Typography fontSize={`${isMobile() ? '20px': '22px'}`} className="filter-count-number" fontWeight='bold' sx={{ paddingTop: isMobile() ? '8px' : 0, paddingLeft: isMobile() ? '5px' : 0}}>{filtersSelected.length}</Typography> }
              { !filtersOpened && <Typography fontSize={`${isMobile() ? '16px': '22px'}`} sx={{paddingTop: isMobile() ? '13px' : 0, marginLeft: isMobile() ? '-5px' : 0}}>&nbsp;&nbsp;Filter{filtersSelected.length === 1 ? '' : 's'} Applied</Typography> }
              { !filtersOpened && <Typography className='hide-on-desktop' onClick={openFilterBox} sx={{opacity: '0.8', fontSize: '13px', paddingTop: '15px', paddingLeft: '20px'}}>Review filters {'>>'}</Typography>}
            </Box>
            <Box className='flex p-l-25-px hide-on-mobile'>
              <Typography fontSize='14px' >{filtersSelected.join(', ')}</Typography>
            </Box>
          </Box>
          {   !filtersOpened && filtersSelected.length > 0 && <Box className='clear-all-filters-icon-wrapper' onClick={clearAllFilters}>
              <HighlightOff fontSize={`${isMobile() ? 'medium' : 'large'}`}/>
            </Box>
          }
          {
            filtersOpened &&  <ChevronLeftIcon onClick={closeFilterBox} sx={{position: 'relative', top: '7px', right: '10px'}} fontSize='large'></ChevronLeftIcon>
          }
        </Box>
    }

    return (
      <Box className={`flex navbar-wrapper ${isMobile() ? '' : 'desktop-wrapper flex-basis-25'}`}>
        <Box className='navbar-body flex flex-column'>
            <Box className='flex p-l-20-px align-items-center total-job-counter hide-on-mobile' sx={{height: 71, minHeight: 71}}>
                <WorkOutlineIcon />&nbsp;
                <Typography className='total-jobs-number'>{ jobsCounter.total }</Typography>&nbsp;
                <Typography className='total-jobs-result' color={'#929292'}>Available { jobsCounter.total !== 1 ? 'Jobs' : 'Job'}</Typography>
            </Box>
            { filtersOpened && <Box onClick={closeFilterBox} sx={{zIndex: 8, width: '100%', height: '1000px', position: 'absolute', backgroundColor: '#000000', opacity: '0.4'}}></Box>}
            { displayFilterBox() }
        </Box>
      </Box>
    );
};

export default NavBar;