import { AnyObject } from "@praos-health/core/utilities/object";
import { ajax } from "./ajax";
import { FileError } from "./errors";
import { FilePermissions, FileThumbnail, FileType, Organization, UploadedFile, User } from "./models";

export type TemporaryUploadOptions = TemporaryUploadRequestOptions & { file: File | string };
export type UploadOptions = UploadRequestOptions & { file: File | string };

export type UploadRequestOptions = PermanentUploadRequestOptions | TemporaryUploadRequestOptions;

export type ListOrderBy = 'fileName' | '!fileName';

export interface DownloadRequest {
	auth?: string,
	fileId: string,
	timestamp: string,
	historyFileName?: string
	url?: string,
	openWindow?: boolean
}

export interface ListRequest {
	organizationId?: string,
	userId?: string,
	isDeleted?: boolean,
	orderBy?: ListOrderBy,
	limit?: number,
	skip?: number	
}

export interface ListResponse<T> {
	list: T[],
	count: number
}

export type FileTypeListOrderBy = 'name' | '!name';

export interface FileTypeListRequest {
	tags?: string[],
	organizationId?: string,
	isEnabled?: boolean,
	orderBy?: FileTypeListOrderBy,
	limit?: number,
	skip?: number
}

export interface PermanentUploadRequestOptions {
	fileId?: string,
	fileType: string,
	fileName: string,
	userId?: string,
	organizationId?: string,
	isPermanent: true,
	permissions: FilePermissions,
	tags?: { [key: string]: string }
}

export interface TemporaryUploadRequestOptions {
	fileId?: string,
	fileName: string
}

export interface UploadRequest {
	fileId: string,
	fileKey: string,
	url: string,
	fields: { [field: string]: string },
	downloadUrl: string
}

export interface UploadResponse {
	fileId: string,
	fileKey: string,
	fileName: string,
	downloadUrl: string
}

export interface UpdateOptions {
	fileType: string,
	fileName: string,
	fileIdOrKey?: string,
	userId?: string,
	organizationId?: string,
	permissions: FilePermissions,
	data?: AnyObject,
	tags?: { [key: string]: string }
}

export interface UpsertResponse extends UploadedFile {
	url?: string
}

export interface GetRequestOptions {
	token: string,
	fileName: string,
	professionalId: string
}

export interface FileUpdated {
	fileId: string,
	fileKey: string,
	fileType: string,
	userId?: string,
	organizationId?: string,
	url: string
	permissions: FilePermissions,
	data?: AnyObject,
	deletedAt?: Date,
	thumbnails?: { [size: string]: FileThumbnail }
}

export class FileService {
	constructor(protected apiUrl: string) {
	}

	async archive(auth: string, userId: string, organizationId?: string): Promise<UpsertResponse> {
		const url = new URL([`${this.apiUrl}/files/archive`, userId].join('/'));

		if (organizationId) {
			url.searchParams.append('organizationId', organizationId);
		}

		return await ajax(
			url.toString(),
			'DELETE',
			auth
		);
	}

	async delete(auth: string, fileId: string, permanent?: boolean, userId?: string, fileType?: string): Promise<UpsertResponse> {
		const url = new URL([`${this.apiUrl}/files`, fileId].join('/'));

		if (permanent) {
			url.searchParams.append('permanent', 'true');
		}

		if (userId) {
			url.searchParams.append('userId', userId);
		}

		if (fileType) {
			url.searchParams.append('fileType', fileType);
		}

		return await ajax(
			url.toString(),
			'DELETE',
			auth
		);
	}

	async download({ auth, fileId, timestamp, historyFileName, url, openWindow }: DownloadRequest): Promise<string> {
		let result: string;

		if (url) {
			result = url;
		} else {
			const url = new URL(`${this.apiUrl}/files/download/${[fileId, timestamp, historyFileName].filter(Boolean).join('/')}`);

			url.searchParams.append('auth', auth);

			result = url.toString();
		}

		if (result && openWindow) {
			window.open(result);
		}

		return result;
	}

	async fileTypeGet(auth: string, id: string): Promise<FileType> {
		return await ajax(`${this.apiUrl}/files/types/${id}`, 'GET', auth);
	}

	async fileTypeList(auth: string, request: FileTypeListRequest): Promise<ListResponse<FileType>> {
		const url = new URL(`${this.apiUrl}/files/types`);

		if (request.organizationId) {
			url.searchParams.append('organizationId', request.organizationId);
		}

		if (request.tags?.length) {
			for (const tag of request.tags) {
				url.searchParams.append('tags', tag);				
			}
		}

		if (request.isEnabled !== undefined) {
			url.searchParams.append('isEnabled', request.isEnabled.toString());
		}

		if (request.orderBy) {
			url.searchParams.append('orderBy', request.orderBy);
		}

		if (request.limit) {
			url.searchParams.append('limit', request.limit.toString());
		}

		if (request.skip) {
			url.searchParams.append('skip', request.skip.toString());
		}

		return await ajax(url.toString(), 'GET', auth);
	}

	async get(auth: string, id: string): Promise<UploadedFile> {
		return await ajax(`${this.apiUrl}/files/${id}`, 'GET', auth);
	}

	async list(auth: string, request: ListRequest): Promise<ListResponse<UploadedFile>> {
		const url = new URL(`${this.apiUrl}/files`);

		if (request.organizationId) {
			url.searchParams.append('organizationId', request.organizationId);
		}

		if (request.userId) {
			url.searchParams.append('userId', request.userId);
		}

		if (request.isDeleted !== undefined) {
			url.searchParams.append('isDeleted', request.isDeleted.toString());
		}

		if (request.orderBy) {
			url.searchParams.append('orderBy', request.orderBy);
		}

		if (request.limit) {
			url.searchParams.append('limit', request.limit.toString());
		}

		if (request.skip) {
			url.searchParams.append('skip', request.skip.toString());
		}

		return await ajax(url.toString(), 'GET', auth);
	}

	async performUpload(request: UploadRequest, fileName: string, file: File | string): Promise<UploadResponse> {
		const formData = new FormData();
	
		for (const key in request.fields) {
			if (request.fields.hasOwnProperty(key)) {
				formData.append(key, request.fields[key]);
			}
		}

		if (typeof file === 'string') {
			const splitDataURI = file.split(',');
			const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
			const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
			const ia = new Uint8Array(byteString.length);

			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			formData.append('file', new Blob([ia], { type: mimeString }), fileName);
		} else {
			formData.append('file', file as any);
		}

		const result = await ajax(request.url, 'POST', undefined, formData);

		if (result) {
			throw new FileError("UploadError", result);
		}

		return {
			fileId: request.fileId,
			fileKey: request.fileKey,
			fileName,
			downloadUrl: request.downloadUrl
		};
	}

	async requestUpload(options: TemporaryUploadRequestOptions): Promise<UploadRequest>;
	async requestUpload(auth: string, { fileId, ...options }: UploadRequestOptions): Promise<UploadRequest>;
	async requestUpload(auth: string | TemporaryUploadRequestOptions, options?: UploadRequestOptions): Promise<UploadRequest> {
		if (typeof auth === 'object') {
			options = auth;
			auth = undefined as string;
		}

		const { fileId, ...ajaxOptions } = options;

		return await ajax(
			[this.apiUrl, 'files', 'requestupload', fileId].filter(Boolean).join('/'),
			fileId ? 'PUT': 'POST',
			auth,
			ajaxOptions
		);
	}

	async upload(options: TemporaryUploadOptions): Promise<UploadResponse>;
	async upload(auth: string, options: UploadOptions): Promise<UploadResponse>;
	async upload(auth: string | TemporaryUploadOptions, options?: UploadOptions): Promise<UploadResponse> {
		if (typeof auth === 'object') {
			options = auth;
			auth = undefined as string;
		}

		if (typeof options.file !== 'string' && typeof window !== 'undefined') {
			if (options.file.name) {
				const lastDot = options.file.name.lastIndexOf('.');

				if (lastDot > -1) {
					options.fileName += options.file.name.substring(lastDot);
				}
			}

			try {
				// Check for google docs upload
				await options.file.slice(0, 1).arrayBuffer();
			} catch (error) {
				throw new FileError('FileError', 'Trouble uploading docs? Email them to support@praoshealth.com or via the contact form in the Help button.');
			}
		}

		const { file, ...requestOptions } = options;
		
		const request = await this.requestUpload(auth, requestOptions);

		return await this.performUpload(request, options.fileName, file);
	}

	async upsert(auth: string, { fileIdOrKey, ...options }: UpdateOptions): Promise<UpsertResponse> {
		return await ajax(
			[`${this.apiUrl}/files`, fileIdOrKey].filter(Boolean).join('/'),
			'PUT',
			auth,
			options
		);
	}	
}