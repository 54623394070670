import { ajax, ajaxKey } from './ajax';
import { State } from './models/state';

export class StateService {
    constructor(protected apiUrl: string) {}

    async list(auth: string, statePartialName?: string, allStates?: boolean): Promise<State[]> {
        if ((!statePartialName || statePartialName.length < 2) && !allStates)  {
            return new Promise<State[]>((resolve) => resolve([]));
        }

        return await ajax(`${this.apiUrl}/briefcase/states`, 'GET', auth, undefined, {
            statePartialName, allStates
        });
    }
}
