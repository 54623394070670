import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type Certification = {
	id?: string,
	name?: string,
	certifyingBody?: string,
	expirationDate?: Date,
	fileUrl?: string,
	downloadUrl?: string,
	digitalBadgeUrl?: string,
	courseWorkFileUrl?: string,
	courseWorkDownloadUrl?: string,
	uploadedAt?: Date
}

export type CertificationHistory = {
	name?: string,
	fileUrl?: string,
	uploadedAt?: Date,
	createdAt?: Date
}

export function prepCertification(cert: Certification): Certification {
	delete cert.fileUrl;
	delete cert.downloadUrl;
	delete cert.courseWorkFileUrl;
	delete cert.courseWorkDownloadUrl;
	delete cert.uploadedAt;

	if (cert.expirationDate) {
		cert.expirationDate = midnight(new Date(cert.expirationDate), true);
	}

	return cert;
}

export function parseCertification(json: any): Certification {
	if (json.expirationDate) {
		json.expirationDate = addMinutes(new Date(json.expirationDate).getTimezoneOffset(), new Date(json.expirationDate));
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}

export function parseCertificationHistory(json: any): CertificationHistory {
	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	return json;
}