export type Reference = {
	id?: string,
	title?: string,
	organization?: string,
	lastName?: string,
	firstName?: string,
	phoneNumber?: string,
	email?: string
}

export function prepReference(item: Reference): Reference {
	if (item.phoneNumber) {
		item.phoneNumber = item.phoneNumber.replace(/\D/g,'');
	}

	return item;
}