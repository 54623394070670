import { Department, parseDepartment } from "./models";
import { ajax } from "./ajax"

export type ListDepartmentsOptions = {
	organizationId?: string,
	limit?: number,
	skip?: number
}

export type ListDepartmentResult = {
	list: Department[],
	count: number
}

export class DepartmentService {
	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, options?: ListDepartmentsOptions): Promise<ListDepartmentResult> {
		const result = await ajax(`${this.apiUrl}/departments`, 'GET', auth, null, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseDepartment(result.list[i]);
		}

		return result;
	}

	async get(auth: string, id: string): Promise<Department> {
		let result = await ajax(`${this.apiUrl}/departments/${id}`, 'GET', auth);

		if (result) {
			result = parseDepartment(result);
		}

		return result;
	}

	async add(auth: string, department: Department): Promise<Department> {
		const result = await ajax(`${this.apiUrl}/departments`, 'POST', auth, department);

		return parseDepartment(result);
	}

	async update(auth: string, id: string, department: Department): Promise<Department> {
		const result = await ajax(`${this.apiUrl}/departments/${id}`, 'PUT', auth, department);

		return parseDepartment(result);
	}

	async delete(auth: string, id: string): Promise<void> {
		await ajax(`${this.apiUrl}/departments/${id}`, 'DELETE', auth);
	}
}