import { parseUser, User } from "./user";

export type Organization = {
	_id?: string,
	intId?: number,
	name?: string,
	address1?: string,
	address2?: string,
	city?: string,
	state?: string,
	zip?: string,
	createdAt?: string,
	facility?: {
		type?: string,
		logoURL?: string,
		employees?: string
	},
	suspendedAt?: Date,
	suspendedBy?: User
	approvedAt?: Date,
	approvedBy?: User,
	rejectedAt?: Date,
	rejectedBy?: User,
	settings?: {
		autoRePostJobs?: boolean
	}
}

export function parseOrganization(json: any): Organization {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	if (json.suspendedAt) {
		json.suspendedAt = new Date(json.suspendedAt);
	}

	if (json.suspendedBy) {
		json.suspendedBy = parseUser(json.suspendedBy);
	}

	if (json.approvedAt) {
		json.approvedAt = new Date(json.approvedAt);
	}

	if (json.approvedBy) {
		json.approvedBy = parseUser(json.approvedBy);
	}

	if (json.rejectedAt) {
		json.rejectedAt = new Date(json.rejectedAt);
	}

	if (json.rejectedBy) {
		json.rejectedBy = parseUser(json.rejectedBy);
	}

	return json;
}