import { DefaultFaculty } from "./defaultFaculty";
import { ReferralsSettings } from "./referrals";
import { Resource } from "./resource";
import { ServerSettings } from "./server";
import { SessionSettings } from "./session";
import { Versions } from "./versions";

export type Settings = {
	versions?: Versions,
	session?: SessionSettings,
	resources?: Resource[],
	referrals?: ReferralsSettings,
	defaultFacility?: DefaultFaculty,
	server?:  ServerSettings

}

export function parseSettings(json: any): Settings {
	return json;
}