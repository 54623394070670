import { constantsData } from 'configs'
import { useApi } from 'contexts'
import { createContext, useContext, useEffect, useState } from 'react'

import { InitialValues, ConstantProviderProps, Constants } from './constant.types'

const initialValues: InitialValues = {
  constants: constantsData,
  setConstants: () => {}
}

const ConstantContext = createContext(initialValues)

const ConstantProvider = ({ children }: ConstantProviderProps) => {
  const { licenseTypeService, specialtyService, jobService } = useApi();
  const [constants, setConstants] = useState<Constants>(initialValues.constants)

  useEffect(() => {
    licenseTypeService.list(process.env.REACT_APP_X_API_KEY || '')
      .then(licenseTypes => {
        constants.licenseTypes = licenseTypes;
        setConstants(Object.assign({}, constants))
      })
      .catch(_error => console.error('Licenses could not be retrieved'))
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseTypeService]);

  useEffect(() => {
    specialtyService.list(process.env.REACT_APP_X_API_KEY || '')
      .then(specialties => {
        constants.specialties = specialties;
        setConstants(Object.assign({}, constants))
      })
      .catch(_error => console.error('Specialties could not be retrieved'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialtyService]);

    useEffect(() => {
      jobService.listOrganizationsJobCount(process.env.REACT_APP_X_API_KEY || '')
        .then(organization => {
          constants.organizations = organization;
          
          setConstants(Object.assign({}, constants))
        })
        .catch(_error => console.error('Organizations could not be retrieved'))
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [jobService]);


  return (
    <ConstantContext.Provider value={{ constants, setConstants }}>
      {children}
    </ConstantContext.Provider>
  )
}

const useConstant = () => {
  return useContext(ConstantContext)
}

export { ConstantProvider, useConstant }
