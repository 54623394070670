import { Box } from '@mui/material';
import { useBrand } from 'contexts';
import React from 'react';
import './BrandedBanner.scss';

const BrandedBanner: React.FC<{}> = () => {
    const { brand } = useBrand();
    
    return (
        <Box className='flex w-100-p branded-image'>
            <img className='banner' alt={brand.name} src={brand.banner} />
        </Box>
    );
};

export default BrandedBanner;