import { AnyObject } from '@praos-health/core/utilities/object';
import { Organization } from './organization';

export enum StandardFileType {
	ActivityWork = 'Activity Work',
	AdditionalCertification = 'Additional Certification',
	AdditionalCertificationBack = 'Additional Certification Back',
	Certification = 'Certification',
	CertificationCoursework = 'Certification Coursework',
	Competency = 'Competency',
	ContinuingEducation = 'Continuing Education',
	ContinuingEducationCoursework = 'Continuing Education Coursework',
	Education = 'Education',
	EducationDocument = 'Education Document',
	EhrSkills = 'EHR and Computer Skills',
	License = 'License',
	ProfilePicture = 'Profile Picture',
	PSV_License = 'PSV License',
	OrganizationBanner = 'Organization Banner',
	OrganizationImage = 'Organization Image',
	OrganizationLogo = 'Organization Logo',
	OrganizationMembership = 'Organization Membership',
	Reference = 'Reference',
	Resume = 'Resume',
	WorkHistory = 'Work History'
}

export enum FileRequirement {
	NotAllowed = 0,
	Optional = 1,
	Required = 3,
	Mandatory = 4
}

export interface FileType {
	_id: any,
	name?: string,
	tags?: string[],
	organization?: Organization,
	editor?: string,
	settings?: AnyObject,
	jsonSchema?: string,
	fileRequirement?: FileRequirement,
	isEnabled?: false
}