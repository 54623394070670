import { parseLicense, License, LicenseHistory, parseLicenseHistory, prepLicense } from "./license";
import { Certification, CertificationHistory, parseCertification, parseCertificationHistory, prepCertification } from "./certification";
import { parseAdditionalCertification, parseAdditionalCertificationHistory, AdditionalCertification, AdditionalCertificationHistory, prepAdditionalCertification } from "./additional-certification";
import { DriversLicense, parseDriversLicense, prepDriversLicense } from "./drivers-license";
import { Education, EducationDocument, parseContinuingEducation, ContinuingEducation, prepContinuingEducation, prepEducationDocument, prepEducation } from "./education";
import { WorkExperience, parseWorkExperience, prepWorkExperience } from "./work-experience";
import { Competency, parseCompetency, prepCompetency } from "./competency";
import { prepReference, Reference } from "./reference";
import { parseAdditionalDocument, AdditionalDocument, prepAdditionalDocument } from "./additional-document";
import { HealthDocument, parseHealthDocument, prepHealthDocument } from "./health-document";
import { parseVehicleInsurance, prepVehicleInsurance, VehicleInsurance } from "./vehicle-insurance";
import { LiabilityInsurance, parseLiabilityInsurance, prepLiabilityInsurance } from "./liability-insurance";
import { ActivityWork, parseActivityWork, prepActivityWork } from "./activity-work";
import { OrganizationMembership, parseOrganizationMembership, prepOrganizationMembership } from "./organization-membership";
import { Facility } from "./facility";
import { BriefcaseSummary } from "./briefcase-summary";
import { isEmptyObject } from "@praos-health/core/utilities/object";

export type Briefcase = {
	nursys?: {
		isEnabled?: boolean,
		nextRunAt?: Date,
		verificationText?: string
	},

	currentStep?: number,
	consentedAt?: Date | true,
	completedAt?: Date,

	licensedAt?: Date,
	licenses?: License[],
	licenseHistory?: LicenseHistory[],
	specialties?: string[],
	certifications?: Certification[],
	certificationHistory?: CertificationHistory[],
	additionalCertifications?: AdditionalCertification[],
	additionalCertificationHistory?: AdditionalCertificationHistory[],

	facilities?: Facility[],

	w9?: string,
	driversLicense?: DriversLicense,
	references?: Reference[],
//	skillsChecklists?: SkillsChecklist[],

	educationLevel?: string,
	education?: Education[],
	educationDocuments?: EducationDocument[],
	continuingEducation?: ContinuingEducation[],
	workExperience?: WorkExperience,
	yearsOfExperience?: string,
	competencies?: Competency[],
	organizationMembership?: OrganizationMembership[],
	activityWork?: ActivityWork[],
	ehrSkills?: string[],

	healthDocuments?: HealthDocument[],
	additionalDocuments?: AdditionalDocument[],
	liabilityInsurance?: LiabilityInsurance,
	personalStatement?: string,
	vehicleInsurance?: VehicleInsurance,

	summary?: BriefcaseSummary
}

/*
export type SkillsChecklist = {
	name?: string,
	identifier?: string,
	fileUrl?: string,
	data?: any
}
*/

export enum BriefcaseField {
	ActivityWork = 'activitywork',
	AdditionalCertifications = 'additionalcertifications',
	AdditionalDocuments = 'additionaldocuments',
	Certifications = 'certifications',
	Competencies = 'competencies',
	ContinuingEducation = 'continuingeducation',
	Education = 'education',
	EducationDocuments = 'educationdocuments',
	Facilities = 'facilities',
	HealthDocuments = 'healthdocuments',
	Licenses = 'licenses',
	OrganizationMembership = 'organizationmembership',
	References = 'references'
}

export type BriefcaseItem = ActivityWork | AdditionalCertification | AdditionalDocument | Certification | Competency | 
	ContinuingEducation | Education | EducationDocument | Facility | HealthDocument | License | OrganizationMembership | Reference;

export function prepBriefcaseItem(field: BriefcaseField, item: BriefcaseItem): BriefcaseItem {
	switch (field) {
		case BriefcaseField.ActivityWork:
			prepActivityWork(item);
			break;
		case BriefcaseField.AdditionalCertifications:
			prepAdditionalCertification(item);
			break;
		case BriefcaseField.AdditionalDocuments:
			prepAdditionalDocument(item);
			break;
		case BriefcaseField.Certifications:
			prepCertification(item);
			break;
		case BriefcaseField.Competencies:
			prepCompetency(item);
			break;
		case BriefcaseField.ContinuingEducation:
			prepContinuingEducation(item);
			break;
		case BriefcaseField.HealthDocuments:
			prepHealthDocument(item);
			break;
		case BriefcaseField.Licenses:
			prepLicense(item);
			break;
		case BriefcaseField.OrganizationMembership:
			prepOrganizationMembership(item);
			break;
		case BriefcaseField.EducationDocuments:
			prepEducationDocument(item);
			break;
		case BriefcaseField.Education: 
			prepEducation(item);
			break;
		case BriefcaseField.References:
			prepReference(item);
			break;
		default:
			break;
	}

	return item;
}

export function prepBriefcase(item: Briefcase): Briefcase {
	if (item.licenses?.length) {
		for (let i = 0; i < item.licenses.length; i++) {
			if (isEmptyObject(item.licenses[i] = prepLicense(item.licenses[i]), true)) {
				item.licenses.splice(i, 1);
				i--;
			}
		}
	}

	if (item.certifications?.length) {
		for (let i = 0; i < item.certifications.length; i++) {
			if (isEmptyObject(item.certifications[i] = prepCertification(item.certifications[i]), true)) {
				item.certifications.splice(i, 1);
				i--;
			}
		}
	}

	if (item.additionalCertifications?.length) {
		for (let i = 0; i < item.additionalCertifications.length; i++) {
			if (isEmptyObject(item.additionalCertifications[i] = prepAdditionalCertification(item.additionalCertifications[i]), true)) {
				item.additionalCertifications.splice(i, 1);
				i--;
			}
		}
	}

	if (item.driversLicense) {
		item.driversLicense = prepDriversLicense(item.driversLicense);
	}

	if (item.continuingEducation) {
		for (let i = 0; i < item.continuingEducation.length; i++) {
			if (isEmptyObject(item.continuingEducation[i] = prepContinuingEducation(item.continuingEducation[i]), true)) {
				item.continuingEducation.splice(i, 1);
				i--;
			}
		}
	}

	if (item.workExperience) {
		item.workExperience = prepWorkExperience(item.workExperience);

		if (isEmptyObject(item.workExperience)) {
			delete item.workExperience;
		}
	}

	if (item.competencies) {
		for (let i = 0; i < item.competencies.length; i++) {
			if (isEmptyObject(item.competencies[i] = prepCompetency(item.competencies[i]), true)) {
				item.competencies.splice(i, 1);
				i--;
			}
		}
	}

	if (item.additionalDocuments) {
		for (let i = 0; i < item.additionalDocuments.length; i++) {
			if (isEmptyObject(item.additionalDocuments[i] = prepAdditionalDocument(item.additionalDocuments[i]), true)) {
				item.additionalDocuments.splice(i, 1);
				i--;
			}
		}
	}

	if (item.healthDocuments) {
		for (let i = 0; i < item.healthDocuments.length; i++) {
			if (isEmptyObject(item.healthDocuments[i] = prepHealthDocument(item.healthDocuments[i]), true)) {
				item.healthDocuments.splice(i, 1);
				i--;
			}
		}
	}

	if (item.references) {
		for (let i = 0; i < item.references.length; i++) {
			if (isEmptyObject(item.references[i] = prepReference(item.references[i]), true)) {
				item.references.splice(i, 1);
				i--;
			}
		}
	}

	if (item.liabilityInsurance) {
		item.liabilityInsurance = prepLiabilityInsurance(item.liabilityInsurance);
	}

	if (item.vehicleInsurance) {
		item.vehicleInsurance = prepVehicleInsurance(item.vehicleInsurance);
	}

	if (item.activityWork) {
		for (let i = 0; i < item.activityWork.length; i++) {
			if (isEmptyObject(item.activityWork[i] = prepActivityWork(item.activityWork[i]), true)) {
				item.activityWork.splice(i, 1);
				i--;
			}
		}
	}

	if (item.organizationMembership) {
		for (let i = 0; i < item.organizationMembership.length; i++) {
			if (isEmptyObject(item.organizationMembership[i] = prepOrganizationMembership(item.organizationMembership[i]), true)) {
				item.organizationMembership.splice(i, 1);
				i--;
			}
		}
	}

	if (item.educationDocuments) {
		for (let i = 0; i < item.educationDocuments.length; i++) {
			if (isEmptyObject(item.educationDocuments[i] = prepEducationDocument(item.educationDocuments[i]), true)) {
				item.educationDocuments.splice(i, 1);
				i--;
			}
		}
	}

	if (item.education) {
		for (let i = 0; i < item.education.length; i++) {
			if (isEmptyObject(item.education[i] = prepEducation(item.education[i]), true)) {
				item.education.splice(i, 1);
				i--;
			}
		}
	}

	return item;
}

export function parseBriefcase(json: any): Briefcase {
	if (json.nursys?.nextRunAt) {
		json.nursys.nextRunAt = new Date(json.nursys.nextRunAt);
	}

	if (json.completedAt) {
		json.completedAt = new Date(json.completedAt);
	}

	if (json.consentedAt) {
		json.consentedAt = new Date(json.consentedAt);
	}

	if (json.licensedAt) {
		json.licensedAt = new Date(json.licensedAt);
	}

	if (json.licenses?.length) {
		for (let i = 0; i < json.licenses.length; i++) {
			json.licenses[i] = parseLicense(json.licenses[i]);
		}
	}

	if (json.licenseHistory?.length) {
		for (let i = 0; i < json.licenseHistory.length; i++) {
			json.licenseHistory[i] = parseLicenseHistory(json.licenseHistory[i]);
		}
	}

	if (json.certifications?.length) {
		for (let i = 0; i < json.certifications.length; i++) {
			json.certifications[i] = parseCertification(json.certifications[i]);
		}
	}

	if (json.certificationHistory?.length) {
		for (let i = 0; i < json.certificationHistory.length; i++) {
			json.certificationHistory[i] = parseCertificationHistory(json.certificationHistory[i]);
		}
	}

	if (json.additionalCertifications?.length) {
		for (let i = 0; i < json.additionalCertifications.length; i++) {
			json.additionalCertifications[i] = parseAdditionalCertification(json.additionalCertifications[i]);
		}
	}

	if (json.additionalCertificationHistory?.length) {
		for (let i = 0; i < json.additionalCertificationHistory.length; i++) {
			json.additionalCertificationHistory[i] = parseAdditionalCertificationHistory(json.additionalCertificationHistory[i]);
		}
	}

	if (json.driversLicense) {
		json.driversLicense = parseDriversLicense(json.driversLicense);
	}

	if (json.continuingEducation) {
		for (let i = 0; i < json.continuingEducation.length; i++) {
			json.continuingEducation[i] = parseContinuingEducation(json.continuingEducation[i]);
		}
	}

	if (json.workExperience) {
		json.workExperience = parseWorkExperience(json.workExperience);
	}

	if (json.competencies) {
		for (let i = 0; i < json.competencies.length; i++) {
			json.competencies[i] = parseCompetency(json.competencies[i]);
		}
	}

	if (json.additionalDocuments) {
		for (let i = 0; i < json.additionalDocuments.length; i++) {
			json.additionalDocuments[i] = parseAdditionalDocument(json.additionalDocuments[i]);
		}
	}

	if (json.healthDocuments) {
		for (let i = 0; i < json.healthDocuments.length; i++) {
			json.healthDocuments[i] = parseHealthDocument(json.healthDocuments[i]);
		}
	}

	if (json.liabilityInsurance) {
		json.liabilityInsurance = parseLiabilityInsurance(json.liabilityInsurance);
	}

	if (json.vehicleInsurance) {
		json.vehicleInsurance = parseVehicleInsurance(json.vehicleInsurance);
	}

	if (json.activityWork) {
		for (let i = 0; i < json.activityWork.length; i++) {
			json.activityWork[i] = parseActivityWork(json.activityWork[i]);
		}
	}

	if (json.organizationMembership) {
		for (let i = 0; i < json.organizationMembership.length; i++) {
			json.organizationMembership[i] = parseOrganizationMembership(json.organizationMembership[i]);
		}
	}

	return json;
}