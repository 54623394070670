import { useState, useEffect, createContext, useContext, useMemo } from 'react'

import { CssBaseline, CircularProgress, Box } from '@mui/material'
import { createTheme, ThemeProvider, ThemeOptions, StyledEngineProvider } from '@mui/material/styles'
import { BrandProviderProps, InitialValues, Brand, BRIEFCASE_SERVICE, JOB_BOARD_SERVICE, Service } from './brand.types'

import componentsOverride from './theme/overrides'
import typography from './theme/typography'
import breakpoints from './theme/breakpoints'

import { brand } from 'configs'
import { useLocation } from 'react-router-dom'
import { useApi } from 'contexts/api/Api'
import Favicon from 'react-favicon'
import { DEFAULT_FAVICON } from 'configs/brand/brand'
import { useJobId } from 'contexts/job-id/JobId'

const initialValues: InitialValues = {
  brand,
  setBrand: () => { },
  resetBrand: () => { }
}

const BrandContext = createContext(initialValues)

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A'
}
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D'
}
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01'
}
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E'
}

const BrandProvider = ({ children }: BrandProviderProps) => {
  const companyName = useLocation().pathname.split('/')[1];
  const jobIdPath = useLocation().pathname.split('/')[2];
  
  const { organizationService } = useApi();
  const [brand, setBrand] = useState<Brand>(initialValues.brand)
  const [loadingBrand, setLoadingBrand] = useState(true);
  const { setJobId } = useJobId()

  useEffect(() => {
    if (companyName) {
      
      if (companyName.toLowerCase() !== companyName) {
         let locationHref = companyName.toLowerCase();
        window.location.href = locationHref;
  	  }
      
      if (jobIdPath) {
        setJobId(Object.assign({}, { id: jobIdPath }))
      }
 
      initialValues.brand.name = companyName;

      organizationService.get(companyName)
        .then(async (org) => {
          if (org) {
             setBrand({
              id: org._id?.toString() || '',
              name: org.name || initialValues.brand.name,
              pathName: companyName,
              logo: org.facility?.logoURL || initialValues.brand.logo,
              banner: org.facility?.bannerURL || initialValues.brand.banner,
              coBrandedUrl: org.coBrandedUrl || initialValues.brand.coBrandedUrl,
              companyUrl: companyName || org.coBrandedUrl,
              color: org.facility?.color ? ('#' + org.facility?.color) : initialValues.brand.color,
              googlePlayUrl: org.facility?.googlePlayUrl || initialValues.brand.googlePlayUrl,
              appleStoreUrl: org.facility?.appleStoreUrl || initialValues.brand.appleStoreUrl,
              favicon: org.facility?.logoURL || initialValues.brand.favicon,
              url: org?.facility?.url ?? null,
              isJobBoardEnabled: org.services && org.services.length > 0 ? isServiceEnabled(org.services, JOB_BOARD_SERVICE) : false,
              isBriefcaseEnabled: org.services && org.services.length > 0 ? isServiceEnabled(org.services, BRIEFCASE_SERVICE) : false,
            })
          }
         if(org?.services && org?.facility?.url){
          await isRedirectToCompany(org.services, JOB_BOARD_SERVICE, org?.facility?.url)
        } else {
          setLoadingBrand(false);
         }
        })
        .catch((e) => {
          setLoadingBrand(false); 
          window.location.href = '/'
          console.error(e);
        })
    } else {
      setLoadingBrand(false); 
    }
  }, [companyName, jobIdPath, organizationService, setJobId])


 const isRedirectToCompany = async (services: Service[] , service: string, brandUrl:  string | undefined) => {
   let result = await services.find(s => s.name === service)
   if(!result?.isEnabled && brandUrl){
    window.location.href = brandUrl
   }else{
    setLoadingBrand(false); 
   }
 }
 
  const isServiceEnabled= (services: Service[] , service: string) => {
    let res = services.find(s => s.name === service)
    return res?.isEnabled;
  }

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: {
        type: 'light',
        primary: { main: brand.color },
        secondary: { main: brand.color },
        info: { ...INFO, contrastText: '#fff' },
        success: { ...SUCCESS, contrastText: '#212B36' },
        warning: { ...WARNING, contrastText: '#212B36' },
        error: { ...ERROR, contrastText: '#fff' }
      },
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: 'ltr'
    }),
    [brand.color]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  const resetBrand = () => {
    setBrand(initialValues.brand)
  }

  const setBrandOverride = setBrand

  return (
    <BrandContext.Provider value={{ brand, setBrand: setBrandOverride, resetBrand }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Favicon url={[brand.favicon || DEFAULT_FAVICON]}/>
          { 
            loadingBrand && companyName ?
                <Box style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                    <CircularProgress 
                        style={{ 
                          color: '#a1a1a1',
                          width: 50, height: 50
                        }} />
                </Box>
                : children
          }
        </ThemeProvider>
      </StyledEngineProvider>
    </BrandContext.Provider>
  )
}

const useBrand = () => {
  return useContext(BrandContext)
}

export { BrandProvider, useBrand }
