import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type OrganizationMembership = {
	id?: string,
	name?: string,
	startDate?: Date,
	endDate?: Date
}

export function prepOrganizationMembership(membership: OrganizationMembership): OrganizationMembership {
	if (membership.startDate) {
		membership.startDate = midnight(new Date(membership.startDate), true);
	}

	if (membership.endDate) {
		membership.endDate = midnight(new Date(membership.endDate), true);
	}

	return membership;
}

export function parseOrganizationMembership(json: any): OrganizationMembership {
	if (json.startDate) {
		json.startDate = addMinutes(new Date(json.startDate).getTimezoneOffset(), new Date(json.startDate));
	}

	if (json.endDate) {
		json.endDate = addMinutes(new Date(json.endDate).getTimezoneOffset(), new Date(json.endDate));
	}

	return json;
}