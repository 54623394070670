import { clone } from "@praos-health/core/utilities/object";
import { LicenseType } from "./models";
import { ajax } from "./ajax"

export class LicenseTypeService {
	private _licenseTypeCache: LicenseType[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, profession?: string): Promise<LicenseType[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/licensetypes`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: LicenseType[];

		if (!this._licenseTypeCache) {
			this._licenseTypeCache = await ajax(`${this.apiUrl}/briefcase/licensetypes`, 'GET', auth);
		}

		result = this._licenseTypeCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}
		
		return clone(result);
	}
}
