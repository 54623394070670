import { filtersData } from "configs";
import { FiltersData, InitialValues } from "configs/filtersData/filtersData.types";
import { createContext, useContext, useState } from "react";
import { FilterProviderProps } from "./filter.types";

const FILTER_STORAGE_KEY_NAME = 'filters_data';

const initialValues: InitialValues = {
    filtersData,
    toggleFilters: false,
    setToggleFilters: () => { },
    setFiltersData: () => { },
    updateLocalData: () => { },
    resetFiltersData: () => { }
}
  
export const FilterDataContext = createContext(initialValues)
let firstProviderExecution = true;

const FilterDataContextProvider = ({ children }: FilterProviderProps) => {
  const [filtersData, setFiltersData] = useState<FiltersData>(initialValues.filtersData)
	const [toggleFilters, setToggleFilters] = useState<Boolean>(initialValues.toggleFilters)

  if (firstProviderExecution) {
    firstProviderExecution = false;
    
    let filtersDataStored = localStorage.getItem(FILTER_STORAGE_KEY_NAME)

    if (filtersDataStored) {
        let parsedFiltersDataStored = JSON.parse(filtersDataStored) as FiltersData

        filtersData.rate = parsedFiltersDataStored.rate;
        filtersData.jobType = parsedFiltersDataStored.jobType;
        filtersData.license = parsedFiltersDataStored.license;
        filtersData.location = parsedFiltersDataStored.location;
        filtersData.specialty = parsedFiltersDataStored.specialty;
        filtersData.startDate = parsedFiltersDataStored.startDate;
        filtersData.search = parsedFiltersDataStored.search;
        filtersData.organization = parsedFiltersDataStored.organization;
        filtersData.rateFrequency = parsedFiltersDataStored.rateFrequency;
    }
  }

  const updateLocalData = () => {
    localStorage.setItem(FILTER_STORAGE_KEY_NAME, JSON.stringify(filtersData))
  }

  const resetFiltersData = () => {
    filtersData.rate = undefined;
    filtersData.rateFrequency = undefined;
    filtersData.jobType = undefined;
    filtersData.license = undefined;
    filtersData.location = undefined;
    filtersData.specialty = undefined;
    filtersData.startDate = undefined;
    filtersData.search = undefined;
    filtersData.organization = undefined;

    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  }

  return (
    <FilterDataContext.Provider value={{ filtersData, toggleFilters, setToggleFilters, setFiltersData, updateLocalData, resetFiltersData: resetFiltersData }}>
      {children}
    </FilterDataContext.Provider>
  );
};

const useFiltersData = () => {
  return useContext(FilterDataContext)
}
  

export { FilterDataContextProvider, useFiltersData };
