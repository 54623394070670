export type JobAuditTrail = {
	action: string,
	createdAt: Date,
	createdByUser: {
		intId: number,
		firstName: string,
		lastName: string,
		email?: string
	},
	job: {
		timezoneText: string
	},
	user?: {
		intId: number,
		firstName: string,
		lastName: string,
		email?: string
	}
}

export function parseAuditTrail(json: any): JobAuditTrail {
	const result: JobAuditTrail = json;

	if (json.createdAt) {
		result.createdAt = new Date(json.createdAt);
	}

	return result;
}