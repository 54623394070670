import React from 'react';
import { Box, Typography } from '@mui/material';
import { Job } from '@praos-health/jobs-client';
import ClearIcon from '@mui/icons-material/Clear';
import { useBrand } from 'contexts';
import './JobHeader.scss';
import { isMobile } from 'utils';
import { Avatar } from '@mui/material';

interface JobProps {
	job: Job,
	handleClose: () => void
}

function getInitials(name?: string): string {
	if (!name) {
		return name || '';
	}

	const parts = name.split(' ');

	if (parts.length === 1) {
		return parts[0].substring(0, 1).toUpperCase();
	}

	return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
}

const JobHeader: React.FC<JobProps> = ({ job, handleClose }) => {
	const { brand } = useBrand();

	return (
		<Box sx={{ pb: 2, px: 6 }} className="jobHeaderBox">
			<Box className='modalType' style={{ backgroundColor: brand.color, right:'30px' }}>
				{job.type}
			</Box>
			<Box className={`cancelDiv ${isMobile() ? 'cancelMobile' : 'cancelDesktop'}`}>
				<ClearIcon sx={{color: brand.color}} className='cancel' onClick={handleClose} />
			</Box>
			<Box className='header'>
				<Typography sx={{ fontSize: 18, fontWeight: 600 }}>
					{job.title}
				</Typography>
			</Box>
			<Box className="subHeader">
				<Avatar sx={{ width: 48, height: 48 }} src={job.organization?.facility?.logoURL} >{getInitials(job.organization?.name)}</Avatar>
				<Box className='jobIdTitle'>
					<Box className='organization' >{job.organization?.name}</Box>
					<Box>
						JOB ID # <span className='jobId' style={{ color: brand.color }}>{job.thirdPartyId ? job.thirdPartyId : job.intId}</span>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default JobHeader;