import { ajax } from "./ajax"

export type ConstantsOptions = {
	collectionName?: string,
	properties?: string[],
	filter?: {}
}

export class ConstantsService {
	constructor(protected apiUrl: string) {
	}

	async findConstants(auth: string, options?: ConstantsOptions): Promise<any> {
		return await ajax(`${this.apiUrl}/system/constants`, 'POST', auth, options);
	}
}