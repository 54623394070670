import { Department } from "./department";
import { User } from "./user";

export type OrientationDocument = {
	id?: any,
	name?: string,
	description?: string,
	path?: string,
	department?: Department,
	createdAt?: Date,
	createdBy?: User
}

export function prepOrientationDocument(doc: OrientationDocument): OrientationDocument {
	delete doc.createdAt;
	delete doc.createdBy;
	delete doc.path;

	return doc;
}

export function parseOrientationDocument(json: any): OrientationDocument {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	return json;
}