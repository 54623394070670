import { AnyObject } from "@praos-health/core/utilities/object"
import { FileType } from "./file-type"
import { User } from "./user"
import { Organization } from "./organization"

export enum FilePermissions {
	UserRead = 1,
	UserWrite = 2,
	OrganizationRead = 4,
	OrganizationWrite = 8,
	AnyOrganizationRead = 16,
	AnyOrganizationWrite = 32,
	Public = 64
}

export interface UploadedFile {
	_id?: any,
	user?: User,
	organization?: Organization,
	createdBy?: User,
	createdAt?: Date,
	updatedAt?: Date,
	completedAt?: Date,
	deletedAt?: Date,
	deletedBy?: User,
	url?: string,
	fileType?: FileType,
	fileName?: string,
	thumbnails?: { [size: string]: FileThumbnail },
	data?: AnyObject,
	permissions?: FilePermissions,
	tags?: { [key: string]: string }
}

export interface FileThumbnail {
	fileName: string,
	url: string
}