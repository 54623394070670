import { Box } from '@mui/material';
import { useBrand } from 'contexts';
import { useUserData } from 'contexts/user/User';
import React from 'react';
import './JobFooter.scss';

interface ModalTypeProps {
    modalType?: string
}


const JobFooter: React.FC<ModalTypeProps> = ({ modalType }) => {
    const { brand } = useBrand();
    const { user, resetUserData } = useUserData();

    const handleLogin = () => {
        window.open(brand.coBrandedUrl ?
            `${process.env.REACT_APP_NURSE_URL}/#/co-branded-login/${brand.coBrandedUrl}` :
            `${process.env.REACT_APP_NURSE_URL}/#/login`, '_blank')
    }

    const handleClearUser = () => {
        resetUserData();
    }

    return (
        <div className={`jobFooter ${!user || modalType === 'refer' || !modalType ? 'one-line' : ''}`}>
            {brand?.isBriefcaseEnabled &&
                <Box sx={{textAlign: 'center'}}>
                    <span>Already have an account?</span>
                    <span
                        onClick={handleLogin}
                        className='loginLink'
                        style={{ color: brand.color, cursor: 'pointer' }}>Log In</span>
                </Box>
            }
            {
                !!user && modalType === 'apply' &&
                <Box sx={{textAlign: 'center'}}>
                    Is your data incorrect? <span onClick={handleClearUser} style={{ color: brand.color, cursor: 'pointer' }}>Click here</span> to start from scratch.
                </Box>
            }
        </div>
    );
};

export default JobFooter;