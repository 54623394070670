import { isObject, toJson } from "@praos-health/core/utilities/object";
import { SystemError } from "./system-error";

export async function ajax(url: RequestInfo, method: string, auth?: string, body?: any, qs?: any): Promise<any> {
	if (qs) {
		url += '?' + Object.keys(qs).reduce((i, j) => {
			let v = toJson(qs[j]);

			if (isObject(v)) {
				v = JSON.stringify(v);
			}

			i.push(`${j}=${encodeURIComponent(v)}`);

			return i;
		}, []).join('&');
	}

	const headers: any = {};

	if (body && !(body instanceof FormData)) {
		headers['Content-Type'] = 'application/json';
		body = JSON.stringify(toJson(body));
	}

	if (auth) {
		headers.Authorization = auth;
	}

	const response: Response = await fetch(
		url,
		{
			method,
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers,
			redirect: 'manual',
			referrerPolicy: 'no-referrer',
			body
		}
	);

	return await handleResponse(response);			
}

async function handleResponse(response: Response): Promise<any> {
	const json = (response.statusText === 'No Content') ? undefined : await response.json();

	if (response.ok) {
		return json;
	}

	const location = response.headers.get('location');
	const type = (location || !json?.type) ? 'SystemError' : json.type;
	const err = new SystemError(type, json?.message || response.statusText, response.status, location);

	if (json.stack) {
		err.stack = json.stack;
	}

	throw err;
}
