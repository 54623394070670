import { createContext, useContext } from 'react'

import { JobService } from '@praos-health/jobs-client'
import { OrganizationService } from '@praos-health/organizations-client'
import { InitialValues, ApiProviderProps } from './api.types'
import { LicenseTypeService, SpecialtyService, CityService } from '@praos-health/briefcase-client'
import { ProfessionalService } from '@praos-health/professional-client';
import { UserService } from '@praos-health/users-client'
import { SystemService } from '@praos-health/system-client'
import { FileService } from '@praos-health/files-client'

const API_URL = process.env.REACT_APP_API_URL || ''

const initialValues: InitialValues = {
  jobService: new JobService(API_URL),
  organizationService: new OrganizationService(API_URL),
  specialtyService: new SpecialtyService(API_URL),
  licenseTypeService: new LicenseTypeService(API_URL),
  cityService: new CityService(API_URL),
  userService: new UserService(API_URL),
  professionalService: new ProfessionalService(API_URL),
  settingService: new SystemService(API_URL),
  fileService: new FileService(API_URL)
}

const ApiContext = createContext(initialValues)

const ApiProvider = ({ children }: ApiProviderProps) => {
  return (
    <ApiContext.Provider value={initialValues}>
      {children}
    </ApiContext.Provider>
  )
}

const useApi = () => {
  return useContext(ApiContext)
}

export { ApiProvider, useApi }
