import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { City } from '@praos-health/briefcase-client';
import { useApi } from 'contexts';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { useEffect, useState } from 'react';
import './LocationFilter.scss';

let firstExecution = true;

const LocationFilter: React.FC<{}> = () => {
    const [ options, setOptions ] = useState<City[]>([]);
    const [ value, setValue ] = useState<City[]>([]);
    const { filtersData, setFiltersData, updateLocalData } = useFiltersData();
    const { cityService } = useApi()
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ cityName, setCityName ] = useState<string>('');

    function onCitiesChanged(_event: React.SyntheticEvent, selectedCities: City[]) {
        if (selectedCities.length === 0) {
            filtersData.location = undefined
        } else {
            filtersData.location = selectedCities
        }

        setValue(selectedCities)
        setFiltersData(Object.assign({}, filtersData))
        updateLocalData()
    }
    
    useEffect(() => {
        if (firstExecution) {
            firstExecution = false;
            setValue(Object.assign([], filtersData.location))
        }

        if (filtersData.location === undefined) {
            setValue([]);
        }
    
    }, [filtersData.location])

    useEffect(() => {
        if (cityName && cityName.length >= 2) {
            firstExecution = false;

            const timeOutId = setTimeout(() => {
                setLoading(true)
                cityService.listExternal(process.env.REACT_APP_X_API_KEY || '', cityName)
                    .then(cities => {
                        let uniqueIds: string[] = [];
                        if (cities && cities.length) {
                            if (value && value.length) {
                                let mergedCities = cities.concat(value).filter(element => {
                                    const isDuplicate = uniqueIds.includes(element._id);
                                    if (!isDuplicate) {
                                        uniqueIds.push(element._id);
                                        return true;
                                    }
                                    return false;
                                });
                                setOptions(mergedCities);
                            }  else {
                                setOptions(cities)
                            }
                        } else {
                            setOptions(value)
                        }
                    })
                    .catch(console.error)
                    .finally(() => setLoading(false))
                }, 200);

            return () => clearTimeout(timeOutId);
        } else {
            setOptions([]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityName]);
    
    const convertReadableName = (city: City) => {
        let label = city.city ? city.city : '';
        label += city.state ? `, ${city.state}` : ''
        return label;
    }

    const onInputChange = (event: React.SyntheticEvent, cityName: string) => {
        setCityName(cityName);
    }

    return (
        <Box className='filter-location'>
            <Autocomplete
                multiple
                disableClearable
                color='primary'
                id='filter-location-autocomplete'
                loading={loading}
                options={options}
                getOptionLabel={convertReadableName}
                onChange={onCitiesChanged}
                onInputChange={onInputChange}
                value={value}
                noOptionsText={cityName.length < 2 ? 'Start writing to search' : 'No options'}
                renderTags={(value: City[], getTagProps) => (
                    <Box >
                        {value.map((option: City, index: number) => (
                            <Chip
                                variant="outlined"
                                label={convertReadableName(option)}
                                {...getTagProps({ index })}
                            />
                        ))}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Preferred cities" />
                )}/>
        </Box>
  );
};

export default LocationFilter;