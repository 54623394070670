import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type AdditionalCertification = {
	id?: string,
	name?: string,
	label?: string,
	downloadUrl?: string,
	frontFileUrl?: string,
	backFileUrl?: string,
	expirationDate?: Date,
	eCardNumber?: string,
	uploadedAt?: Date
}

export type AdditionalCertificationHistory = {
	name?: string,
	frontFileUrl?: string,
	backFileUrl?: string,
	createdAt?: Date
}

export function prepAdditionalCertification(cert: AdditionalCertification): AdditionalCertification {
	delete cert.frontFileUrl;
	delete cert.backFileUrl;
	delete cert.uploadedAt;
	delete cert.downloadUrl;

	if (cert.expirationDate) {
		cert.expirationDate = midnight(new Date(cert.expirationDate), true);
	}

	return cert;
}

export function parseAdditionalCertification(json: any): AdditionalCertification {
	if (json.expirationDate) {
		json.expirationDate = addMinutes(new Date(json.expirationDate).getTimezoneOffset(), new Date(json.expirationDate));
	}
	
	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}

export function parseAdditionalCertificationHistory(json: any): AdditionalCertificationHistory {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	return json;
}