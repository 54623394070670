import { User, parseUser, prepUser } from "./user";
import { Briefcase, parseBriefcase, prepBriefcase } from "./briefcase";
import { Affiliation, parseAffiliation } from "./affiliation";
import { EmergencyContact } from "./emergency-contact";
import { JobSettings, parseJobSettings } from "./job-settings";
import { replaceAll } from "@praos-health/core/utilities/string";

export type Professional = User & {
	profession?: string,
	briefcase?: Briefcase,
	gender?: 'Male' | 'Female' | 'Other',
	ssn?: Date | boolean,
	emergencyContact?: EmergencyContact,
	isMarketplace?: boolean,
	affiliations?: Affiliation[],
	suspendedAt?: Date,
	activatedAt?: Date,
	jobs?: JobSettings
}

export function prepProfessional(item: Professional): Professional {
	item = prepUser(item);

	if (item.briefcase) {
		item.briefcase = prepBriefcase(item.briefcase);
	}

	if (item.phoneNumber) {
		item.phoneNumber = replaceAll(item.phoneNumber, "-", "");
	}

	if (item.address?.zip) {
		item.address.zip = replaceAll(item.address.zip, "-", "");
	}

	return item;
}

export function parseProfessional(json: any): Professional {
	if (!json) return json;
	
	json = parseUser(json);

	if (json.affiliations) {
		for (let i = 0; i < json.affiliations.length; i++) {
			json.affiliations[i] = parseAffiliation(json.affiliations[i]);
		}
	}

	if (json.briefcase) {
		json.briefcase = parseBriefcase(json.briefcase);
	}

	if (json.jobs) {
		json.jobs = parseJobSettings(json.jobs);
	}

	return json;
}

export function getAccountStatus(user: Professional): string {
	if (user.deactivatedAt) {
		return 'DEACTIVATED';
	}

	if (!user.activatedAt) {
		return 'INACTIVE';
	}

	if (user.suspendedAt) {
		return 'SUSPENDED';
	}

	return 'ACTIVE';
}