export type User = {
	_id?: string,
	firstName?: string,
	lastName?: string,
	email?: string,
	phoneNumber?: string,
	profilePicUrl?: string,
	profilePicThumbUrl?: string,
	licenseDetails?: any;
}

export function parseUser(json: any): User {
	return json;
}