import { Theme } from '@mui/material/styles'

import Button from './Button'
import Card from './Card'
import CssBaseline from './CssBaseline'
import Drawer from './Drawer'
import Link from './Link'
import Progress from './Progress'
import Select from './Select'
import Stepper from './Stepper'
import Switch from './Switch'
import ToggleButton from './ToggleButton'
import Tabs from './Tabs'
import Typography from './Typography'

export default function ComponentsOverrides (theme: Theme) {
  return {
    ...Button(theme),
    ...Card(theme),
    ...CssBaseline(theme),
    ...Drawer(theme),
    ...Link(theme),
    ...Progress(theme),
    ...Select(theme),
    ...Stepper(theme),
    ...Switch(theme),
    ...ToggleButton(theme),
    ...Tabs(theme),
    ...Typography(theme)
  }
}
