import * as React from 'react';
import { Badge, Box, Button, CircularProgress, Divider, Grid, InputAdornment, Tab, Tabs, TextField, Typography } from '@mui/material';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import './TabNav.scss'
import { Brand } from 'configs/brand/brand.types';
import JobCard from '../JobCard';
import { Job, ListJobsResult } from '@praos-health/jobs-client';
import { HighlightOff, Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useFiltersData } from 'contexts/filter/Filter';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useJobsCounter } from 'contexts/jobs-counter/JobsCounter';
import Map from '../map';
import { isMobile } from 'utils';
import { useFavoritesData } from 'contexts/favorites/Favorites';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useApi } from 'contexts';

interface TabProps {
  children?: React.ReactNode;
  brand: Brand;
  jobs: ListJobsResult;
  mapJobs: ListJobsResult;
  setJobs: (jobs: ListJobsResult) => void;
  loadingJobs: Boolean;
  setLoadingJobs: (value: boolean) => void;
  setSkip: (skip: number) => void;
  skip: number;
  limit: number;
  loadMoreLoading: boolean;
  setSelectedTabValue: (value: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let firstExecution = true;

const TabNav: React.FC<TabProps> = ({ brand, jobs, mapJobs, setJobs, loadingJobs, setLoadingJobs, setSkip, skip, limit, loadMoreLoading, setSelectedTabValue }) => {
  const navigate = useNavigate();
  const { jobService } = useApi()
  const [value, setValue] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedJob, setSelectedJob] = useState<Job | undefined>(undefined);
  const [loadingJobDetails, setLoadingJobDetails] = useState(false)
  // const [ jobsPerPage ] = useState(25);
  // const [ currentPage, setCurrentPage ] = useState(1);

  const { filtersData, setFiltersData, updateLocalData, setToggleFilters } = useFiltersData();
  const { jobsCounter } = useJobsCounter();
  const { favorites } = useFavoritesData();
  // const [suggestionsVisible, setSuggestionsVisible] = useState(false);

  // const { jobService } = useApi()

  // const [suggestions, setSuggestions] = useState<{label: string, id: any}[]>([]);
  // useEffect(() => {
  //   if(typeof searchValue === 'string' && searchValue.length) {
  //     try {
  //       (async () => {
  //         const result = await jobService.autocomplete({query: searchValue});
  //         setSuggestions(result.map((item, index) => ({label: item, id: `${index}-${new Date().toISOString()}-${item}`})));
  //       })()  
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   } else {
  //     setSuggestions([]);
  //   }
  // }, [searchValue, jobService]);
  
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setLoadingJobs(true);
    setJobs({ count: 0, list: [] });
    setSkip(0);
    setValue(newValue);
    setSelectedTabValue(newValue);
    setSelectedJob(undefined);
  }; 

  function clearSearchValue(): void {
    setSearchValue('')
    filtersData.search = undefined
    setFiltersData(Object.assign({}, filtersData))
    updateLocalData()
  }

  function getEndAdornment(): React.ReactNode {
    if (searchValue && searchValue.length > 0) {
        return <InputAdornment position="end"><HighlightOff color='primary' className='clear-search-value-icon' onClick={clearSearchValue}/> </InputAdornment>;
    }
    
    return null;
  }

  function getCurrentJobCounterComponent(): React.ReactNode {
    return <Box className='flex h-40-px current-jobs-wrapper'>
        <ManageSearchIcon className='align-center'/>&nbsp;
        <Typography className='align-center current-jobs-number' fontWeight={'bold'} color='primary'>{jobsCounter.current}</Typography>&nbsp;
        <Typography className='align-center current-jobs-result' color={'#929292'} >{jobsCounter.current !== 1 ? 'Results' : 'Result'}</Typography>
    </Box>
  }

  function onToggleFilters(): void {
    // eslint-disable-next-line no-new-wrappers
    setToggleFilters(new Boolean(true));
  }

  // const hanldeEnterPress = (event: any) => {
  //   if(event.key === 'Enter') {
  //     setSuggestionsVisible(false)
  //   }
  // }

  function getTextSearchComponent(): React.ReactNode {

    // Define styles for the Autocomplete Paper component
    // const autocompletePaperProps = {
    //   style: {
    //     borderRadius: '4px 4px 0 0', // Rounded corners on the top and square on the bottom
    //     marginTop: '0',
    //     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    //     borderTop: suggestionsVisible ? 'none' : '1px solid rgba(0, 0, 0, 0.25)',
    //   }
    // };
    return (
      <Box className={'job-text-search-wrapper flex'}>
        <TextField className='job-text-search' placeholder="What is your dream job?" variant="outlined" 
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
          autoComplete='off'
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
            endAdornment: getEndAdornment()
          }}
        />
        
        { isMobile() && (
          <Box display={'flex'} justifyContent={'flex-end'} minWidth={'60px'} alignItems={'center'} onClick={onToggleFilters}>
            <Badge badgeContent={jobsCounter.filtersApplied} color="primary">
              <FilterAltOutlinedIcon fontSize='large' sx={{ width: 40, height: 40, color: brand.color }} />
            </Badge>
          </Box>
        )}
      </Box>
    );
  }

  function buildAvailableJobsLabel(): string {
    return !isMobile() ? "Available Jobs" : "";
  }
  
  function buildLocationJobsLabel(): string {
    return !isMobile() ? "Jobs by Location" : "";
  }
  
  useEffect(() => {
    const timeOutId = setTimeout(() => {
        filtersData.search = searchValue
        setFiltersData(Object.assign({}, filtersData))
        updateLocalData()
    }, 500);
    return () => clearTimeout(timeOutId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  
  useEffect(() => {
    if (firstExecution) {
        firstExecution = false;
        setSearchValue(filtersData.search || '')
    }

    if (!filtersData.search) {
        setSearchValue(undefined || '')
    }

  }, [filtersData.search])

  const getSelectedJob = async (jobId: string) => {
    if(jobId){
      setLoadingJobDetails(true)
      let job = await jobService.get(jobId)
      setLoadingJobDetails(false)
      setSelectedJob(job);
    }else{
      setSelectedJob(undefined)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor='secondary'
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="tabs">
          <Tab
            style={{ color: value === 0 ? brand.color : '#929292', fontFamily: 'Lato-Light', fontWeight: 900, fontSize: '18px' }}
            className={`headerTab left ${value === 0 ? 'selected' : 'unselected'}`}
            label={buildAvailableJobsLabel()}
            icon={<Badge badgeContent={isMobile() ? jobsCounter.current : undefined} color="primary"><WorkOutlineOutlinedIcon fontSize='medium' /></Badge>}
            iconPosition="start" {...a11yProps(0)} />
          <Tab
            style={{ color: value === 1 ? brand.color : '#929292', fontFamily: 'Lato-Light', fontWeight: 900, fontSize: '18px' }}
            className={`headerTab ${value === 1 ? 'selected' : 'unselected'}`}
            label={buildLocationJobsLabel()}
            icon={<PinDropOutlinedIcon fontSize='medium' />}
            iconPosition="start" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>  
        { getTextSearchComponent() }
        { loadingJobs &&
            <Box style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                <CircularProgress 
                    style={{ 
                    width: 50, height: 50
                    }} />
            </Box>
        }
        { !loadingJobs && <>
                { !isMobile() && getCurrentJobCounterComponent() }
                { !isMobile() && <Divider /> }
                <Box className='w-100-p'>
                  <Grid container>
                      {
                        jobs.list
                          .sort((a, b) => {
                            const isAFavorite = favorites.includes(a._id);
                            const isBFavorite = favorites.includes(b._id);
                            const isAExclusive = a.isExclusive;
                            const isBExclusive = b.isExclusive;

                            if (isAFavorite && isBFavorite) {
                                if (isAExclusive && !isBExclusive) {
                                return -1;
                                } else {
                                return 0;
                              }
                            } else if (isAFavorite && !isBFavorite) {
                              return -1;
                            } else if (!isAFavorite && isBFavorite) {
                              return 1;
                              } else if (isAExclusive && !isBExclusive) {
                              return -1;
                              } else {
                              return 1;
                            }
                          })
                          .map(job => {
                            return (
                              <Grid key={job._id} item xs={12} md={12} lg={6} rowGap={1} >
                                  <JobCard job={job}></JobCard>
                              </Grid>
                            )
                        })
                      }
                      {
                          jobs.count > 0 && jobs.count > (skip + limit) &&
                          <Box className='flex flex-justify-center w-100-p'>
                            <LoadingButton loadingIndicator={<CircularProgress sx={{color: brand.color}} size={16} />} loading={loadMoreLoading} sx={{ width: 200 }} variant="outlined" onClick={_e => setSkip(skip + limit)}>Load More</LoadingButton>
                          </Box>
                      }
                      {
                        jobs.count === 0 && 
                          <Box className='w-100-p flex flex-column align-items-center m-t-60-px'>
                          <Box sx={{ width: isMobile() ? '100%' : '40%', textAlign: 'center' }}>
                            <Typography sx={{ color: '#929292', fontSize: isMobile() ? '20px' : '22px' }}>Thank you for visiting. Tell us about yourself to have a recruiter contact you.</Typography>
                          </Box>
                          <Button 
                            variant="text"  
                            sx={{color: brand.color, fontWeight: 700, fontSize: '30px' }}
                            onClick={() => {
                              if (brand.companyUrl) {
                                navigate(`/${brand.companyUrl}/apply-now`)
                              } else {
                                window.location.href = `${process.env.REACT_APP_NURSE_URL}/#/signup`
                              }
                            }}>
                              Apply Now
                          </Button>
                          <Box sx={{marginTop: '20px'}}>
                            <Typography sx={{ color: '#929292', fontSize: isMobile() ? '20px' : '22px', textAlign: 'center' }}>To speed up the process, look out for an email to compile your credentials.</Typography>
                          </Box>
                          </Box>
                      }
                  </Grid>
                </Box>
            </>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        { getTextSearchComponent() }
        { !isMobile() && getCurrentJobCounterComponent() }
        { !isMobile() && <Divider /> }
        <Box className='map-wrapper' sx={{left: isMobile() ? '-25px'  : '0', width: isMobile() ? 'calc(100% + 50px)' : '100%'}}>
          <Map
            mapType={google.maps.MapTypeId.ROADMAP}
            mapTypeControl={false}
            jobs={mapJobs}
            onSelectedJob={(job: Job) => getSelectedJob(job?._id)}
          />
          <Box className='job-selected-wrapper'>
            { selectedJob && <JobCard job={selectedJob} /> }
          </Box>
          {loadingJobDetails && 
            <Box sx={{position: 'absolute', zIndex: 5, bottom: '20%', right: '20%'}}>
              <CircularProgress 
                style={{ 
                width: 50, height: 50
                }} />
            </Box>
          }
        </Box>
      </TabPanel>
    </Box>
  );
}

export default TabNav;