import { Client } from "./client";
import { Department } from "./department";
import { Organization } from "./organization";
import { parseUser, prepUser, User } from "./user";

export type OrganizationUser = User & {
	jobTitle?: string,
	isSuperAdmin?: boolean,
	organization?: Organization,
	client?: Client,
	department?: Department
}

export function prepOrganizationUser(user: OrganizationUser): OrganizationUser {
	return prepUser(user);
}

export function parseOrganizationUser(json: any): OrganizationUser {
	return parseUser(json);
}