export type JobLocation = {
	name: string,
	address1?: string,
	address2?: string,
	city: string,
	state: string,
	zip?: string,
	coordinates?: number[]
}

export function parseJobLocation(json: any): JobLocation {
	return json;
}