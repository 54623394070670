import { createContext, useContext, useState, useEffect, ReactNode } from "react";

const FAVORITE_STORAGE_KEY_NAME = 'favorites_data';

const initialValues: 
    { 
        favorites: string[],  
        addFavorite: (id: string) => void,
        removeFavorite: (id: string) => void,
        isFavorite: (id: string) => boolean
    } = {
    favorites: [],
    addFavorite: (id: string) => {},
    removeFavorite: (id: string) => {},
    isFavorite: (id: string) => false
}
  
export const FavoritesDataContext = createContext(initialValues)

const FavoritesDataContextProvider = ({ children }: { children: ReactNode }) => {
  const [favoriteData, setFavoriteData] = useState<string[]>([])


  useEffect(() => {
    let favoriteDataStored = localStorage.getItem(FAVORITE_STORAGE_KEY_NAME)

    if (favoriteDataStored) {
        let parsedFavoriteDataStored = JSON.parse(favoriteDataStored) as string[]
        setFavoriteData((_favorites) => parsedFavoriteDataStored);
    }
  }, [])

  const addFavorite = (id: string) => {
    let favoriteDataStored = localStorage.getItem(FAVORITE_STORAGE_KEY_NAME);
    if (favoriteDataStored) {
        let parsedFavoriteDataStored = JSON.parse(favoriteDataStored) as string[]
        const index = parsedFavoriteDataStored.findIndex(f => f === id);
        if (index < 0) {
            parsedFavoriteDataStored.push(id);
            setFavoriteData(parsedFavoriteDataStored);
            localStorage.setItem(FAVORITE_STORAGE_KEY_NAME, JSON.stringify(parsedFavoriteDataStored));
        }
    } else {
        setFavoriteData([id]);
        localStorage.setItem(FAVORITE_STORAGE_KEY_NAME, JSON.stringify([id]));
    }
  }

  const removeFavorite = (id: string) => {
    let favoriteDataStored = localStorage.getItem(FAVORITE_STORAGE_KEY_NAME);
    if (favoriteDataStored) {
        let parsedFavoriteDataStored = JSON.parse(favoriteDataStored) as string[]
        const index = parsedFavoriteDataStored.findIndex(f => f === id);
        if (index >= 0) {
            parsedFavoriteDataStored.splice(index, 1);
            setFavoriteData(parsedFavoriteDataStored);
            localStorage.setItem(FAVORITE_STORAGE_KEY_NAME, JSON.stringify(parsedFavoriteDataStored));
        }
    } else {
        setFavoriteData([id]);
        localStorage.setItem(FAVORITE_STORAGE_KEY_NAME, JSON.stringify([id]));
    }
  }

  const isFavorite = (id: string) => {
    return favoriteData.includes(id);
  }

  return (
    <FavoritesDataContext.Provider value={{ favorites: favoriteData, addFavorite, removeFavorite, isFavorite }}>
      {children}
    </FavoritesDataContext.Provider>
  );
};

const useFavoritesData = () => {
  return useContext(FavoritesDataContext)
}
  

export { FavoritesDataContextProvider, useFavoritesData };
