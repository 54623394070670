import { useState } from 'react';
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './Refer.scss';
import ModalPopup from '../modal';
import CompleteInfo from '../jobForms/completeInfo/CompleteInfo';
import { isMobile } from 'utils';
import ReferForm from '../jobForms/referForm/ReferForm';
import { useBrand } from 'contexts';


const Refer: React.FC<{}> = () => {
    const navigate = useNavigate()
    const { brand } = useBrand()
    const [openConfirmModal, setOpenConfirmModal] = useState(false)

    const handleFormSubmit = () => {
        setOpenConfirmModal(true)
    }
    const handleCloseApplyModal = () => {
        setOpenConfirmModal(false)
        navigate(`/${brand.companyUrl}`)
    }

    return (
        <>
            <Box className='flex content-wrapper flex-basis-75'>
                <Box className='content-body content-filter-wrapper inline-flex apply-content' sx={{ width: isMobile() ? '' : '60% !important' }}>
                    <div>
                        <div style={{margin: isMobile() ? '0 20px 40px 20px' : '0 0 40px 0',}}>
                            <Typography sx={{textAlign: 'center', fontWeight: 'bold', fontSize: '2rem', color: '#3c424b' }}>Refer a friend today!</Typography>
                            <Typography sx={{textAlign: 'center'}}>Do you know a friend or colleague who might also enjoy working with us? Refer them below!</Typography>
                        </div>
                        <ReferForm job={undefined} selectedTab={2} handleNextTab={handleFormSubmit} handlePreviousTab={undefined}></ReferForm>
                    </div>
                </Box>
            </Box>
            <ModalPopup
                openModal={openConfirmModal}
                handleCloseModal={handleCloseApplyModal}>
                <CompleteInfo 
                    title='Your referral has been submitted!' 
                    contentTitle='Thank you for sharing your referral with us.'
                    message='A recruiter will reach out to your referral soon to discuss their preferences and answer any questions.' 
                    handleClose={handleCloseApplyModal}></CompleteInfo>
            </ModalPopup>
        </>
    );
};

export default Refer;
