import { ajax, ajaxKey } from "./ajax"
import { City } from "./models/city";

export class CityService {

	constructor(protected apiUrl: string) {
	}

	async list(auth: string, cityPartialName?: string): Promise<City[]> {
        if (!cityPartialName || cityPartialName.length < 2) {
            return new Promise<City[]>((resolve) => resolve([]));
        }

        return await ajax(`${this.apiUrl}/briefcase/cities`, 'GET', auth, undefined, { cityPartialName });
	}

    async listExternal(xApiKey: string, cityPartialName?: string): Promise<City[]> {
        return await ajaxKey(`${this.apiUrl}/briefcase/cities/external`, 'GET', xApiKey, undefined, { cityPartialName });
	}
}
