import { useState } from 'react';
import { Box } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import './ApplyNow.scss';
import ApplicantForm from '../jobForms/applicantForm/ApplicantForm';
import ModalPopup from '../modal';
import CompleteInfo from '../jobForms/completeInfo/CompleteInfo';
import { isMobile } from 'utils';


const ApplyNow: React.FC<{}> = () => {
    const navigate = useNavigate()
    let location = useLocation();
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [customErrorMessage, setCustomErrorMessage] = useState('')


    const handleFormSubmit = (message?: string) => {
        setCustomErrorMessage(message || '')
        setOpenConfirmModal(true)
    }
    const handleCloseApplyModal = () => {
        setOpenConfirmModal(false)
        setCustomErrorMessage('')
        if(location?.pathname?.split('/')[1] === 'apply-now'){
            navigate('/')
        } else {
            navigate(`/${location?.pathname?.split('/')[1]}`)
        }
    }

    return (
        <>
            <Box className='flex content-wrapper flex-basis-75'>
                <Box className='content-body content-filter-wrapper inline-flex apply-content' sx={{ width: isMobile() ? '' : '60% !important' }}>
                    <ApplicantForm job={undefined} handleNextTab={handleFormSubmit}></ApplicantForm>
                </Box>
            </Box>
            <ModalPopup
                openModal={openConfirmModal}
                handleCloseModal={handleCloseApplyModal}>
                <CompleteInfo title='Thank you for applying' customErrorMessage={customErrorMessage} handleClose={handleCloseApplyModal} modalType="applyNow"></CompleteInfo>
            </ModalPopup>
        </>
    );
};

export default ApplyNow;
