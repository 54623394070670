import { Organization, parseOrganization } from "./organization";

export type Department = {
	_id?: string,
	name?: string,
	contact?: {
		name: string,
		email: string,
		phoneNumber: string
	},
	organization?: Organization
}

export function parseDepartment(json: any): Organization {
	if (json.organization) {
		json.organization = parseOrganization(json.organization);
	}

	return json;
}