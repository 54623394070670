import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type DriversLicense = {
	state?: string,
	number?: string,
	expirationDate?: Date,
	fileUrl?: string,
	downloadUrl?: string,
	uploadedAt?: Date
}

export function prepDriversLicense(lic: DriversLicense): DriversLicense {
	delete lic.fileUrl;
	delete lic.downloadUrl;
	delete lic.uploadedAt;

	if (lic.expirationDate) {
		lic.expirationDate = midnight(new Date(lic.expirationDate), true);
	}

	return lic;
}

export function parseDriversLicense(json: any): DriversLicense {
	if (json.expirationDate) {
		json.expirationDate = addMinutes(new Date(json.expirationDate).getTimezoneOffset(), new Date(json.expirationDate));
	}

	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}