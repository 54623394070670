import { FiltersData } from './filtersData.types'

const filtersData: FiltersData = {
  location: undefined,
  jobType: undefined,
  license: undefined,
  specialty: undefined,
  startDate: undefined,
  organization: undefined,
  rate: undefined,
  search: undefined
}

export default filtersData
