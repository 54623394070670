import { clone } from "@praos-health/core/utilities/object";
import { Specialty } from "./models";
import { ajax } from "./ajax"

export class SpecialtyService {
	private _specialtyCache: Specialty[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, profession?: string): Promise<Specialty[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/specialties`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: Specialty[];

		if (!this._specialtyCache) {
			this._specialtyCache = await ajax(`${this.apiUrl}/briefcase/specialties`, 'GET', auth);
		}

		result = this._specialtyCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}

		return clone(result);
	}
}