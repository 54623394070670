export type ConsentForm = {
	consentedAt?: Date,
	form?: string[]
}

export function parseConsentForm(json: any): ConsentForm {
	if (json.consentedAt) {
		json.consentedAt = new Date(json.consentedAt);
	}
	
	return json;
}