import { Search } from '@mui/icons-material';
import { Box, Checkbox, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField} from '@mui/material';
import { Specialty } from '@praos-health/briefcase-client';
import { useConstant } from 'contexts/constant/Constant';
import { useFiltersData } from 'contexts/filter/Filter';
import React, { useEffect, useState } from 'react';
import './SpecialtyFilter.scss';

let firstExecution = true;

const SpecialtyFilter: React.FC<{}> = () => {
  const { constants } = useConstant();

  const [checked, setChecked] = useState<Specialty[]>([]);
  const [values, setValues] = useState<Specialty[]>([]);
  const [specialtySearchText, setSpecialtySearchText] = useState<string>('');
  const { filtersData, setFiltersData, updateLocalData } = useFiltersData();

  const handleToggle = (value: Specialty) => () => {
    const currentIndex = checked.findIndex(check => check._id === value._id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === 0) {
        filtersData.specialty = undefined;
    } else {
        filtersData.specialty = newChecked;
    }

    setFiltersData(Object.assign({}, filtersData))
    updateLocalData();

    setChecked(newChecked);
  };

  useEffect(() => {
      setValues(constants.specialties || [])
  }, [constants.specialties])

  useEffect(() => {
    if (firstExecution) {
        firstExecution = false
        setChecked(filtersData.specialty || [])
    }

    if (filtersData.specialty === undefined) {
        setChecked([]);
    }

  }, [filtersData.specialty])

  return (
    <Box>
      <Box className='flex w-100-p'>
        <TextField id="outlined-basic" className='specialty-text-search m-20-px w-100-p' placeholder="Search specialty" variant="outlined" 
          onChange={event => setSpecialtySearchText(event.target.value)}
          value={specialtySearchText}
          autoComplete='off'
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search /> </InputAdornment>
          }} />
      </Box>

      <List sx={{ width: '100%', minWidth: '100%', height: 200, bgcolor: 'background.paper', overflow: 'scroll', overflowX: 'hidden' }}>
        { values
            .filter(value => value.name?.toLocaleLowerCase().includes(specialtySearchText.toLowerCase()))
            .map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
            <ListItem
                key={value._id} className='m-0 p-0'>
                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                <ListItemIcon>
                    <Checkbox
                    edge="start"
                    checked={checked.some(check => check._id === value._id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }} />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
                </ListItemButton>
            </ListItem>
            );
        })}
      </List>

    </Box>
  );
};

export default SpecialtyFilter;