import { Organization, parseOrganization, OrganizationStatistics, prepOrganization, Resource, OrientationDocument, OrganizationField, OrganizationItem, prepItem, parseOrientationDocument } from "./models";
import { ajax, ajaxKey } from "./ajax"
import { clone } from "@praos-health/core/utilities/object";
import { OrganizationLocation, parseOrganizationLocation, prepOrganizationLocation } from "./models/organization-location";
import { NotificationPreferences } from "./models/notification-preferences";

export type OrganizationSelect = {
	'intId'?: 1,
	'name'?: 1,
	'address1'?: 1,
	'address2'?: 1,
	'city'?: 1,
	'state'?: 1,
	'zip'?: 1,
	'createdAt'?: 1,
	'completedAt'?: 1,
	'resources'?: 1,
	'admin'?: 1,
	'billingContact'?: 1,
	'notificationPreferences'?: 1,
	'facility'?: 1,
	'paymentType'?: 1,
	'cards'?: 1,
	'locations'?:1,
	'accountManager'?: 1,
	'accountNotes'?: 1,
	'MSAsignDate'?: 1,
	'taxId'?: 1,
	'approvedAt'?: 1,
	'approvedBy'?: 1,
	'approvalNotes'?: 1,
	'rejectedAt'?: 1,
	'rejectedBy'?: 1,
	'rejectionNotes'?: 1,
	'suspendedAt'?: 1,
	'suspendedBy'?: 1,
	'suspensionNotes'?: 1,
	'jobs.orientationDocuments'?: 1,
	'generalContactInfo'?: 1,
	'coBrandedUrl'?: 1,
	'webUrls'?: 1,
	'services'?: 1
}

export type ListOrganizationsOptions = {
	orderBy?: 'intId' | '!intId' | 'name' | '!name',
	limit?: number,
	skip?: number,
	select?: OrganizationSelect,
	search?: string,
	status?: 'REGISTERED' | 'REJECTED' | 'PENDING' | 'APPROVED',
	isSuspended?: boolean
}

export type ListOrganizationResult = {
	list: Organization[],
	count: number
}

export type GetOrganizationOptions = {
	select?: OrganizationSelect
};

export type UpdateOptions = {
	item: {
		name?: string,
		accountManager?: string,
		accountNotes?: string,
		MSAsignDate?: Date,
		taxId?: string,
		address1?: string,
		address2?: string,
		city?: string,
		state?: string,
		zip?: string,
		facility?: {
			type?: string,
			employees?: string,
			description?: string,
			url?: string,
		},
		billingContact?: {
			name?: string,
			email?: string,
			phoneNumber?: string
		},
		resources?: Resource[],
        notificationPreferences?: NotificationPreferences,
		services: {
			name: string,
			isEnabled: boolean
		}[]
	},
	select?: OrganizationSelect
}

export class OrganizationService {
	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, options?: ListOrganizationsOptions): Promise<ListOrganizationResult> {
		const result = await ajax(`${this.apiUrl}/organizations`, 'GET', auth, undefined, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseOrganization(result.list[i]);
		}

		return result;
	}

	async get(token: string): Promise<Organization>;
	async get(auth: string, id: string, options?: GetOrganizationOptions): Promise<Organization>;
	async get(auth: string, id?: string, options?: GetOrganizationOptions): Promise<Organization> {
		let result: any;
		
		if (id) {
			result = await ajax(`${this.apiUrl}/organizations/${id}`, 'GET', auth, null, options);
		} else {
			result = await ajax(`${this.apiUrl}/organizations/${auth}`, 'GET');
		}

		return result ? parseOrganization(result) : result;
	}

	async statistics(auth: string, date?: Date): Promise<OrganizationStatistics> {
		return await ajax(`${this.apiUrl}/organizations/statistics`, 'GET', auth, null, { date } );
	}

	async update(auth: string, organizationId: string, options: UpdateOptions) : Promise<Organization> {
		options.item = prepOrganization(clone<any>(options.item)) as any;

		const result = await ajax(`${this.apiUrl}/organizations/${organizationId}`, 'PUT', auth, options);

		return parseOrganization(result);
	}


	async addItem(auth: string, organizationId: string, field: OrganizationField.OrientationDocuments, item: OrientationDocument): Promise<OrientationDocument>;
	async addItem(auth: string, organizationId: string, field: OrganizationField.Locations, item: OrganizationLocation): Promise<OrganizationLocation>;
	async addItem<T extends OrganizationItem = OrganizationItem>(auth: string, organizationId: string, field: OrganizationField, item: T): Promise<T>;
	async addItem(auth: string, organizationId: string, field: OrganizationField, item: OrganizationItem): Promise<OrganizationItem> {
		const body = clone<any>(item);

		body._field = field;

		prepItem(field, body);

		let result = await ajax(`${this.apiUrl}/organizations/${organizationId}/${field}`, 'POST', auth, body);

		switch (field) {
			case OrganizationField.OrientationDocuments:
				if (result) {
					result = parseOrientationDocument(result);
				}

				return result;
			case OrganizationField.Locations:
				if (result) {
					result = parseOrganizationLocation(result);
				}

				return result;
			default:
				return result;
		}
	}

	async updateItem(auth: string, organizationId: string, field: OrganizationField.OrientationDocuments, item: OrientationDocument): Promise<OrientationDocument>;
	async updateItem(auth: string, organizationId: string, field: OrganizationField.Locations, item: OrganizationLocation): Promise<OrganizationLocation>;
	async updateItem<T extends OrganizationItem = OrganizationItem>(auth: string, organizationId: string, field: OrganizationField, item: T): Promise<T>;
	async updateItem(auth: string, organizationId: string, field: OrganizationField, item: OrganizationItem): Promise<OrganizationItem> {
		const body = clone<any>(item);

		body._field = field;

		prepItem(field, body);

		let result = await ajax(`${this.apiUrl}/organizations/${organizationId}/${field}/${item.id}`, 'PUT', auth, body);

		switch (field) {
			case OrganizationField.OrientationDocuments:
				if (result) {
					result = parseOrientationDocument(result);
				}

				return result;
			case OrganizationField.Locations:
				if (result) {
					result = parseOrganizationLocation(result);
				}

				return result;
			default:
				return result;
		}
	}

	async deleteItem(auth: string, organizationId: string, field: OrganizationField, id: string): Promise<void> {
		await ajax(`${this.apiUrl}/organizations/${organizationId}/${field}/${id}`, 'DELETE', auth);
	}

	async approve(auth: string, id: string, notes?: string) : Promise<void> {
		return await ajax(`${this.apiUrl}/organizations/${id}/approve`, 'PUT', auth, { notes } );
	}

	async reject(auth: string, id: string, notes?: string) : Promise<void> {
		return await ajax(`${this.apiUrl}/organizations/${id}/reject`, 'PUT', auth, { notes } );
	}

	async suspend(auth: string, id: string, notes?: string) : Promise<void> {
		return await ajax(`${this.apiUrl}/organizations/${id}/suspend`, 'PUT', auth, { notes } );
	}

	async unsuspend(auth: string, id: string) : Promise<void> {
		return await ajax(`${this.apiUrl}/organizations/${id}/unsuspend`, 'PUT', auth);
	}
}