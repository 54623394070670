import { addMinutes, midnight } from "@praos-health/core/utilities/date";

export type Education = {
	id?: string,
	institute?: string,
	yearFrom?: number,
	yearTo?: number,
	programName?: string,
	courseWorkUrl?: string,
	courseWorkFileUrl?: string,
	downloadUrl?: string,
	status?: string,
	educationLevel?: string
}

export function prepEducation(doc: Education): Education {
	delete doc.downloadUrl;
	delete doc.courseWorkFileUrl;

	if (typeof doc.yearFrom === "string") {
		doc.yearFrom = parseInt(doc.yearFrom);
	}
	
	if (typeof doc.yearTo === "string") {
		doc.yearTo = parseInt(doc.yearTo);
	}

	return doc;
}

export type EducationDocument = {
	id?: string,
	name?: string,
	fileUrl?: string,
	downloadUrl?: string
}

export function prepEducationDocument(doc: EducationDocument): EducationDocument {
	delete doc.fileUrl;
	delete doc.downloadUrl;

	return doc;
}

export type ContinuingEducation = {
	id?: string,
	title?: string,
	startDate?: Date,
	endDate?: Date,
	isInternal?: boolean,
	courseWorkUrl?: string,
	courseWorkFileUrl?: string,
	courseWorkDownloadUrl?: string,
	certificateUrl?: string,
	downloadUrl?: string
}

export function prepContinuingEducation(education: ContinuingEducation): ContinuingEducation {
	delete education.certificateUrl;
	delete education.downloadUrl;
	delete education.courseWorkFileUrl;
	delete education.courseWorkDownloadUrl;

	if (education.startDate) {
		education.startDate = midnight(new Date(education.startDate), true);
	}

	if (education.endDate) {
		education.endDate = midnight(new Date(education.endDate), true);
	}

	return education;
}

export function parseContinuingEducation(json: any): ContinuingEducation {
	if (json.startDate) {
		json.startDate = addMinutes(new Date(json.startDate).getTimezoneOffset(), new Date(json.startDate));
	}

	if (json.endDate) {
		json.endDate = addMinutes(new Date(json.endDate).getTimezoneOffset(), new Date(json.endDate));
	}

	return json;
}