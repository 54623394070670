import React from 'react';
import { useBrand } from 'contexts';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import './CompleteInfo.scss';
import { Box, Button } from '@mui/material';
import { isMobile } from 'utils';

interface CompleteInfoProps {
    handleClose: () => void,
    customErrorMessage?: string,
    title?: string,
    message?: string,
    contentTitle?: string,
    modalType?: string
}

const CompleteInfo: React.FC<CompleteInfoProps> = ({ handleClose, customErrorMessage, title, message, contentTitle, modalType }) => {
    const { brand } = useBrand();

    const handleLogin = () => {
        window.open(brand.coBrandedUrl ?
            `${process.env.REACT_APP_NURSE_URL}/#/co-branded-login/${brand.coBrandedUrl}` : `${process.env.REACT_APP_NURSE_URL}/#/login`, '_blank')
    }

    return (
        <div className='completeInfo'>
            <Box className={`cancelDiv ${isMobile() ? 'cancelMobile' : 'cancelDesktop'}`}>
                <ClearIcon data-test="info-modal-close" sx={{color: brand.color}} className='cancel' onClick={handleClose} />
            </Box>
            <div className='header' style={{ backgroundColor: brand.color }}>
                <div className='title'>
                    <TaskAltOutlinedIcon />
                    <span data-test="info-modal-title" className='note'>
                        {title ? title : 'Thank you for registering your interest'}
                    </span>
                </div>
            </div>
            <div className="content">
                {customErrorMessage ?
                    <PersonSearchOutlinedIcon className='emailIcon' sx={{ color: brand.color }} /> :
                    <EmailOutlinedIcon className='emailIcon' sx={{ color: brand.color }} />
                }
                <div className='contentTitle'>
                    {customErrorMessage ? 'Email Already Registered' : contentTitle ? contentTitle : brand?.isBriefcaseEnabled ? 'Check your inbox' : 'Recruiter has been notified!'}
                </div>
                <div className='contentInstructions'>
                    {
                        customErrorMessage ?
                            (<span>{customErrorMessage}</span>) :
                            (<span>
                                {
                                    message ? message : brand?.isBriefcaseEnabled ? (
                                        <>
                                            Please check your email to get started with your application.<br />
                                            Your email includes the steps on how to complete your profile.
                                        </>
                                    ) : (
                                        modalType && modalType === 'applyNow' ? 
                                        <>
                                        Your submission has been shared with a recruiter. They will be in<br/>
                                        touch to discuss your preferences and answer any questions.
                                        </>
                                       :
                                        <>
                                        A recruiter has been notified of your interest in the job and they <br/>
                                        will be in touch to discuss your preferences and answer any questions.
                                        </> 
                                    ) 
                                }
                            </span>
                            )
                    }
                </div>
                { brand?.isBriefcaseEnabled &&
                    customErrorMessage &&
                    <div className='contentSignin'>
                        <Button
                            className='actionBtn'
                            sx={{ bgcolor: brand.color }}
                            variant="contained"
                            onClick={handleLogin}>
                            LOGIN
                        </Button>
                    </div>
                }
            </div>
        </div>
    );
};

export default CompleteInfo;