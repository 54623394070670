import { Job, ListJobsResult } from '@praos-health/jobs-client';
import React, {useEffect, useRef, useState} from 'react';
import './Map.scss';
import { MarkerClusterer, Cluster } from "@googlemaps/markerclusterer";
import { useBrand } from 'contexts';
import { faker } from '@faker-js/faker';

interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
    jobs?: ListJobsResult;
    onSelectedJob: Function;
}

interface IGoogleConfig {
    zoom: number,
    address: {lat: number, lng: number}
}

const GOOGLE_CONFIGURATION = {
    zoom: 4,
    address: { lat: 37.166013499, lng: -97.3698147 }
} as IGoogleConfig

const Map: React.FC<IMap> = ({ mapType, mapTypeControl = false, jobs, onSelectedJob}) => {

    const ref = useRef<HTMLDivElement>(null);
    let [map, setMap] = useState<google.maps.Map>();
    let [usedCoordinates, setUserCoordinates] = useState<google.maps.LatLng[]>([]);
    let [markers, setMarkers] = useState<google.maps.Marker[]>([]);
    let [markerCluster, setMarkerCluster] = useState<MarkerClusterer | undefined>(undefined);
    const { brand } = useBrand();

    useEffect(() => {
        if (!map) {
            initMap();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    const clearMarkers =  (): void => {
        markers?.forEach(marker => marker.setMap(null))
        markers = []
        setMarkers(markers)
        markerCluster?.clearMarkers()
    }

    const initEventListener = (): void => {
      if (map) {
        onSelectedJob(undefined)
        setUserCoordinates([])
        clearMarkers()
        jobs?.list.filter(clearWrongCoordinates).forEach(addMarker)
        markerCluster = new MarkerClusterer({map, markers, renderer})
        markerCluster.setMap(map)
        setMarkerCluster(markerCluster);
      }
    };

    useEffect(()  => {
        initEventListener()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, jobs]);

    const renderer = {
        render({ count, position }: Cluster) {
          return new google.maps.Marker({
            label: {
                text: String(count),
                color: "white",
                fontSize: "18px"
            },
            position,
            icon: getClusterIconAttributes(),
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          })
        }
      }
    
    const getNonCollidedLocation = (location: google.maps.LatLng): google.maps.LatLng => {
        let nearbyLocation  = faker.location.nearbyGPSCoordinate({origin: [location.lat(), location.lng()], radius: 0.12, isMetric: true})
        return new google.maps.LatLng(nearbyLocation[0], nearbyLocation[1]);
    }

    const clearWrongCoordinates = (job: Job): boolean => !job.location?.coordinates?.some(coordinate => coordinate === 0) || false;

    const addMarker = (job: Job): void => {
        let location = new google.maps.LatLng(job.location?.coordinates ? job.location?.coordinates[1] : 0, job.location?.coordinates ? job.location.coordinates[0] : 0)
        location = getNonCollidedLocation(location)
        usedCoordinates.push(location)

        let marker: google.maps.Marker = new google.maps.Marker({
            position: location,
            map: map,
            icon: getMarkerIconAttributes(),
            clickable: true
        });
        
        marker.addListener('click', () => onSelectedJob(job));
        markers.push(marker)
        setMarkers(markers)
    };

    const getMarkerIconAttributes = (): google.maps.Symbol => {
        let CX = 3
        let CY = 3
        let R = 9

        let circlePath = 
        `M ${CX - R}, ${CY}
         a ${R},${R} 0 1,0 ${R * 2},0
         a ${R},${R} 0 1,0 -${R * 2},0`
        
        return {
            path: circlePath,
            fillColor: '#FFF',
            fillOpacity: 0.9,
            strokeColor: brand.color,
            strokeWeight: 3,
            anchor: new google.maps.Point(5, 15)
        };
    };

    const getClusterIconAttributes = (): google.maps.Symbol => {
        return {
            path: `m 40 12 h -8 v -4 c 0 -2.2 -1.8 -4 -4 -4 h -8 c -2.2 0 -4 1.8 -4 4 v 4 h -8 c -2.2 0 -4 1.8 -4 4 l 0 22 c 0 2.2 1.8 4 4 4 h 32 c 2.2 0 4 -1.8 4 -4 v -22 c 0 -2.2 -1.8 -4 -4 -4 m -14 -1 h -4 v -2 h 4 z`,
            fillColor: brand.color,
            fillOpacity: 1,
            strokeColor: '#FFF',
            strokeWeight: 2,
            anchor: new google.maps.Point(30, 30),
            labelOrigin: new google.maps.Point(24, 26),
            strokeOpacity: 0.8
        };
    };

    const initMap = async (): Promise<void> => {
        if (ref.current) {
            await navigator.geolocation.getCurrentPosition((position) => {
                GOOGLE_CONFIGURATION.address = { lat: position.coords.latitude, lng: position.coords.longitude }
                configureMap();
            }, configureMap);
        }

        function configureMap() {
            if (ref.current)
                map = new google.maps.Map(ref.current, {
                    mapId: process.env.REACT_APP_GOOGLE_MAPS_CLOUD_STYLES_KEY,
                    zoom: GOOGLE_CONFIGURATION.zoom,
                    center: GOOGLE_CONFIGURATION.address,
                    mapTypeControl: mapTypeControl,
                    streetViewControl: false,
                    rotateControl: false,
                    scaleControl: true,
                    fullscreenControl: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_TOP
                    },
                    gestureHandling: 'cooperative',
                    mapTypeId: mapType,
                    draggableCursor: 'pointer'
                });

                map?.addListener('click', () => onSelectedJob(undefined));
                setMap(map)
        }
    };

    return (
        <div className="map-container">
            <div ref={ref} className="map-container__map"></div>
        </div>
    );
};

export default Map;
