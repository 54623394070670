import { Box, Modal, SxProps } from '@mui/material';
import React from 'react';
import { isMobile } from 'utils';

interface ModalPopupProps {
    children?: React.ReactNode;
    openModal: boolean,
    handleCloseModal: () => void
}

const ModalPopup: React.FC<ModalPopupProps> = ({ children, openModal, handleCloseModal }) => {
    let style: SxProps = {
        width: isMobile() ? '100%' : 'calc(max(400px, 60%))',
        position: 'absolute',
        bgcolor: 'background.paper',
        backgroundClip: 'padding-box',
        border: isMobile() ? 'none' : '7px solid rgba(0, 0, 0, .4)',
        boxShadow: 24,
        outline: 'none',
        margin: '0 auto',
        display: 'table',
        left: ' 0',
        right: '0',
        top: isMobile() ? '25px' : '100px',
        bottom: ' 0',
    };

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            sx={{ overflow: 'scroll' }}
			className={'modal-wrapper'}
        >
            <Box sx={style}>
                {children}
            </Box>
        </Modal>
    );
};

export default ModalPopup;