import { clone } from "@praos-health/core/utilities/object";
import { Certification } from "./models";
import { ajax } from "./ajax"

export class CertificationService {
	private _certificationCache: Certification[];

	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async list(auth: string, profession?: string): Promise<Certification[]> {
		if (!this.cacheEnabled) {
			return await ajax(`${this.apiUrl}/briefcase/certifications`, 'GET', auth, undefined, profession ? { profession } : null);
		}

		let result: Certification[];

		if (!this._certificationCache) {
			this._certificationCache = await ajax(`${this.apiUrl}/briefcase/certifications`, 'GET', auth);
		}

		result = this._certificationCache;

		if (profession) {
			result = result.filter(i => i.profession === profession);
		}
		
		return clone(result);
	}
}